import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    DeleteMarketIndicatorFactorRequest,
    GetMarketIndicatorResponse,
    MarketIndicatorFactorSummaryModel,
    UpdateMarketIndicatorFactorGroupRequest,
} from '../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import sixDotVector from '../../Assets/Icons/sixDotVector.svg';
import IndicatorsManagementButton from '../../Components/Form/Buttons/IndicatorsManagementButton/IndicatorsManagementButton';
import ConfirmModal from '../../Components/Modals/ConfirmModal/ConfirmModal';
import useLanguage from '../../Services/Language/useLanguageHook';
import MarketIndicatorFactorGroupActions from '../Administration/MarketIndicatorsManagement/MarketIndicatorActions/MarketIndicatorFactorGroupActions';
import styles from './MarketIndicatorFactorsPage.module.scss';
import MarketIndicatorFactorSummary from './MarketIndicatorFactorSummary';

interface IMarketIndicatorFactorGroupProps {
    displayName: string;
    getMarketIndicatorResponse?: GetMarketIndicatorResponse;
    factors: MarketIndicatorFactorSummaryModel[];
    handleUpdateMarketIndicatorFactorsGroup: (marketIndicatorFactor: UpdateMarketIndicatorFactorGroupRequest) => void;
    handleDeleteMarketIndicatorFactors: (marketIndicatorFactors: DeleteMarketIndicatorFactorRequest | DeleteMarketIndicatorFactorRequest[]) => void;
    isInAdministrationMode?: boolean;
    nextOrder?: number;
    testId?: string;
}

const defaultNumberOfFactorsInRow = 4;

// TODO - Make this component drag and drop.
// TODO - Need a version of this for non-administrators.
const MarketIndicatorFactorGroup: React.FC<IMarketIndicatorFactorGroupProps> = (props: IMarketIndicatorFactorGroupProps) => {
    // Application hooks and application constants.
    const [translations] = useLanguage();
    const navigate = useNavigate();
    const { marketIndicatorGuid } = useParams();

    // Component states.
    const [showDeleteFactorModal, setShowDeleteFactorModal] = useState(false);
    const [currentDisplayName, setCurrentDisplayName] = useState(props.displayName);

    // Form request.
    const [selectedIndicator, setSelectedIndicator] = useState<
        DeleteMarketIndicatorFactorRequest | DeleteMarketIndicatorFactorRequest[] | UpdateMarketIndicatorFactorGroupRequest
    >({
        marketIndicatorGuid: marketIndicatorGuid ?? '',
        marketIndicatorFactorGuid: '',
        displayGroupName: props.displayName,
        newDisplayGroupName: '',
    });

    useEffect(() => {
        setSelectedIndicator({ ...selectedIndicator, newDisplayGroupName: currentDisplayName });
    }, [currentDisplayName]);

    return (
        <>
            <div className={styles.indicators_group_display_name} data-testid={props.testId}>
                {props.isInAdministrationMode && <img src={sixDotVector} alt="#" />}
                {currentDisplayName}
                {props.isInAdministrationMode && (
                    <MarketIndicatorFactorGroupActions
                        factors={props.factors}
                        marketIndicatorGuid={marketIndicatorGuid ?? ''}
                        currentDisplayName={currentDisplayName}
                        setCurrentDisplayName={setCurrentDisplayName}
                        handleUpdateMarketIndicatorFactorsGroup={props.handleUpdateMarketIndicatorFactorsGroup}
                        handleDeleteMarketIndicatorFactors={props.handleDeleteMarketIndicatorFactors}
                    />
                )}
            </div>
            <div className={styles.indicators_group}>
                {props.factors.map((factor) => (
                    <MarketIndicatorFactorSummary
                        key={factor.marketIndicatorFactorGuid}
                        factor={factor}
                        getMarketIndicatorResponse={props.getMarketIndicatorResponse}
                        groupDisplayName={currentDisplayName}
                        handleIndicatorDelete={(indicator) => {
                            setSelectedIndicator(indicator);
                            setShowDeleteFactorModal(true);
                        }}
                        isInAdministrationMode={props.isInAdministrationMode}
                    />
                ))}
                {props.factors.length < defaultNumberOfFactorsInRow && props.isInAdministrationMode && (
                    <IndicatorsManagementButton
                        handleClick={() =>
                            navigate(
                                `${NavigationRoutes.AdministrationMarketIndicators}/${marketIndicatorGuid}/factorGroups/${
                                    currentDisplayName ?? props.displayName
                                }/add?order=${props.nextOrder}`,
                            )
                        }
                    />
                )}
            </div>
            {props.isInAdministrationMode && (
                <ConfirmModal
                    header={translations.marketIndicatorsManagement.headers.deleteFactor}
                    showModal={showDeleteFactorModal}
                    message={translations.marketIndicatorsManagement.text.areYouSureDeleteFactor}
                    handleConfirm={() => {
                        props.handleDeleteMarketIndicatorFactors(selectedIndicator as DeleteMarketIndicatorFactorRequest);
                        setShowDeleteFactorModal(false);
                    }}
                    handleCancel={() => setShowDeleteFactorModal(false)}
                />
            )}
        </>
    );
};

export default MarketIndicatorFactorGroup;
