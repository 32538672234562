/* eslint-disable react/no-this-in-sfc */
import HighchartsReact from 'highcharts-react-official';
import HighStock from 'highcharts/highstock';
import { useEffect, useRef, useState } from 'react';
import { GraphOptions } from '../../../../Components/Charts/BaseCharts/GeneralChartDefinitions';
import { HighChartsGraphOptionsOriginsDestinationsStub } from '../../../../Components/Charts/ChartHelpers/HighChartGraphOptionStubs';
import ChartWrapper from '../../../../Components/ChartWrapper/ChartWrapper';
import { DemeterFilterTimeSpan } from '../../../../Generated/Raven-Demeter';
import {
    CommodityMonthlyOriginsOrDestinationsModel,
    ListCommodityMonthlyOriginsOrDestinationsResponse,
} from '../../../Apis/Hooks/useOriginsAndDestinationsApiHook';
import formattingService from '../../../Services/Formatting/FormattingService';
import languageService from '../../../Services/Language/LanguageService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import Switch from '../../Form/Buttons/Switch';
import styles from './OriginsAndDestinationsChart.module.scss';

export interface IOriginsAndDestinationsChartProps {
    filterTimeSpan: DemeterFilterTimeSpan;
    originsOrDestinationsResponse?: ListCommodityMonthlyOriginsOrDestinationsResponse;
    title: string;
    testId?: string;
}

const OriginsAndDestinationsChart: React.FC<IOriginsAndDestinationsChartProps> = (props) => {
    const donutChartReference = useRef<any>();
    const [isChartPopped, setIsChartPopped] = useState<boolean>(false);
    const [originsAndDestinationsMonthLocked, setOriginsAndDestinationsMonthLocked] = useState<boolean>(false);
    const [translations] = useLanguage();
    const [highchartGraphOptions, setHighchartGraphOptions] = useState<GraphOptions>(HighChartsGraphOptionsOriginsDestinationsStub);
    const getDonutValues = (model: CommodityMonthlyOriginsOrDestinationsModel) => {
        if (originsAndDestinationsMonthLocked) {
            return model.value;
        }

        if (props.filterTimeSpan === DemeterFilterTimeSpan.YearToDate) {
            return model.yearToDateValue;
        }

        return model.rollingYearValue;
    };
    const getChartTitle = (): string => {
        let header = '';
        if (props.filterTimeSpan !== DemeterFilterTimeSpan.YearToDate) {
            header = originsAndDestinationsMonthLocked
                ? `${translations.charts.text.monthly} ${props.title}`
                : `${translations.charts.text.pastTwelveMonths} ${props.title}`;
        } else {
            header = `${translations.charts.text.yearToDate} ${props.title}`;
        }
        return header;
    };

    useEffect(() => {
        if (!props.originsOrDestinationsResponse?.rows || props.originsOrDestinationsResponse?.rows?.length! === 0) {
            return;
        }
        const newOptions: GraphOptions = { ...highchartGraphOptions };
        const totalValue = formattingService.toFormattedNumber(getDonutValues(props.originsOrDestinationsResponse.rows[0]));
        const timeSpanText = originsAndDestinationsMonthLocked
            ? translations.destinationsOrOrigins.text.monthly
            : translations.filterTimeSpan[props.filterTimeSpan!];

        newOptions.subtitle!.text = `<p class=${styles.origins_and_destination_chart_subtitle}>${timeSpanText}</p>
            <p class=${styles.origins_and_destination_chart_subtitle}>${translations.words.total}</p>
            <p class=${styles.origins_and_destination_chart_subtitle_bold}>${totalValue}</p>`;

        newOptions.tooltip!.pointFormat = `<p class=${styles.origins_and_destination_chart_p}>{point.y}</p>
            <p class=${styles.origins_and_destination_chart_p_percent}>{point.percentage:.0f} %</p>
            <p class=${styles.origins_and_destination_chart_p}>${translations.unitOfMeasure[props.originsOrDestinationsResponse?.unitOfMeasure!]}</p>`;
        newOptions.plotOptions!.pie!.dataLabels.enabled = isChartPopped;
        newOptions.series[0].innerSize = isChartPopped ? '35%' : '45%';
        newOptions.series[0].size = isChartPopped ? '82%' : '100%';
        newOptions.chart!.height = isChartPopped ? '38%' : '50%';
        newOptions.series[0].dataLabels = {
            formatter() {
                return this.y > 1
                    ? `<span class=${styles.origins_and_destination_chart_lable_root}>
                    <p class=${styles.origins_and_destination_chart_country_name}> ${languageService.translate(this.point.name)}</p> 
                     <span class=${styles.origins_and_destination_chart_data_and_percentage_wrapper} >
                    <p class = ${styles.origins_and_destination_chart_p} >${formattingService.toFormattedNumber(this.y)} </p><b> 
                    <p  class= ${styles.origins_and_destination_chart_p_percent} >${Math.round(this.point.percentage)} % </p> </span></span>`
                    : null;
            },
        };

        newOptions.series[0].data = props.originsOrDestinationsResponse.rows.map((row, index: number) => {
            if (index !== 0) {
                return { name: languageService.translate(row.originOrDestinationRegion!), y: getDonutValues(row) };
            }
            return null;
        });

        setHighchartGraphOptions(newOptions);
    }, [props.originsOrDestinationsResponse, originsAndDestinationsMonthLocked, isChartPopped, props.filterTimeSpan]);
    return (
        <div data-testid={props.testId} className={styles.origins_and_destination_chart}>
            <ChartWrapper
                header={getChartTitle()}
                chartRef={donutChartReference}
                dataSourceTag={props.originsOrDestinationsResponse?.dataSourceTag ?? ''}
                setIsChartPopped={setIsChartPopped}
            >
                <HighchartsReact ref={donutChartReference} highcharts={HighStock} options={highchartGraphOptions} />
            </ChartWrapper>
            <p className={styles.origins_and_destination_chart_time_override}>
                {translations.destinationsOrOrigins.text.monthly}
                <Switch handleChange={() => setOriginsAndDestinationsMonthLocked(!originsAndDestinationsMonthLocked)} />
            </p>
        </div>
    );
};

export default OriginsAndDestinationsChart;
