import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { demeterMarketIndicatorsApi } from '../../../Apis/Apis';
import scssVariables from '../../../Config.module.scss';
import { MarketIndicatorSummaryModel } from '../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import useApi from '../../Apis/Hooks/useApiHook';
import { chartColors } from '../../Components/Charts/ChartDefinitions';
import ComponentHeader from '../../Components/Headers/ComponentHeader';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import OverflowCellWithTooltip from '../../Components/Tables/OverflowCellWithTooltip/OverflowCellWithTooltip';
import formattingService from '../../Services/Formatting/FormattingService';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './Dashboard.module.scss';

const minimumCellValueForWhiteText = 10;
const maximumCellValueForWhiteText = 90;
const minimumAllowedDecileValue = 0;
const maximumAllowedDecileValue = 100;

type CellType = 'commodity' | 'split' | 'positiveOrNegativeText' | 'medianBackground';
type QuarterYearValue = { quarter: number; year: number };

const DashboardCommoditySummaryTable: React.FC = () => {
    const [translations, translate] = useLanguage();

    // Text hooks.
    const priceValueMarixTitleString = useMemo(() => translations.dashboard.headings.priceValueMatrix, [translations]);
    const commoditySummaryTableTitleString = useMemo(() => translations.dashboard.headings.commoditySummaryTable, [translations]);
    const commoditySummaryTableFootNote = useMemo(() => translations.dashboard.text.commoditySummaryTableFootnote, [translations]);

    // Data hooks.
    const [, , listMarketIndicatorSummariesResponse] = useApi(() => demeterMarketIndicatorsApi.listMarketIndicatorSummaries());

    // Table hooks.
    const tableHeaders = useMemo(() => {
        if (!listMarketIndicatorSummariesResponse) {
            return null;
        }
        const quarterDeciles = listMarketIndicatorSummariesResponse.rows?.filter((x) => x.quarterDeciles.length !== 0).map((x) => x.quarterDeciles);
        const commonQuarterDeciles = quarterDeciles?.map((x) =>
            x.filter((y) => quarterDeciles.every((z) => z.find((a) => a.quarter === y.quarter && a.year === y.year))),
        )![0]!;

        return [
            {
                label: translations.words.commodity,
                value: 'commodity',
                cellType: 'commodity',
            },
            {
                label: `% ${translations.text.monthOverMonth}`,
                value: 'monthOverMonthChangePercent',
                cellType: 'positiveOrNegativeText',
            },
            {
                label: `% ${translations.words.shortYearOverYear}`,
                value: 'rollingYearChangePercent',
                cellType: 'positiveOrNegativeText',
            },
            {
                label: translations.dashboard.text.fiftiethDecile,
                value: 'annual',
                cellType: 'medianBackground',
            },
            {
                label: translations.words.spot,
                value: 'currentPrice',
                cellType: 'split',
            },
            {
                label: `${translations.words.quarter} ${commonQuarterDeciles[0].quarter}, ${commonQuarterDeciles[0].year}`,
                value: { quarter: commonQuarterDeciles[0].quarter, year: commonQuarterDeciles[0].year },
                cellType: 'split',
            },
            {
                label: `${translations.words.quarter} ${commonQuarterDeciles[1].quarter}, ${commonQuarterDeciles[1].year}`,
                value: { quarter: commonQuarterDeciles[1].quarter, year: commonQuarterDeciles[1].year },
                cellType: 'split',
            },
            {
                label: `${translations.words.quarter} ${commonQuarterDeciles[2].quarter}, ${commonQuarterDeciles[2].year}`,
                value: { quarter: commonQuarterDeciles[2].quarter, year: commonQuarterDeciles[2].year },
                cellType: 'split',
            },
            {
                label: `${translations.words.quarter} ${commonQuarterDeciles[3].quarter}, ${commonQuarterDeciles[3].year}`,
                value: { quarter: commonQuarterDeciles[3].quarter, year: commonQuarterDeciles[3].year },
                cellType: 'split',
            },
        ] as { label: string; value: keyof MarketIndicatorSummaryModel | QuarterYearValue; cellType?: CellType }[];
    }, [translations, listMarketIndicatorSummariesResponse]);

    const tableDataCells = useMemo(() => {
        if (!tableHeaders) {
            return null;
        }

        return listMarketIndicatorSummariesResponse?.rows
            ?.filter((x) => !x.usePrices)
            .map((x) => (
                <div className={styles.commodity_summary_table_row}>
                    {tableHeaders.map((y, index) => {
                        const selectedValue = x[y.value as keyof typeof x]! as number;

                        const selectedTermClassification = x.quarterDeciles.find(
                            (z) => z.quarter === (y.value as QuarterYearValue).quarter && z.year === (y.value as QuarterYearValue).year,
                        );

                        let currencyAndUnitOfMeasureString = '';

                        if (x.currency && x.unitOfMeasure) {
                            currencyAndUnitOfMeasureString = `${translations.currency[x.currency]} / ${
                                translations.unitOfMeasure[`Short${x.unitOfMeasure}`] ?? ''
                            }`;
                        } else if (x.unitOfMeasure) {
                            currencyAndUnitOfMeasureString = translations.unitOfMeasure[`Short${x.unitOfMeasure}`] ?? '';
                        } else if (x.currency) {
                            currencyAndUnitOfMeasureString = translations.currency[x.currency];
                        }

                        if (index === 0) {
                            return (
                                <div className={styles.commodity_summary_table_cell_extra_large}>
                                    <OverflowCellWithTooltip
                                        value={`${translate(x.displayName ?? '')} ${currencyAndUnitOfMeasureString}`}
                                        nonTooltipOverrideValue={
                                            <div className={styles.commodity_summary_table_no_wrap_flex}>
                                                {translate(x.displayName ?? '')}
                                                <div className={styles.commodity_summary_table_sub_text}>{currencyAndUnitOfMeasureString}</div>
                                            </div>
                                        }
                                    />
                                </div>
                            );
                        }

                        if (y.cellType === 'positiveOrNegativeText') {
                            return (
                                <div
                                    className={styles.commodity_summary_table_cell}
                                    style={{ color: selectedValue > 0 ? scssVariables.tableTextPositive : scssVariables.tableTextNegativeAlternative }}
                                >
                                    <OverflowCellWithTooltip value={`${formattingService.toPercent(selectedValue)}`} />
                                </div>
                            );
                        }

                        if (y.cellType === 'medianBackground') {
                            return (
                                <div
                                    className={styles.commodity_summary_table_cell}
                                    style={{ color: scssVariables.plainWhite, backgroundColor: scssVariables.decileMedianLine }}
                                >
                                    <OverflowCellWithTooltip value={`${x?.median}`} />
                                </div>
                            );
                        }

                        if (y.cellType === 'split') {
                            const selectedDecile = y.value === 'currentPrice' ? x.outlookDecile : selectedTermClassification?.decile ?? 0;
                            const currentDecileColor = chartColors.decileChartColors.find((z) => (z.decileRank - 1) * 10 <= (selectedDecile! ?? 0))?.color;

                            const getCurrentDecileString = () => {
                                if (Math.round(selectedDecile) === minimumAllowedDecileValue) {
                                    return translations.words.minimum;
                                }

                                if (Math.round(selectedDecile) === maximumAllowedDecileValue) {
                                    return translations.words.maximum;
                                }

                                return formattingService.toPriceString(Math.round(selectedDecile));
                            };

                            return (
                                <>
                                    <div className={styles.commodity_summary_table_cell_split_value_large}>
                                        <OverflowCellWithTooltip
                                            value={formattingService.toPriceString(
                                                y.value === 'currentPrice' ? x.currentPrice : selectedTermClassification?.value,
                                            )}
                                        />
                                    </div>
                                    <div
                                        className={styles.commodity_summary_table_cell_split_value_small}
                                        style={{
                                            color:
                                                selectedDecile < minimumCellValueForWhiteText || selectedDecile > maximumCellValueForWhiteText
                                                    ? scssVariables.plainWhite
                                                    : scssVariables.mainTextColor,
                                            backgroundColor: currentDecileColor,
                                        }}
                                    >
                                        <div>{getCurrentDecileString()}</div>
                                    </div>
                                </>
                            );
                        }

                        return (
                            <div className={styles.commodity_summary_table_cell}>
                                <OverflowCellWithTooltip value={`${selectedValue}`} />
                            </div>
                        );
                    })}
                </div>
            ));
    }, [listMarketIndicatorSummariesResponse, tableHeaders]);

    const isLoading = !tableHeaders || !tableDataCells;

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <div className={styles.commodity_summary_table}>
            <div className={styles.commodity_summary_table_title_line}>
                <ComponentHeader title={commoditySummaryTableTitleString} />
                <div className={styles.commodity_summary_table_top_title}>{priceValueMarixTitleString}</div>
            </div>
            <div className={styles.commodity_summary_table_row}>
                {tableHeaders.map((x, index) => {
                    if (index === 0) {
                        return <div className={styles.commodity_summary_table_header_item_extra_large}>{x.label}</div>;
                    }

                    return <div className={styles.commodity_summary_table_header_item}>{x.label}</div>;
                })}
            </div>
            {tableDataCells}
            <p className={styles.commodity_summary_table_footnote}>
                {commoditySummaryTableFootNote}
                <Link to={`${NavigationRoutes.Calculators}`}>{translations.calculators.text.valueMatrix}</Link>.
            </p>
        </div>
    );
};

export default DashboardCommoditySummaryTable;
