import React, { useEffect, useMemo, useState } from 'react';
import {
    CommodityMonthlyExportModel,
    CommodityWeeklyExportModel,
    DemeterDataFrequency,
    DemeterFeatureType,
    DemeterMarket,
    DemeterRegion,
    DemeterTableDefinitionType,
    SeasonalYearAverageMonthlyValueMonthly,
    SeasonalYearByWeekAverageMonthlyValueMonthly,
    UnitOfMeasure,
} from '../../../../Generated/Raven-Demeter/api';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectUserCurrentMarket } from '../../../../Redux/Slices/UserSlice';
import useSeasonalApi, { SeasonalMonthlyRowModel, SeasonalWeeklyRowModel } from '../../../Apis/Hooks/useSeasonalApiHook';
import featureFlagsService from '../../../Services/FeatureFlags/FeatureFlagsService';
import useFeatureFlag from '../../../Services/FeatureFlags/useFeatureFlagHook';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import CheckboxDropdown from '../../Form/Inputs/CheckboxDropdown';
import { SelectInputOption } from '../../Form/Inputs/SelectInput';
import { IRegionCommoditySelection } from '../../Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Navigation/Hooks/useTableDefinitionHook';
import { IChartData, IChartSeasonalDataSeries, LineDataType } from '../ChartDefinitions';
import ChartWrapper from '../ChartWrapper/ChartWrapper';
import styles from './SeasonalChart.module.scss';
import SeasonalChartRaw from './SeasonalChartRaw';

export interface ISeasonalChartProps {
    title: string;
    tableDefinitionType: DemeterTableDefinitionType;
    regionCommoditySelection: IRegionCommoditySelection;
    aggregateRegions?: DemeterRegion[];
    unitOfMeasure?: UnitOfMeasure;
    testId?: string;
}

interface YearOption {
    yearsOrNumberOfYears: number;
    lineDataType: LineDataType;
}

const currentYear = new Date().getFullYear();
const getYearsArray = (startYear: number, numberOfYearsForward: number) => {
    const endYear = currentYear + numberOfYearsForward;
    const years: number[] = [];

    for (let year = startYear; year <= endYear; year += 1) {
        years.push(year);
    }

    return years;
};

const miniumSelections = 1;
const maximumSelections = 10;
const startYear = 2002;
const years = getYearsArray(startYear, 1);
const numberOfYearsForAverages = [3, 5];
const defautNumberOfYearsForAveragesAndRanges = 5;

const SeasonalChart: React.FC<ISeasonalChartProps> = (props: ISeasonalChartProps) => {
    // Data/navigation hooks.
    const [translations, translate] = useLanguage();
    const [tableDefinitionRegion, tableDefinitionCommodity] = useTableDefinition(props.tableDefinitionType, props.regionCommoditySelection);
    const market = useApplicationSelector(selectUserCurrentMarket);
    const isExportDataOn = useFeatureFlag(featureFlagsService.getFeatureType(DemeterFeatureType.DownloadsGeneral, props.tableDefinitionType));
    const dataFrequency = useMemo(() => {
        if (!tableDefinitionCommodity?.dataFrequencies || tableDefinitionCommodity.dataFrequencies.length === 0) {
            return DemeterDataFrequency.Monthly;
        }

        return tableDefinitionCommodity.dataFrequencies[0];
    }, [tableDefinitionCommodity]);

    const disableTooltip = market === DemeterMarket.Energy;

    const allYearOptions = useMemo(() => {
        const options = years.map((x) => ({ label: `${x}`, value: { yearsOrNumberOfYears: x, lineDataType: 'value' } } as SelectInputOption<YearOption>));

        if (market === DemeterMarket.Dairy) {
            options.push(
                ...years.slice(-2).map(
                    (x) =>
                        ({
                            label: `${x}-${translations.words.forecast}`,
                            value: { yearsOrNumberOfYears: x, lineDataType: 'forecast' },
                        } as SelectInputOption<YearOption>),
                ),
            );
        }

        options.push({
            label: `${defautNumberOfYearsForAveragesAndRanges} ${translations.words.year} ${translations.words.range}`,
            value: { yearsOrNumberOfYears: defautNumberOfYearsForAveragesAndRanges, lineDataType: 'range' },
        });

        options.push(
            ...numberOfYearsForAverages.reverse().map(
                (x) =>
                    ({
                        label: `${x} ${translations.words.year} ${translations.words.average}`,
                        value: { yearsOrNumberOfYears: x, lineDataType: 'average' },
                    } as SelectInputOption<YearOption>),
            ),
        );

        return options.filter((x) => x.value.yearsOrNumberOfYears <= currentYear || x.value.lineDataType === 'forecast').reverse();
    }, [translations]);

    const getDefaultYearSelected = () => {
        if (market === DemeterMarket.Dairy) {
            return allYearOptions
                .filter(
                    (x) =>
                        (x.value.lineDataType === 'range' && x.value.yearsOrNumberOfYears === defautNumberOfYearsForAveragesAndRanges) ||
                        x.value.lineDataType === 'forecast' ||
                        x.value.yearsOrNumberOfYears === currentYear ||
                        x.value.yearsOrNumberOfYears === currentYear - 1,
                )
                .map((x) => x.value);
        }

        return allYearOptions
            .filter(
                (x) =>
                    ((x.value.lineDataType === 'range' || x.value.lineDataType === 'average') &&
                        x.value.yearsOrNumberOfYears === defautNumberOfYearsForAveragesAndRanges) ||
                    x.value.yearsOrNumberOfYears === currentYear ||
                    x.value.yearsOrNumberOfYears === currentYear - 1,
            )
            .map((x) => x.value);
    };

    const [yearOptions, setYearOptions] = useState(allYearOptions);
    const [yearsSelected, setYearsSelected] = useState<YearOption[]>(getDefaultYearSelected);
    const yearsToQuery = useMemo<number[]>(() => {
        const initialYearsSelected = [...yearsSelected.filter((x) => x.yearsOrNumberOfYears >= startYear).map((x) => x.yearsOrNumberOfYears)];

        const yearsToQueryForApi = new Set([...years, ...initialYearsSelected]);

        return Array.from(yearsToQueryForApi);
    }, [yearsSelected]);

    const [forecastOptionsSelected, setForecastOptionsSelected] = useState<YearOption[]>(() =>
        allYearOptions.filter((x) => x.value.lineDataType === 'forecast').map((x) => x.value),
    );

    const seasonalData = useSeasonalApi(
        props.tableDefinitionType,
        props.regionCommoditySelection,
        props.aggregateRegions,
        dataFrequency,
        props.unitOfMeasure,
        undefined,
        yearsToQuery,
        true,
    );

    useEffect(() => {
        if (!seasonalData || !seasonalData?.rows || market !== DemeterMarket.Dairy) {
            setYearOptions(allYearOptions);
            return;
        }

        const hasForecastData = seasonalData.rows.some((x) => {
            const rowValues = ((x as SeasonalMonthlyRowModel)?.monthlyValues ||
                (x as SeasonalWeeklyRowModel)?.weeklyValues ||
                []) as (CommodityMonthlyExportModel & CommodityWeeklyExportModel)[];

            if (rowValues.length === 0) {
                return false;
            }

            return rowValues.some((y) => !y.isActualValue);
        });

        if (hasForecastData) {
            setYearOptions(allYearOptions);

            if (forecastOptionsSelected.length && !yearsSelected.some((x) => x.lineDataType === 'forecast')) {
                setYearsSelected([...yearsSelected, ...forecastOptionsSelected]);
            }
        } else {
            setYearOptions(allYearOptions.filter((x) => x.value.lineDataType !== 'forecast'));

            if (yearsSelected.some((x) => x.lineDataType === 'forecast')) {
                setYearsSelected(yearsSelected.filter((x) => x.lineDataType !== 'forecast'));
            }
        }
    }, [market, seasonalData]);

    const [lineSeries, setLineSeries] = useState<IChartSeasonalDataSeries[]>([]);

    // Display hooks.
    const title = useMemo(
        () =>
            `${
                props.regionCommoditySelection.subRegion ? translate(props.regionCommoditySelection.subRegion) : translate(tableDefinitionRegion?.displayName!)
            }${tableDefinitionCommodity ? ` ${translate(tableDefinitionCommodity?.displayName)}` : ''} ${
                props.title ? props.title : translations.tableDefinitionType[props.tableDefinitionType]
            } ${translations.charts.text.seasonal}`,
        [props.title, props.regionCommoditySelection?.subRegion, tableDefinitionRegion, tableDefinitionCommodity, translations],
    );

    useEffect(() => {
        if (!seasonalData?.rows) {
            return;
        }

        const newLineSeriesData = yearsSelected
            .sort((a, b) => {
                const aValue = `${a.yearsOrNumberOfYears}${a.lineDataType === 'value' ? '0' : a.lineDataType}`;
                const bValue = `${b.yearsOrNumberOfYears}${b.lineDataType === 'value' ? '0' : b.lineDataType}`;

                if (aValue === bValue) {
                    return 0;
                }

                return aValue < bValue ? -1 : 1;
            })
            .flatMap((yearOption) => {
                let values: IChartData[] = [];
                let label: string = '';

                if (yearOption.lineDataType === 'average' || yearOption.lineDataType === 'range') {
                    const average = seasonalData.averages?.find((x) => x.numberOfYears === yearOption.yearsOrNumberOfYears);
                    const averageValues = (average?.monthlyValues || average?.weeklyValues || []) as Partial<
                        SeasonalYearAverageMonthlyValueMonthly & SeasonalYearByWeekAverageMonthlyValueMonthly
                    >[];

                    if (averageValues.length === 0) {
                        return [];
                    }

                    if (yearOption.lineDataType === 'average') {
                        label = `${yearOption.yearsOrNumberOfYears} ${translations.words.year} ${translations.words.average}`;

                        values = averageValues.map((x) => ({
                            value: x.averageValue!,
                            asOfDate:
                                dataFrequency === DemeterDataFrequency.Monthly
                                    ? new Date(startYear, x.month! - 1, 1)
                                    : formattingService.getWeekStartDate(startYear, x.week!, 1),
                            isActualValue: true,
                        }));
                    } else {
                        label = `${yearOption.yearsOrNumberOfYears} ${translations.words.year} ${translations.words.range}`;
                        values = averageValues
                            .map((x) => ({
                                value: x.averageValue!,
                                minimumValue: x.minimumValue,
                                maximumValue: x.maximumValue,
                                asOfDate:
                                    dataFrequency === DemeterDataFrequency.Monthly
                                        ? new Date(startYear, x.month! - 1, 1)
                                        : formattingService.getWeekStartDate(startYear, x.week!, 1),
                                isActualValue: true,
                            }))
                            .sort((a, b) => a.asOfDate.getTime() - b.asOfDate.getTime());
                    }
                } else if (yearOption.lineDataType === 'forecast' || yearOption.lineDataType === 'value') {
                    const row = seasonalData.rows?.find((seasonalRow) => seasonalRow.year === yearOption.yearsOrNumberOfYears);
                    let rowValues = ((row as SeasonalMonthlyRowModel)?.monthlyValues ||
                        (row as SeasonalWeeklyRowModel)?.weeklyValues ||
                        []) as (CommodityMonthlyExportModel & CommodityWeeklyExportModel)[];

                    if (rowValues.length === 0) {
                        return [];
                    }

                    if (yearOption.lineDataType === 'forecast') {
                        label = `${yearOption.yearsOrNumberOfYears}-${translations.words.forecast}`;
                        // We need to add in the last actual value in the forecast line so it can be continuous.
                        const firstForecastIndex = rowValues.findIndex((x) => !x.isActualValue);
                        rowValues = firstForecastIndex <= 0 ? rowValues : rowValues.slice(firstForecastIndex - 1);
                    } else {
                        label = `${yearOption.yearsOrNumberOfYears}`;
                        rowValues = rowValues.filter((x) => x.isActualValue);
                    }

                    if (rowValues.length === 0) {
                        return [];
                    }

                    values = rowValues
                        .filter((x) => x.week === undefined || x.week <= 52)
                        .map((x) => ({
                            value: x.value ?? 0,
                            asOfDate:
                                dataFrequency === DemeterDataFrequency.Monthly
                                    ? new Date(x.asOfDate!)
                                    : formattingService.getWeekStartDate(startYear, x.week!, 1),
                            isActualValue: x.isActualValue ?? true,
                        }));
                }

                if (values.length === 0) {
                    return [];
                }

                return [
                    {
                        label,
                        yearsOrNumberOfYears: yearOption.yearsOrNumberOfYears,
                        lineDataType: yearOption.lineDataType,
                        data: values,
                    } as IChartSeasonalDataSeries,
                ];
            });

        setLineSeries(newLineSeriesData);
    }, [seasonalData, translations, yearsSelected]);

    const handleYearsSelected = (newYearsSelected: YearOption[]) => {
        setYearsSelected(newYearsSelected);

        if (yearOptions.some((x) => x.value.lineDataType === 'forecast')) {
            setForecastOptionsSelected(newYearsSelected.filter((x) => x.lineDataType === 'forecast'));
        }
    };

    const sourceDataTag = useMemo(() => {
        if (!seasonalData?.rows) {
            return '';
        }

        // Add special note if we are using monthly data.
        if (dataFrequency === DemeterDataFrequency.Monthly) {
            return `${seasonalData.dataSourceTag}\u00A0 seasonalChartNote`;
        }

        return seasonalData.dataSourceTag ?? '';
    }, [seasonalData]);

    const isLoading = !seasonalData || lineSeries.length === 0 || !tableDefinitionCommodity;

    return (
        <ChartWrapper
            name="SeasonalChart"
            title={title}
            dataSourceTag={sourceDataTag}
            isLoading={isLoading}
            headerOptions={{
                isCsvDownloadAvailable: isExportDataOn && !disableTooltip,
            }}
            header={
                <div className={styles.seasonal_chart_years_dropdown_container}>
                    <CheckboxDropdown
                        options={yearOptions}
                        handleOptionSelect={handleYearsSelected}
                        values={yearsSelected}
                        placeholder={translations.dropdown.seasonalChart}
                        minimumSelectionLimit={miniumSelections}
                        maximumSelectionLimit={maximumSelections}
                        limitMessage={translations.charts.limitMessage.yearLimitMessage}
                    />
                </div>
            }
            testId={props.testId}
        >
            <SeasonalChartRaw
                linesSeries={lineSeries}
                dataFrequency={dataFrequency === DemeterDataFrequency.Monthly ? DemeterDataFrequency.Monthly : DemeterDataFrequency.Weekly}
                unitOfMeasure={seasonalData?.unitOfMeasure}
                displayDecimalPlacesMinimum={tableDefinitionCommodity?.displayDecimalPlacesMinimum ?? 0}
                displayDecimalPlacesMaximum={tableDefinitionCommodity?.displayDecimalPlacesMaximum ?? 0}
                disableTooltip={disableTooltip}
            />
        </ChartWrapper>
    );
};

export default SeasonalChart;
