import { Link } from 'react-router-dom';
import applicationSettings from '../../Core/Settings/ApplicationSettings';
import NavigationRoutes from '../../Layouts/NavigationRoutes';
import formattingService from '../../Refactor/Services/Formatting/FormattingService';
import { translationKeys } from '../../Refactor/Services/Language/TranslationDefinitions';
import useLanguage from '../../Refactor/Services/Language/useLanguageHook';
import styles from './Footer.module.scss';
import generatedReleaseData from './releaseData.json';

const Footer: React.FC = () => {
    const isDevelopmentLikeEnvironment = applicationSettings.isDevelopmentLike;
    const isLocalEnvironment = applicationSettings.environment === 'local';
    const [, translate] = useLanguage();
    const currentYear = new Date().getFullYear();

    return (
        <div className={styles.footer}>
            <div className={`${styles.master_flex} ${styles.footer_vertical_center}`}>
                <p className={styles.footer_margin_adjustment}>&copy; {`${currentYear} ${translate(translationKeys.footer.text.allRightsReserved)}`}</p>
                <div className={styles.footer_link_vertical_line}>&nbsp; | &nbsp;</div>
                <Link to={NavigationRoutes.PrivacyPolicy} className={styles.footer_link_text_style}>
                    {translate(translationKeys.footer.text.privacyPolicy)}
                </Link>
                <div className={styles.footer_link_vertical_line}>&nbsp; | &nbsp;</div>
                <Link className={styles.footer_link_text_style} to={NavigationRoutes.Disclaimer}>
                    {translate(translationKeys.footer.text.disclaimer)}
                </Link>
                <div className={styles.footer_link_vertical_line}>&nbsp; | &nbsp;</div>
                <a className={styles.footer_link_text_style} href="https:\\www.stonex.com ">
                    Stonex.com
                </a>
                <div className={isDevelopmentLikeEnvironment ? styles.footer_link_vertical_line : styles.footer_link_vertical_line_production}>
                    &nbsp; | &nbsp;
                </div>
                <p className={isDevelopmentLikeEnvironment ? styles.footer_release : styles.footer_release_production}>
                    {isLocalEnvironment
                        ? 'Local'
                        : `${generatedReleaseData?.ReleaseNumber} - ${formattingService.toTimestamp(
                              `${generatedReleaseData?.ReleaseDate}T${generatedReleaseData?.ReleaseTime}`,
                          )}`}
                </p>
            </div>
        </div>
    );
};

export default Footer;
