import { CellValueChangedEvent, ColDef, RowValueChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AgGridBuilder from '../../../../Components/AgGridBuilder/AgGridBuilder';
import scssVariables from '../../../../Config.module.scss';
import { Currency, DemeterCommodity, SymbolCategory, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import { MarketPriceModel } from '../../../../Redux/Slices/MarketPricesSlice';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import useUnitOfMeasureConversionApi from '../../../Apis/Hooks/useUnitOfMeasureConversionApiHook';
import Dropdown from '../../../Components/Form/Inputs/Dropdown';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import ShowMoreAccordian from '../../../Components/Tables/ShowMoreAccordian/ShowMoreAccordian';
import useMarketPricesForwardCurve from '../../../Core/Hooks/useMarketPricesForwardCurveHook';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './ForwardSpreadCalculator.module.scss';
import ForwardSpreadCalculatorChart from './ForwardSpreadCalculatorChart';
import {
    ForwardSpreadCalculatorRequest,
    forwardSpreadColumnDefinitions,
    forwardSpreadColumnOptions,
    ForwardSpreadDefinitionsType,
    ForwardSpreadDirection,
    ForwardSpreadRow,
    quarterlyComparisonColumnDefinitions,
    quarterlyComparisonColumnOptions,
    RendererParameters,
} from './ForwardSpreadCalculatorDefinitions';

// Right now we only Dairy commodities with only USD and EUR currencies.
const allowedCategories: SymbolCategory[] = [SymbolCategory.Dairy];
const allowedCurrencies: Currency[] = [Currency.Usd, Currency.Eur];
const allowedUnitOfMeasures: UnitOfMeasure[] = [
    UnitOfMeasure.HundredWeight,
    UnitOfMeasure.Kilogram,
    UnitOfMeasure.MetricTon,
    UnitOfMeasure.Pound,
    UnitOfMeasure.ShortTon,
];
const initialCommodity = DemeterCommodity.Butter;
const minimumNumberOfContracts = 10;
const notAllowedCommodities: DemeterCommodity[] = [DemeterCommodity.FluidMilk];
const reutersInstrumentCodePrefixEurUsd = 'URO';
const rowsToShow = 12;

type RowDataStatus = 'loading' | 'initializing' | 'fetching' | 'stable' | 'overriden';

const ForwardSpreadCalculator: React.FC = () => {
    // Application hooks.
    const [translations] = useLanguage();

    // Form/request object hooks.
    const [forwardSpreadCalculatorRequest, setForwardSpreadCalculatorRequest] = useState<ForwardSpreadCalculatorRequest>({
        direction1: ForwardSpreadDirection.Last,
        direction2: ForwardSpreadDirection.Last,
        currency: Currency.Usd,
        unitOfMeasure: UnitOfMeasure.Pound,
    });

    // Data hooks.
    const symbols = useSymbolsApi();
    const currencySymbol = symbols?.find((x) => x.reutersInstrumentCodePrefix === reutersInstrumentCodePrefixEurUsd);
    const [getUnitOfMeasureConversionRate1, unitOfMeasureConversionMap1] = useUnitOfMeasureConversionApi(
        forwardSpreadCalculatorRequest?.symbol1?.reutersInstrumentCodePrefix,
    );
    const [getUnitOfMeasureConversionRate2, unitOfMeasureConversionMap2] = useUnitOfMeasureConversionApi(
        forwardSpreadCalculatorRequest?.symbol2?.reutersInstrumentCodePrefix,
    );
    const [marketPrices1, refreshMarketPrices1] = useMarketPricesForwardCurve(forwardSpreadCalculatorRequest.symbol1);
    const [marketPrices2, refreshMarketPrices2] = useMarketPricesForwardCurve(forwardSpreadCalculatorRequest.symbol2);
    const [currencyPrices, refreshCurrencyPrices, currencySpotPrice] = useMarketPricesForwardCurve(currencySymbol);

    // State hooks.
    const [rowDataStatus, setRowDataStatus] = useState<RowDataStatus>('loading');
    const [forwardSpreadRowData, setForwardSpreadRowData] = useState<ForwardSpreadRow[]>([]);
    // We have to use another variable for the chart because we don't want to send it updates while processing.
    const [forwardSpreadRowDataForChart, setForwardSpreadRowDataForChart] = useState<ForwardSpreadRow[]>([]);
    const [quarterlyComparisonRowData, setQuarterlyComparisonRowData] = useState<ForwardSpreadRow[]>([]);
    // We have to use a reference to the data in order to retreive it in the grid value change callback.
    const forwardSpreadRowDataReference = useRef<ForwardSpreadRow[]>([]);
    forwardSpreadRowDataReference.current = forwardSpreadRowData;

    // Grid hooks.
    const [forwardSpreadColumnOverrideDefinitions, setForwardSpreadColumnOverrideDefinitions] = useState(forwardSpreadColumnDefinitions);
    const [quarterlyComparisonColumnOverrideDefinitions, setQuarterlyComparisonColumnOverrideDefinitions] = useState(quarterlyComparisonColumnDefinitions);
    const [gridHeightFull, setGridHeightFull] = useState(false);
    const contractComparisonGridReference = useRef<AgGridReact>();
    const quarterlyComparisonGridReference = useRef<AgGridReact>();

    const symbolDropdownOptions = useMemo(() => {
        if (!symbols || symbols.length === 0) {
            return [];
        }

        return symbols
            .filter((x) => allowedCategories.includes(x.symbolCategory as SymbolCategory))
            .filter((x) => !notAllowedCommodities.includes(x.commodity as DemeterCommodity))
            .filter((x) => x.symbolContracts.length >= minimumNumberOfContracts)
            .sort((a, b) => `${a.exchange}-${a.displayName}`.localeCompare(`${b.exchange}-${b.displayName}`))
            .map((x) => ({
                label: formattingService.toDisplayName(x),
                value: x,
            }));
    }, [symbols]);

    const spreadDirectionDropdownOptions = useMemo(
        () => [
            {
                label: translations.futures.fields.last,
                value: ForwardSpreadDirection.Last,
            },
            {
                label: translations.futures.fields.bidPrice,
                value: ForwardSpreadDirection.Bid,
            },
            {
                label: translations.futures.fields.askPrice,
                value: ForwardSpreadDirection.Ask,
            },
            {
                label: translations.futures.fields.settle,
                value: ForwardSpreadDirection.Settle,
            },
        ],
        [translations],
    );

    const currencyDropdownOptions = useMemo(
        () =>
            allowedCurrencies.map((x) => ({
                label: x,
                value: x,
            })),
        [translations],
    );

    const unitOfMeasureDropdownOptions = useMemo(
        () =>
            allowedUnitOfMeasures.map((x) => ({
                label: translations.unitOfMeasure[x],
                value: x,
            })),
        [translations],
    );

    // Set default symbols when we first load.
    useEffect(() => {
        if (!symbols) {
            return;
        }

        const initialOptions = symbolDropdownOptions.filter((x) => x.value.commodity === initialCommodity);

        setForwardSpreadCalculatorRequest({
            ...forwardSpreadCalculatorRequest,
            symbol1: initialOptions.length >= 2 ? initialOptions[0].value : symbolDropdownOptions[0].value,
            symbol2: initialOptions.length >= 2 ? initialOptions[1].value : symbolDropdownOptions[0].value,
            currency: symbolDropdownOptions[0].value.currency,
            unitOfMeasure: symbolDropdownOptions[0].value.unitOfMeasure,
        });
    }, [symbols]);

    // Set default current and unit of measure when the symbol1 changes.
    useEffect(() => {
        setForwardSpreadCalculatorRequest({
            ...forwardSpreadCalculatorRequest,
            currency: forwardSpreadCalculatorRequest.symbol1?.currency ?? Currency.Usd,
            unitOfMeasure: forwardSpreadCalculatorRequest.symbol1?.unitOfMeasure ?? UnitOfMeasure.Pound,
        });
    }, [forwardSpreadCalculatorRequest.symbol1]);

    // If any of the form parameters change, then we start the process over again.
    useEffect(() => {
        if (!forwardSpreadCalculatorRequest.symbol1 || !forwardSpreadCalculatorRequest.symbol2) {
            return;
        }

        setRowDataStatus('initializing');
    }, [forwardSpreadCalculatorRequest]);

    // Put the lightstreamer data into the array, only keeping the overlapping contracts.
    useEffect(() => {
        if (rowDataStatus !== 'initializing' || marketPrices1.length === 0 || marketPrices2.length === 0 || currencyPrices.length === 0) {
            return;
        }

        const defaultCurrencyMarketPrice = currencyPrices[0];

        let newRowData = forwardSpreadCalculatorRequest.symbol1!.symbolContracts.map((contract, index) => {
            const toComparator = (x: { year: number; month: number }) => x.year * 100 + x.month;
            let reutersInstrumentCodeCurrency: string = currencySymbol!.reutersInstrumentCodeSpotPrice!;

            // Find the closest valid currency contract.
            if (toComparator(contract) >= toComparator(currencySymbol!.symbolContracts[0])) {
                const filteredCurrencyContracts = currencySymbol?.symbolContracts.filter((x) => toComparator(x) >= toComparator(contract)) ?? [];
                const prices = filteredCurrencyContracts
                    .map((x) =>
                        currencyPrices.find((price) => {
                            if (price.reutersInstrumentCode !== x.reutersInstrumentCode) {
                                return false;
                            }

                            // Sometimes we get a contract but it doesn't have an actually value attached.
                            // This usually occurs during the first trade day of that contract.
                            const latestOrSettlementPrice = (price.latestPrice ?? 0) || (price.settlementPrice ?? 0);
                            return latestOrSettlementPrice > 0;
                        }),
                    )
                    .filter((x) => !!x);

                if (prices && prices.length > 0) {
                    reutersInstrumentCodeCurrency = prices[0]!.reutersInstrumentCode;

                    // TODO: If we don't have a matching currency contract, then interpolate.
                }
            }

            // If we don't have a market price for the matching currency contract, then use the default.
            const currencyMarketPrice = currencyPrices.find((x) => x.reutersInstrumentCode === reutersInstrumentCodeCurrency) ?? defaultCurrencyMarketPrice;
            let currencyLatestPrice = !currencyMarketPrice ? 1.0 : currencyMarketPrice.latestPrice ?? currencyMarketPrice.settlementPrice ?? 1;

            // Use the spot price if it is the spot code.
            if (reutersInstrumentCodeCurrency === currencySymbol!.reutersInstrumentCodeSpotPrice) {
                currencyLatestPrice = currencySpotPrice ?? 1.0;
            }

            // Get the currency conversion rates.
            let currencyConversionRate1 = 1.0;
            let currencyConversionRate2 = 1.0;

            if (forwardSpreadCalculatorRequest.currency !== forwardSpreadCalculatorRequest.symbol1?.currency) {
                currencyConversionRate1 = currencyLatestPrice;
            }

            if (forwardSpreadCalculatorRequest.currency !== forwardSpreadCalculatorRequest.symbol2?.currency) {
                currencyConversionRate2 = currencyLatestPrice;
            }

            const marketPrice1 = marketPrices1.find((x) => x.reutersInstrumentCode === contract.reutersInstrumentCode);
            const contract2 = forwardSpreadCalculatorRequest.symbol2!.symbolContracts.find((x) => x.month === contract.month && x.year === contract.year);
            const marketPrice2 = marketPrices2.find((x) => x.reutersInstrumentCode === contract2?.reutersInstrumentCode);

            return {
                id: `row-${index}`,
                month: contract.month,
                year: contract.year,
                quarter: Math.floor(contract.month / 3) + 1,
                symbolContract1: contract,
                marketPrice1,
                value1: marketPrice1?.settlementPrice ? marketPrice1.settlementPrice * contract.priceMultiplier : undefined,
                overrideValue1: undefined,
                currencyConversionRate1,
                overrideCurrencyConversionRate1: undefined,
                convertedValue1: undefined,
                symbolContract2: contract2,
                marketPrice2,
                value2: marketPrice2?.settlementPrice ? marketPrice2.settlementPrice * contract2!.priceMultiplier : undefined,
                overrideValue2: undefined,
                currencyConversionRate2,
                overrideCurrencyConversionRate2: undefined,
                convertedValue2: undefined,
                spread: undefined,
                eurToUsdCurrencyConversionRate: currencyLatestPrice,
            } as ForwardSpreadRow;
        });

        newRowData = newRowData.filter((x) => x.symbolContract1 && x.symbolContract2);

        const getValue = (marketPrice: MarketPriceModel | undefined, direction: ForwardSpreadDirection, multiplier: number) => {
            let value = marketPrice?.settlementPrice;

            if (direction === ForwardSpreadDirection.Last) {
                value = marketPrice?.latestPrice ?? marketPrice?.settlementPrice;
            } else if (direction === ForwardSpreadDirection.Bid) {
                value = marketPrice?.bidPrice;
            } else if (direction === ForwardSpreadDirection.Ask) {
                value = marketPrice?.askPrice;
            }

            return value ? value * multiplier : value;
        };

        newRowData.forEach((x) => {
            x.value1 = getValue(x.marketPrice1, forwardSpreadCalculatorRequest.direction1, forwardSpreadCalculatorRequest.symbol1?.priceMultiplierFutures ?? 1);
            x.value2 = getValue(x.marketPrice2, forwardSpreadCalculatorRequest.direction2, forwardSpreadCalculatorRequest.symbol2?.priceMultiplierFutures ?? 1);
        });

        setRowDataStatus('stable');
        setForwardSpreadRowData(newRowData);
    }, [rowDataStatus, marketPrices1, marketPrices2, currencyPrices]);

    // Detect changes in the data and re-calculate.
    useEffect(() => {
        if (rowDataStatus !== 'stable' && rowDataStatus !== 'overriden') {
            return;
        }

        const newRowData = forwardSpreadRowData.map((x) => {
            if (x.overrideValue1 !== undefined || x.value1 !== undefined) {
                const valueToUse = x.overrideValue1 !== undefined ? x.overrideValue1 : x.value1!;
                let currencyConversionRate =
                    x.overrideCurrencyConversionRate1 !== undefined ? x.overrideCurrencyConversionRate1! : x.currencyConversionRate1 ?? 1.0;

                if (forwardSpreadCalculatorRequest.currency !== forwardSpreadCalculatorRequest.symbol1?.currency) {
                    currencyConversionRate = forwardSpreadCalculatorRequest.currency === Currency.Eur ? 1.0 / currencyConversionRate : currencyConversionRate;
                }

                x.convertedValue1 = valueToUse * currencyConversionRate * getUnitOfMeasureConversionRate1(forwardSpreadCalculatorRequest.unitOfMeasure);
            } else {
                x.convertedValue1 = undefined;
            }

            if (x.overrideValue2 !== undefined || x.value2 !== undefined) {
                const valueToUse = x.overrideValue2 !== undefined ? x.overrideValue2 : x.value2!;
                let currencyConversionRate =
                    x.overrideCurrencyConversionRate2 !== undefined ? x.overrideCurrencyConversionRate2! : x.currencyConversionRate2 ?? 1.0;

                if (forwardSpreadCalculatorRequest.currency !== forwardSpreadCalculatorRequest.symbol2?.currency) {
                    currencyConversionRate = forwardSpreadCalculatorRequest.currency === Currency.Eur ? 1.0 / currencyConversionRate : currencyConversionRate;
                }

                x.convertedValue2 = valueToUse * currencyConversionRate * getUnitOfMeasureConversionRate2(forwardSpreadCalculatorRequest.unitOfMeasure);
            } else {
                x.convertedValue2 = undefined;
            }

            if (x.convertedValue1 !== undefined && x.convertedValue2 !== undefined) {
                x.spread = x.convertedValue1 - x.convertedValue2;
            }

            return { ...x };
        });

        setRowDataStatus('stable');
        setForwardSpreadRowData(newRowData);
        setForwardSpreadRowDataForChart(newRowData);
    }, [rowDataStatus, unitOfMeasureConversionMap1, unitOfMeasureConversionMap2]);

    // Update the grid definitions to detect value changes.
    useEffect(() => {
        const childIndex = 0;
        const value1ColumnIndex = 1;
        const value2ColumnIndex = 2;
        const value2QuarterlyColumnIndex = 3;

        if (rowDataStatus === 'loading') {
            const valueChanged = (event: (RowValueChangedEvent | CellValueChangedEvent) & { colDef: ColDef; oldValue: number; newValue: number }) => {
                const rowInGrid = event.data as ForwardSpreadRow;
                const rowInState = forwardSpreadRowDataReference.current.find((x) => x.id === event.data.id)!;

                const isFirstValue = !event.colDef.field || event.colDef.field?.indexOf('1') > 0;
                const { oldValue, newValue } = event;

                if (oldValue === newValue) {
                    return event;
                }

                if (isFirstValue) {
                    rowInGrid.overrideValue1 = newValue;
                    rowInState.overrideValue1 = newValue;
                } else {
                    rowInGrid.overrideValue2 = newValue;
                    rowInState.overrideValue2 = newValue;
                }

                setForwardSpreadRowData([...forwardSpreadRowDataReference.current]);
                setRowDataStatus('overriden');
                return event;
            };

            const newForwardSpreadDefinitions: any = [...forwardSpreadColumnDefinitions];
            const newQuarterComparisonDefinitions: any = [...quarterlyComparisonColumnDefinitions];
            newForwardSpreadDefinitions[value1ColumnIndex].children[childIndex].onCellValueChanged = valueChanged;
            newForwardSpreadDefinitions[value1ColumnIndex].children[childIndex].rowValueChanged = valueChanged;

            newForwardSpreadDefinitions[value2ColumnIndex].children[childIndex].onCellValueChanged = valueChanged;
            newForwardSpreadDefinitions[value2ColumnIndex].children[childIndex].rowValueChanged = valueChanged;

            newForwardSpreadDefinitions[value1ColumnIndex].children[childIndex].headerName = formattingService.toDisplayName(
                forwardSpreadCalculatorRequest.symbol1,
            );
            newForwardSpreadDefinitions[value2ColumnIndex].children[childIndex].headerName = formattingService.toDisplayName(
                forwardSpreadCalculatorRequest.symbol2,
            );
            newForwardSpreadDefinitions[value1ColumnIndex].headerName = formattingService.toDisplayName(forwardSpreadCalculatorRequest.symbol1);
            newForwardSpreadDefinitions[value2ColumnIndex].headerName = formattingService.toDisplayName(forwardSpreadCalculatorRequest.symbol2);
            newQuarterComparisonDefinitions[value1ColumnIndex].headerName = formattingService.toDisplayName(forwardSpreadCalculatorRequest.symbol1);
            newQuarterComparisonDefinitions[value2QuarterlyColumnIndex].headerName = formattingService.toDisplayName(forwardSpreadCalculatorRequest.symbol2);

            setForwardSpreadColumnOverrideDefinitions(newForwardSpreadDefinitions);
            setQuarterlyComparisonColumnOverrideDefinitions(newQuarterComparisonDefinitions);
        } else {
            const existingForwardSpreadDefinitions = contractComparisonGridReference?.current?.api?.getColumnDefs() as ForwardSpreadDefinitionsType[];
            const existingQuarterComparisonDefinitions = quarterlyComparisonGridReference?.current?.api?.getColumnDefs() as ColDef[];
            if (existingForwardSpreadDefinitions?.length > 0 && existingQuarterComparisonDefinitions?.length > 0) {
                existingForwardSpreadDefinitions[value1ColumnIndex].children[childIndex].headerName = formattingService.toDisplayName(
                    forwardSpreadCalculatorRequest.symbol1,
                );
                existingForwardSpreadDefinitions[value2ColumnIndex].children[childIndex].headerName = formattingService.toDisplayName(
                    forwardSpreadCalculatorRequest.symbol2,
                );
                existingForwardSpreadDefinitions[value1ColumnIndex].headerName = formattingService.toDisplayName(forwardSpreadCalculatorRequest.symbol1);
                existingForwardSpreadDefinitions[value2ColumnIndex].headerName = formattingService.toDisplayName(forwardSpreadCalculatorRequest.symbol2);
                existingQuarterComparisonDefinitions[value1ColumnIndex].headerName = formattingService.toDisplayName(forwardSpreadCalculatorRequest.symbol1);
                existingQuarterComparisonDefinitions[value2QuarterlyColumnIndex].headerName = formattingService.toDisplayName(
                    forwardSpreadCalculatorRequest.symbol2,
                );
                contractComparisonGridReference?.current?.api?.setColumnDefs([...existingForwardSpreadDefinitions]);
                quarterlyComparisonGridReference?.current?.api?.setColumnDefs([...existingQuarterComparisonDefinitions]);
            }
        }
    }, [forwardSpreadCalculatorRequest]);

    // Calculate quarterly comparison data when any data in the row changes.
    useEffect(() => {
        const quarterlyRows: ForwardSpreadRow[] = [];
        let lastRow: ForwardSpreadRow | undefined;

        forwardSpreadRowData.forEach((x) => {
            if (lastRow === undefined && x.month % 3 !== 1) {
                return;
            }

            const value1 = x.overrideValue1 ?? x.value1;
            const value2 = x.overrideValue2 ?? x.value2;

            if (x.month % 3 === 1) {
                lastRow = { ...x, value1, value2 };
                return;
            }

            // Sum up the values that matter.
            lastRow!.value1 = lastRow!.value1 === undefined || value1 === undefined ? undefined : lastRow!.value1 + value1;
            lastRow!.value2 = lastRow!.value2 === undefined || value2 === undefined ? undefined : lastRow!.value2 + value2;
            lastRow!.overrideValue1 = lastRow!.overrideValue1 ?? x.overrideValue1;
            lastRow!.overrideValue2 = lastRow!.overrideValue2 ?? x.overrideValue2;
            lastRow!.convertedValue1 =
                lastRow!.convertedValue1 === undefined || x.convertedValue1 === undefined ? undefined : lastRow!.convertedValue1 + x.convertedValue1;
            lastRow!.convertedValue2 =
                lastRow!.convertedValue2 === undefined || x.convertedValue2 === undefined ? undefined : lastRow!.convertedValue2 + x.convertedValue2;
            lastRow!.spread = lastRow!.spread === undefined || x.spread === undefined ? undefined : lastRow!.spread + x.spread;

            if (lastRow && x.month % 3 === 0) {
                quarterlyRows.push({
                    ...lastRow,
                    value1: lastRow.value1 === undefined ? undefined : lastRow.value1 / 3.0,
                    value2: lastRow.value2 === undefined ? undefined : lastRow.value2 / 3.0,
                    convertedValue1: lastRow.convertedValue1 === undefined ? undefined : lastRow.convertedValue1 / 3.0,
                    convertedValue2: lastRow.convertedValue2 === undefined ? undefined : lastRow.convertedValue2 / 3.0,
                    spread: lastRow.spread === undefined ? undefined : lastRow.spread / 3.0,
                });
            }
        });

        setQuarterlyComparisonRowData(quarterlyRows);
    }, [forwardSpreadRowData]);

    // Refresh the cells once any data changes.
    useEffect(() => {
        contractComparisonGridReference?.current?.api?.refreshCells();
        quarterlyComparisonGridReference?.current?.api?.refreshCells();
    }, [forwardSpreadRowData, quarterlyComparisonRowData]);

    const title = useMemo(
        () =>
            `${formattingService.toDisplayName(forwardSpreadCalculatorRequest.symbol1)} ${translations.words.vs} ${formattingService.toDisplayName(
                forwardSpreadCalculatorRequest.symbol2,
            )}`,
        [forwardSpreadCalculatorRequest],
    );

    const resetPrices = useCallback(() => {
        refreshMarketPrices1();
        refreshMarketPrices2();
        refreshCurrencyPrices();
        setRowDataStatus('initializing');
    }, []);

    const getRowStyle = useCallback((parameters: RendererParameters) => {
        if ((!parameters.data.value1 && !parameters.data.overrideValue1) || (!parameters.data.value2 && !parameters.data.overrideValue2)) {
            return { background: scssVariables.tableRowInvalid };
        }

        if (parameters.data.overrideValue1 !== undefined || parameters.data.overrideValue2 !== undefined) {
            return { background: scssVariables.tableRowValid };
        }

        return parameters.node.rowIndex % 2 === 0 ? { background: scssVariables.tableRowDark } : { background: scssVariables.tableRowLight };
    }, []);

    return rowDataStatus !== 'stable' && forwardSpreadRowData.length === 0 ? (
        <PageLoadingSpinner />
    ) : (
        <div>
            <ComponentHeader title={translations.calculators.text.forwardSpreadCalculator} />
            <div className={styles.spread_calculator_request_and_chart_container}>
                <div data-testid="ForwardSpreadCalculator" className={styles.spread_calculator_dropdown_inputs_container}>
                    <div className={styles.spread_calculator_dropdown_row}>
                        <div className={styles.spread_calculator_half_width_left}>
                            <p className={styles.spread_calculator_input_label}>{translations.words.product} 1</p>
                            <Dropdown
                                value={forwardSpreadCalculatorRequest?.symbol1 ?? null}
                                options={symbolDropdownOptions}
                                handleOptionChange={(value) => {
                                    setForwardSpreadCalculatorRequest({
                                        ...forwardSpreadCalculatorRequest,
                                        symbol1: value ?? undefined,
                                    });
                                }}
                            />
                        </div>
                        <div className={styles.spread_calculator_half_width_right}>
                            <p className={styles.spread_calculator_input_label}>{translations.words.product} 2</p>
                            <Dropdown
                                value={forwardSpreadCalculatorRequest?.symbol2 ?? null}
                                options={symbolDropdownOptions}
                                handleOptionChange={(value) => {
                                    setForwardSpreadCalculatorRequest({
                                        ...forwardSpreadCalculatorRequest,
                                        symbol2: value ?? undefined,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.spread_calculator_dropdown_row}>
                        <div className={styles.spread_calculator_half_width_left}>
                            <p className={styles.spread_calculator_input_label}>{translations.words.direction}</p>
                            <Dropdown
                                value={forwardSpreadCalculatorRequest?.direction1}
                                options={spreadDirectionDropdownOptions}
                                handleOptionChange={(value) => {
                                    setForwardSpreadCalculatorRequest({
                                        ...forwardSpreadCalculatorRequest,
                                        direction1: value,
                                    });
                                }}
                            />
                        </div>
                        <div className={styles.spread_calculator_half_width_right}>
                            <p className={styles.spread_calculator_input_label}>{translations.words.direction}</p>
                            <Dropdown
                                value={forwardSpreadCalculatorRequest?.direction2}
                                options={spreadDirectionDropdownOptions}
                                handleOptionChange={(value) => {
                                    setForwardSpreadCalculatorRequest({
                                        ...forwardSpreadCalculatorRequest,
                                        direction2: value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.spread_calculator_gray_divider} />
                    <div className={styles.spread_calculator_dropdown_conversions_row}>
                        <div className={styles.spread_calculator_half_width_left}>
                            <p className={styles.spread_calculator_input_label}>{translations.words.currency}</p>
                            <Dropdown
                                value={forwardSpreadCalculatorRequest?.currency}
                                options={currencyDropdownOptions}
                                handleOptionChange={(value) => {
                                    setForwardSpreadCalculatorRequest({
                                        ...forwardSpreadCalculatorRequest,
                                        currency: value,
                                    });
                                }}
                            />
                        </div>
                        <div className={styles.spread_calculator_half_width_right}>
                            <p className={styles.spread_calculator_input_label}>{translations.words.unit}</p>
                            <Dropdown
                                value={forwardSpreadCalculatorRequest?.unitOfMeasure}
                                options={unitOfMeasureDropdownOptions}
                                handleOptionChange={(value) => {
                                    setForwardSpreadCalculatorRequest({ ...forwardSpreadCalculatorRequest, unitOfMeasure: value });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.spread_calculator_chart_container}>
                    {forwardSpreadCalculatorRequest.symbol1 && forwardSpreadCalculatorRequest.symbol2 && (
                        <ForwardSpreadCalculatorChart
                            title={title}
                            forwardSpreadCalculatorRequest={forwardSpreadCalculatorRequest}
                            forwardSpreadRowData={forwardSpreadRowDataForChart}
                            testId="ForwardSpreadCalculatorChart"
                        />
                    )}
                </div>
            </div>
            <div className={styles.spread_calculator_chart_top_right}>
                <button
                    className={styles.spread_calculator_button_reset}
                    type="button"
                    onClick={() => {
                        resetPrices();
                    }}
                >
                    {translations.calculators.actions.resetPrices}
                </button>
            </div>
            <ComponentHeader title={title} />
            <div className={styles.spread_calculator_grid_wrapper}>
                <AgGridBuilder
                    domLayout="autoHeight"
                    gridRef={contractComparisonGridReference}
                    rowData={!gridHeightFull ? forwardSpreadRowData.slice(0, 11) : forwardSpreadRowData}
                    columnDefinitions={forwardSpreadColumnOverrideDefinitions}
                    defaultColumnDefinition={forwardSpreadColumnOptions}
                    getRowStyle={getRowStyle}
                    gridHeightFull={gridHeightFull}
                    disableExport
                    suppressMovableColumns
                    testId="ForwardSpreadCalculatorComparisonTable"
                />
                <ShowMoreAccordian
                    showAccordian={forwardSpreadRowData.length > rowsToShow}
                    showMore={gridHeightFull}
                    handleShowMoreToggle={(value) => setGridHeightFull(value)}
                />
            </div>
            <div className={styles.spread_calculator_table_key}>
                <p className={styles.spread_calculator_key_valid}>{translations.calculators.text.containsUserEnteredValue}</p>
                <p className={styles.spread_calculator_key_invalid}>{translations.calculators.text.noPriceData}</p>
            </div>
            <ComponentHeader title={translations.calculators.text.quarterly} />
            <div className={styles.spread_calculator_grid_wrapper}>
                <AgGridBuilder
                    domLayout="autoHeight"
                    gridRef={quarterlyComparisonGridReference}
                    rowData={quarterlyComparisonRowData}
                    columnDefinitions={quarterlyComparisonColumnOverrideDefinitions}
                    defaultColumnDefinition={quarterlyComparisonColumnOptions}
                    getRowStyle={getRowStyle}
                    gridHeightFull={false}
                    disableExport
                    suppressMovableColumns
                    testId="ForwardSpreadCalculatorQuarterlyComparisonTable"
                />
            </div>
        </div>
    );
};

export default memo(ForwardSpreadCalculator);
