import React, { useMemo } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import scssVariables from '../../../Config.module.scss';
import { MarketIndicatorModel, MarketIndicatorOutlook } from '../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import GuageChartRaw from '../../Components/Charts/Guage/GuageChartRaw';
import ComponentHeader from '../../Components/Headers/ComponentHeader';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import formattingService from '../../Services/Formatting/FormattingService';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './MarketIndicatorFactorsPage.module.scss';

export interface IMarketIndicatorGuageChartProps {
    title?: string;
    marketIndicator: MarketIndicatorModel;
    chartDimensions?: { chartSize?: number; dialRadius?: number };
    isSmallGuage?: boolean;
}

const MarketIndicatorGuageChart: React.FC<IMarketIndicatorGuageChartProps> = (props: IMarketIndicatorGuageChartProps) => {
    // Application hooks.
    const navigate = useNavigate();
    const [translations] = useLanguage();
    const isLoading = !props.marketIndicator;

    // Chart hooks.
    const guageSeries = useMemo(
        () => ({
            label: translations.calculators.fields.spread,
            data: {
                asOfDate: props.marketIndicator.asOfDate,
                outlookDecile: props.marketIndicator?.outlookDecile ? 100 - props.marketIndicator.outlookDecile : 0,
            },
        }),
        [props.marketIndicator],
    );

    const brandingTitleString = useMemo(() => translations.marketIndicators.brandingTitleString, [translations]);
    const headerWrapperStyle = props.isSmallGuage ? styles.guage_heading_wrapper_small : styles.guage_heading_wrapper;
    const guageValueStyle = props.isSmallGuage ? styles.guage_value_small : styles.guage_value;
    const numberOfPercentDecimalPlaces = props.isSmallGuage ? 0 : undefined;

    const handleClick = () => {
        navigate({
            pathname: NavigationRoutes.MarketIndicators,
            search: createSearchParams({ marketIndicatorGuid: props.marketIndicator.marketIndicatorGuid }).toString(),
        });
    };

    return isLoading ? (
        <PageLoadingSpinner />
    ) : (
        <div
            className={props.isSmallGuage ? styles.guage_card_small : styles.guage_card}
            onClick={props.isSmallGuage ? handleClick : undefined}
            onKeyPress={() => {}}
            role="button"
            tabIndex={props.isSmallGuage ? 0 : -1}
            data-testid="MarketIndicatorGuage"
        >
            <div className={props.isSmallGuage ? styles.guage_title_small : styles.guage_title}>
                <div className={styles.guage_branding_title}>{brandingTitleString}</div>
                <ComponentHeader title={props.title} />
            </div>
            <GuageChartRaw guageSeries={guageSeries} chartDimensions={props.chartDimensions} />
            <div className={styles.guage_100}>
                <div className={!props.isSmallGuage ? styles.guage : styles.guage_small}>
                    <div style={{ color: scssVariables.veryBearishColor }}>{translations.marketIndicatorOutlook[MarketIndicatorOutlook.VeryBearish]}</div>
                    {!props.isSmallGuage && (
                        <div style={{ color: scssVariables.bearishColor }}>{translations.marketIndicatorOutlook[MarketIndicatorOutlook.Bearish]}</div>
                    )}
                    <div style={{ color: scssVariables.neutralColor }}>{translations.marketIndicatorOutlook[MarketIndicatorOutlook.Flat]}</div>
                    {!props.isSmallGuage && (
                        <div style={{ color: scssVariables.bullishColor }}>{translations.marketIndicatorOutlook[MarketIndicatorOutlook.Bullish]}</div>
                    )}
                    <div style={{ color: scssVariables.veryBullishColor }}>{translations.marketIndicatorOutlook[MarketIndicatorOutlook.VeryBullish]}</div>
                </div>
                <div className={styles.guage_table}>
                    <div className={props.isSmallGuage ? styles.guage_table_bar_small : styles.guage_table_bar}>
                        <div className={headerWrapperStyle}>{formattingService.toShortDayMonthYear(props.marketIndicator.asOfDate)}</div>
                        <div className={headerWrapperStyle}>{`% ${translations.text.monthOverMonth}`}</div>
                        <div className={headerWrapperStyle}>{`% ${translations.words.shortYearOverYear}`}</div>
                    </div>
                    <div className={props.isSmallGuage ? styles.guage_table_bar_small : styles.guage_table_bar}>
                        <div className={guageValueStyle}>{`${props.marketIndicator.currentPrice}`}</div>
                        <div className={guageValueStyle}>
                            <div className={(props.marketIndicator.monthOverMonthChangePercent ?? 0) < 0 ? styles.guage_negative : styles.guage_positive}>
                                <div className={props.isSmallGuage ? styles.guage_month_over_month_small : styles.guage_month_over_month}>
                                    {`${formattingService.toPercentWithoutPlaceholder(
                                        props.marketIndicator.monthOverMonthChangePercent ?? 0,
                                        numberOfPercentDecimalPlaces,
                                        numberOfPercentDecimalPlaces,
                                    )}`}
                                </div>
                            </div>
                        </div>
                        <div className={guageValueStyle}>
                            <div className={(props.marketIndicator.rollingYearChangePercent ?? 0) < 0 ? styles.guage_negative : styles.guage_positive}>
                                {`${formattingService.toPercentWithoutPlaceholder(
                                    props.marketIndicator.rollingYearChangePercent ?? 0,
                                    numberOfPercentDecimalPlaces,
                                    numberOfPercentDecimalPlaces,
                                )}`}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketIndicatorGuageChart;
