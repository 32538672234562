import React, { useEffect, useMemo, useState } from 'react';
import { UnitOfMeasure } from '../../../../Generated/Raven';
import { DemeterFeatureType, DemeterFilterTimeSpan, DemeterRegion, DemeterTableDefinitionType } from '../../../../Generated/Raven-Demeter';
import useProjectionApi, { CommodityMonthlyProjectionModel, ListCommodityMonthlyProjectionResponse } from '../../../Apis/Hooks/useProjectionApiHook';
import featureFlagsService from '../../../Services/FeatureFlags/FeatureFlagsService';
import useFeatureFlag from '../../../Services/FeatureFlags/useFeatureFlagHook';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { IRegionCommoditySelection } from '../../Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Navigation/Hooks/useTableDefinitionHook';
import { IChartBarDataSeries, IChartData, IChartDataSeries } from '../ChartDefinitions';
import ChartWrapper from '../ChartWrapper/ChartWrapper';
import FilterTimeSpans from '../FilterTimeSpans/FilterTimeSpans';
import ProjectionChartRaw from './ProjectionChartRaw';

export interface IProjectionChartProps {
    title?: string;
    subtitle?: string | ((data: ListCommodityMonthlyProjectionResponse) => string);
    hideCommodityDisplayName?: boolean;
    tableDefinitionType: DemeterTableDefinitionType;
    regionCommoditySelection: IRegionCommoditySelection;
    aggregateRegions?: DemeterRegion[];
    unitOfMeasure?: UnitOfMeasure;
    solidLineFields?: { fieldName: string; title: string }[];
    testId?: string;
    showPopout?: boolean;
    showOnlyAsPopout?: boolean;
    setShowPopout?: (showPopout: boolean) => void;
}

const defaultFilterTimeSpan = DemeterFilterTimeSpan.FiveYears;
const barSeriesDecimalPlaces = 2;

const availableFilterTimeSpans = [
    DemeterFilterTimeSpan.OneYear,
    DemeterFilterTimeSpan.FiveYears,
    DemeterFilterTimeSpan.TenYears,
    DemeterFilterTimeSpan.TwentyYears,
    DemeterFilterTimeSpan.All,
];

const ProjectionChart: React.FC<IProjectionChartProps> = (props: IProjectionChartProps) => {
    // Data/navigation hooks.
    const [translations, translate] = useLanguage();
    const [tableDefinitionRegion, tableDefinitionCommodity] = useTableDefinition(props.tableDefinitionType, props.regionCommoditySelection);

    const [filterTimeSpan, setFilterTimeSpan] = useState<DemeterFilterTimeSpan>(defaultFilterTimeSpan);
    const projectionData = useProjectionApi(
        props.tableDefinitionType,
        props.regionCommoditySelection,
        props.aggregateRegions,
        props.unitOfMeasure,
        filterTimeSpan,
    );

    const [lineSeries, setLineSeries] = useState<IChartDataSeries[]>([]);
    const [barSeries, setBarSeries] = useState<IChartBarDataSeries>({
        label: translations.charts.text.yearOverYearChange,
        forecastLabel: translations.words.forecast,
        data: [],
    });
    const isExportDataOn = useFeatureFlag(featureFlagsService.getFeatureType(DemeterFeatureType.DownloadsGeneral, props.tableDefinitionType));

    // Display hooks.
    const title = useMemo(
        () =>
            `${
                props.regionCommoditySelection.subRegion ? translate(props.regionCommoditySelection.subRegion) : translate(tableDefinitionRegion?.displayName!)
            }${!props.hideCommodityDisplayName ? `${tableDefinitionCommodity ? ` ${translate(tableDefinitionCommodity?.displayName)}` : ''}` : ''} ${
                props.title ? props.title : translations.tableDefinitionType[props.tableDefinitionType]
            }`,
        [props.title, props.regionCommoditySelection?.subRegion, tableDefinitionRegion, tableDefinitionCommodity],
    );

    const subtitle = useMemo(() => (typeof props.subtitle === 'function' ? props.subtitle(projectionData!) : props.subtitle), [projectionData, translations]);

    useEffect(() => {
        if (!projectionData?.rows) {
            return;
        }

        const lineSeriesData: IChartData[] = [];
        const barSeriesData: IChartData[] = [];
        const solidLineFieldValues: IChartDataSeries[] =
            props?.solidLineFields?.map((solidLine) => ({
                label: solidLine.title,
                forecastLabel: `${solidLine.title} ${translations.words.forecast}`,
                data: [],
            })) ?? [];

        projectionData?.rows.forEach((row: CommodityMonthlyProjectionModel) => {
            if (row.normalizedValue) {
                lineSeriesData.push({
                    value: row.normalizedValue!,
                    asOfDate: new Date(row.asOfDate),
                    isActualValue: row.isActualValue!,
                });
            }

            if (row.monthOverMonthChangePercent) {
                barSeriesData.push({
                    value: row.monthOverMonthChangePercent!,
                    asOfDate: new Date(row.asOfDate),
                    isActualValue: row.isActualValue ?? true,
                });
            }

            if (props.solidLineFields && props.solidLineFields.length > 0) {
                props.solidLineFields?.forEach((solidLine, index) => {
                    solidLineFieldValues[index].data.push({
                        value: row[solidLine.fieldName as keyof CommodityMonthlyProjectionModel] as number,
                        asOfDate: new Date(row.asOfDate),
                        isActualValue: row.isActualValue ?? true,
                    });
                });
            }
        });

        setLineSeries([
            {
                label: translations.words.history,
                forecastLabel: translations.charts.text.forecastData,
                data: lineSeriesData,
            },
            ...solidLineFieldValues,
        ]);

        setBarSeries({
            label: translations.charts.text.yearOverYearChange,
            forecastLabel: translations.words.forecast,
            data: barSeriesData,
            format: 'percent',
            displayDecimalPlacesMinimum: barSeriesDecimalPlaces,
            displayDecimalPlacesMaximum: barSeriesDecimalPlaces,
            isHidden: props.tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyMarginTable,
        });
    }, [projectionData]);

    const sourceDataTag = useMemo(() => {
        if (!projectionData?.rows) {
            return '';
        }

        // Add a note if we are using normalized data.
        if (projectionData.rows.length > 0 && projectionData.rows.every((x: { alwaysShowActualValue: boolean }) => !x.alwaysShowActualValue)) {
            return `${projectionData.dataSourceTag}\u00A0 projectionChartNote`;
        }

        return projectionData.dataSourceTag ?? '';
    }, [projectionData]);

    const isLoading = !projectionData || lineSeries.length === 0 || !tableDefinitionCommodity;

    return (
        <ChartWrapper
            name="ProjectionChart"
            title={title}
            subtitle={subtitle}
            dataSourceTag={sourceDataTag}
            isLoading={isLoading}
            footer={
                <FilterTimeSpans
                    name="ProjectionChart"
                    filterTimeSpanOptions={availableFilterTimeSpans}
                    filterTimeSpan={filterTimeSpan}
                    handleTimeSpanSelected={(timeSpan) => setFilterTimeSpan(timeSpan)}
                />
            }
            showPopout={props.showPopout}
            setShowPopout={props.setShowPopout}
            headerOptions={{
                isCsvDownloadAvailable: isExportDataOn,
                showOnlyAsPopout: props.showOnlyAsPopout,
            }}
            testId={props.testId}
        >
            <ProjectionChartRaw
                linesSeries={lineSeries}
                barSeries={barSeries}
                unitOfMeasure={projectionData?.unitOfMeasure}
                displayDecimalPlacesMinimum={tableDefinitionCommodity?.displayDecimalPlacesMinimum ?? 0}
                displayDecimalPlacesMaximum={tableDefinitionCommodity?.displayDecimalPlacesMaximum ?? 0}
            />
        </ChartWrapper>
    );
};

export default ProjectionChart;
