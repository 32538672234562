/* eslint-disable newline-per-chained-call */
/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import { format } from 'date-fns';
import moment from 'moment';
import { MonthNamesEnum } from '../../../Core/Common/MonthNames';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import { DemeterDataFrequency, DemeterSymbolModel } from '../../../Generated/Raven-Demeter';
import languageService from '../Language/LanguageService';

export interface ThisContext {
    x: string;
    y: number;
    point: {
        color: string;
    };
    series: {
        name: string;
    };
}

class FormattingService {
    // Trim the string with the given characters: https://stackoverflow.com/a/55292366.
    trimStringEnd = (value: string, characterToTrim: string): string => {
        let end = value.length;

        while (end > 0 && value[end - 1] === characterToTrim) {
            end -= 1;
        }

        return end < value.length ? value.substring(0, end) : value;
    };

    // Format to a date we can use for the api.
    toApiDate = (date: Date | string, nonLocalizedDate?: boolean): string => {
        if ((typeof date === 'string' && date.indexOf('T') > 0) || nonLocalizedDate) {
            // This is already in ISO format.
            return moment(date).format('YYYY-MM-DD');
        }
        if (typeof date === 'string') {
            return moment(date, 'L').format('YYYY-MM-DD');
        }

        return format(date, 'yyyy-MM-dd');
    };

    getWeekStartDate = (year: number, week: number, dayOffset: number | undefined = 0) =>
        moment().year(year).week(week).startOf('week').add(dayOffset, 'days').toDate();

    // Formats to 'Apr 18, 2023 12:06 PM'.
    toTimestamp = (date: Date | string | null | undefined): string => {
        if (!date) {
            return '';
        }

        return moment(typeof date === 'string' ? new Date(date) : date)
            .local()
            .format('lll');
    };

    // Formats to 'Apr 18, 2023 12:06 PM'.
    toTimestampFromUtc = (date: Date | string | null | undefined): string => {
        if (!date) {
            return '';
        }

        let newDate = date;
        if (typeof date === 'string' && !date.endsWith('Z')) {
            newDate = `${date}Z`;
        }

        return moment
            .utc(typeof newDate === 'string' ? new Date(newDate) : newDate)
            .local()
            .format('lll');
    };

    // Formats to 'Thu, 8 Jun 2023'.
    toDayOfWeekWithDayMonthYear = (date: Date | string | null | undefined): string => {
        if (!date) {
            return '';
        }

        return moment(typeof date === 'string' ? new Date(date) : date)
            .local()
            .format('ddd, ll');
    };

    // Formats to '01/10/2023' (MM/DD/YYYY) for US or '10/01/2023' (DD/MM/YYYY) for EU.
    toShortDayMonthYear = (date: Date | string | null | undefined): string => {
        if (!date) {
            return '';
        }

        return moment(typeof date === 'string' ? new Date(date) : date).format('L');
    };

    // Formats to 4 Jan 2023.
    toLongDayMonthYear = (date: Date | string | null | undefined): string => {
        if (!date) {
            return '';
        }

        return moment(typeof date === 'string' ? new Date(date) : date).format('ll');
    };

    // Formats to 'Jan 2023'.
    toMonthYear = (date: Date | string | null | undefined): string => {
        if (!date) {
            return '';
        }

        return moment(typeof date === 'string' ? new Date(date) : date).format('MMM YYYY');
    };

    // Formats to '2023' from a utc date.
    toYearFromUtc = (date: Date | string | null | undefined): string => {
        if (!date) {
            return '';
        }

        return moment.utc(typeof date === 'string' ? new Date(date) : date).format('YYYY');
    };

    // Formats to 'Jan'. The number parameter starts at 1, Jan = 1, Feb = 2.
    toMonth = (month: number): string =>
        moment()
            .month(month - 1)
            .format('MMM');

    // Formats to 'Jan' from a utc date.
    toMonthFromUtc = (date: Date | string | number | null | undefined): string => {
        if (!date) {
            return '';
        }

        return moment.utc(typeof date === 'string' ? new Date(date) : date).format('MMM');
    };

    // Formats to '8 Jun'.
    toDayMonth = (date: Date | string | null | undefined): string => {
        if (!date) {
            return '';
        }

        return moment(typeof date === 'string' ? new Date(date) : date)
            .local()
            .format('DD MMM');
    };

    // TODO: This is temporary until the dashboard refactor.
    toMonthFromEnumeration = (month: MonthNamesEnum): string => moment().month(month).format('MMM');

    // Format to x minutes/days/months/years ago.
    toTimeAgo = (date: Date | string | null | undefined) => {
        if (!date) {
            return '';
        }

        return moment(typeof date === 'string' ? new Date(date) : date)
            .local()
            .fromNow();
    };

    // Formats to 10.00% or -- when empty.
    toPercent = (number: number | null | undefined, displayDecimalPlacesMinimum: number = 2, displayDecimalPlacesMaximum: number = 2): string => {
        if (!number) {
            return applicationConstants.TablePlaceholderZeroOrEmpty;
        }

        return `${number.toLocaleString(languageService.getLanguage(), {
            minimumFractionDigits: displayDecimalPlacesMinimum,
            maximumFractionDigits: displayDecimalPlacesMaximum,
        })}%`;
    };

    // Formats to 10.00% or 0.00% when empty.
    toPercentWithoutPlaceholder = (
        number: number | null | undefined,
        displayDecimalPlacesMinimum: number = 2,
        displayDecimalPlacesMaximum: number = 2,
    ): string => {
        const numberToFormat = !number ? 0 : number;

        return `${numberToFormat.toLocaleString(languageService.getLanguage(), {
            minimumFractionDigits: displayDecimalPlacesMinimum,
            maximumFractionDigits: displayDecimalPlacesMaximum,
        })}%`;
    };

    // Formats to a regular price that is rounded to 4 digits after the decimal and has trailing zeros truncated.
    toPriceString = (number: number | null | undefined): string => {
        if (!number) {
            return applicationConstants.TablePlaceholderZeroOrEmpty;
        }

        if (Math.abs(number) < 0.00001) {
            return '0';
        }

        const pricePrecision = applicationConstants.DefaultDisplayDecimalPlacesMaximum;
        const newNumber = number.toPrecision(pricePrecision).replace(new RegExp(`((\\d\\.*){${pricePrecision}}).*`), '$1');

        return `${+newNumber}`;
    };

    // Formats to a futures price that is rounded to 5 digits after the decimal and has trailing zeros truncated.
    toFuturesPriceString = (number: number): string => {
        const futurePrecision = 5;
        const newNumber = number.toPrecision(futurePrecision).replace(new RegExp(`((\\d\\.*){${futurePrecision}}).*`), '$1');

        return `${+newNumber}`;
    };

    // Formats to a currency price that is rounded to 5 digits after the decimal.
    toCurrencyPriceString = (number: number): string => {
        const currencyPrecision = 5;
        const newNumber = number.toFixed(currencyPrecision);

        return `${newNumber}`;
    };

    // Formats a number with trailing zeros - 1.5000 if displayDecimalPlacesMinimum = 4.
    toNumberStringWithTrailingZeros = (number: number, displayDecimalPlacesMinimum?: number, displayDecimalPlacesMaximum?: number): string => {
        const maximum =
            displayDecimalPlacesMaximum === undefined
                ? displayDecimalPlacesMinimum ?? applicationConstants.DefaultDisplayDecimalPlacesMaximum
                : displayDecimalPlacesMaximum;
        const formattedNumber = number ? +number.toFixed(maximum) : (0).toFixed(maximum);

        return formattedNumber.toLocaleString(languageService.getLanguage(), {
            minimumFractionDigits: displayDecimalPlacesMinimum,
            maximumFractionDigits: maximum,
        });
    };

    // Formats a number with trailing zeros - 1.5000 if displayDecimalPlacesMinimum = 4.
    // Only if the number is undefined or zero, will it have a "--" instead.
    toNumberStringWithTrailingZerosOrDash = (
        number: number | undefined,
        displayDecimalPlacesMinimum: number,
        displayDecimalPlacesMaximum: number | undefined = undefined,
    ): string => {
        if (!number) {
            return applicationConstants.TablePlaceholderZeroOrEmpty;
        }

        const maximum = displayDecimalPlacesMaximum === undefined ? displayDecimalPlacesMinimum : displayDecimalPlacesMaximum;
        const formattedNumber = +number.toFixed(maximum);

        return formattedNumber.toLocaleString(languageService.getLanguage(), {
            minimumFractionDigits: displayDecimalPlacesMinimum,
            maximumFractionDigits: maximum,
        });
    };

    getDisplayDecimalPlacesMinimum = (number: number | undefined): number => {
        if (number === undefined) {
            return 0;
        }

        const absoluteNumber = Math.abs(number);
        if (absoluteNumber >= 1000) {
            return 0;
        }

        if (absoluteNumber >= 100) {
            return 1;
        }

        if (absoluteNumber >= 10) {
            return 2;
        }

        if (absoluteNumber >= 1) {
            return 3;
        }

        if (absoluteNumber >= 0.1) {
            return 4;
        }

        return 5;
    };

    getDisplayDecimalPlacesMinimumForCharts = (number: number | undefined): number => {
        const decimalPlaces = this.getDisplayDecimalPlacesMinimum(number) - 1;

        return decimalPlaces < 0 ? 0 : decimalPlaces;
    };

    toCamelCase = (value: string): string => `${value.charAt(0).toLowerCase()}${value.slice(1)}`;

    toPascalCase = (value: string): string => `${value.charAt(0).toUpperCase()}${value.slice(1)}`;

    // Formats a futures symbol to a display name with the exchange + commodity display names.
    toDisplayName = (symbol?: DemeterSymbolModel): string => {
        if (!symbol) {
            return '';
        }
        if (symbol.subExchange) {
            return `${languageService.translate('subExchange', symbol.subExchange)} ${languageService.translate(symbol.displayName)}`;
        }

        return `${languageService.translate('exchange', symbol.exchange)} ${languageService.translate(symbol.displayName)}`;
    };

    getDataFrequencyShortDisplayName = (dataFrequency: DemeterDataFrequency): string => {
        switch (dataFrequency) {
            case DemeterDataFrequency.Daily:
                return 'D';
            case DemeterDataFrequency.Weekly:
                return 'W';
            case DemeterDataFrequency.Monthly:
                return 'M';
            case DemeterDataFrequency.Yearly:
                return 'Y';
            case DemeterDataFrequency.Streaming:
                return 'S';
            default:
                return '';
        }
    };

    // TODO: EVERYTHING BELOW HERE NEEDS TO BE RE-EVALUATED. DO NOT USE ANYTHING BELOW.

    toBrowserDateAndTimeFromUTC = (date: string): string => moment(new Date(`${date} UTC`)).format('YYYY-MM-DDTHH:mm:ss');

    // This will print out the display price, trimming all of the trailing zeros.
    toDisplayPrice = (value?: number): string => {
        if (value != null) {
            this.trimStringEnd(this.trimStringEnd(value.toFixed(8), '0'), '.');
        }

        return applicationConstants.TablePlaceholderZeroOrEmpty;
    };

    // Over 99,999 -> no decimal places
    toFormattedNumber = (number: number | string | null | undefined, decimalPlaces?: number): string | null => {
        if (!number) {
            return null;
        }

        let actualNumber: number = typeof number === 'number' ? number : 0;
        if (decimalPlaces !== undefined && typeof number === 'number') {
            actualNumber = +actualNumber.toFixed(decimalPlaces);
        }

        // Function recieves a string and it evaluates to be >= 100000
        if (typeof number === 'string' && parseFloat(number) >= 100000) {
            return parseInt(number, 10).toLocaleString(languageService.getLanguage());
        }

        // Function recieves a string
        if (typeof number === 'string') {
            return parseFloat(number).toLocaleString(languageService.getLanguage(), {
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces,
            });
        }

        // Function recieves number type and >= 100000
        if (number >= 100000) {
            return actualNumber.toLocaleString(languageService.getLanguage(), { maximumFractionDigits: 0 });
        }

        // Function recieves number type
        return actualNumber.toLocaleString(languageService.getLanguage(), {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });
    };

    toDollarFormattedNumber = (number: number | string | null | undefined, decimalPlaces?: number): string | null => {
        if (!number) {
            return null;
        }

        let actualNumber: number = typeof number === 'number' ? number : 0;
        if (decimalPlaces !== undefined && typeof number === 'number') {
            actualNumber = +actualNumber.toFixed(decimalPlaces);
        }
        if (typeof number === 'string') {
            return parseFloat(number).toLocaleString(languageService.getLanguage(), {
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces,
            });
        }

        return `$${actualNumber.toLocaleString(languageService.getLanguage(), {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        })}`;
    };

    toLongFormatDate = (date: Date): string => moment(date).format('DD MMMM, YYYY');

    toLongDateAndTimeFormat = (date: Date): string => moment(date).format('DD MMMM, YYYY, HH:mm');

    getDaysDifference = (dateString: string | null | undefined) => (dateString ? moment().diff(new Date(dateString), 'days') : dateString);

    // Linter needs posibility of returning undefined, even though it should be impossible for returned value to be undefined.
    toRegionalizedDate = (date: Date | string | null | undefined): null | string | undefined => {
        if (!date) {
            return null;
        }
        if (typeof date === 'string') {
            return date;
        }

        const returnedDateFormat = moment(date).format('L');
        return returnedDateFormat;
    };

    toMonthYearDateFormat = (date: Date | string | undefined): null | string | undefined => {
        if (!date) {
            return null;
        }
        if (typeof date === 'string') {
            return date;
        }
        const returnedDateFormat = moment(date).locale(languageService.getLanguage()).format('MM/YYYY');
        return returnedDateFormat;
    };

    getRegionalizedDateFormat = () => {
        // Converting these values for the date picker. Date picker needs to be updated to use moment.
        const longDateFormat = moment.localeData(languageService.getLanguage()).longDateFormat('L');
        return longDateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');
    };

    withParenthesisForNegatives = (number: any) => {
        if (number.value === 0 || number.value === '0' || number.value === '-') {
            return number.value;
        }

        if (number.value > 0) {
            return number.value;
        }
        return `(${Math.abs(number.value)})`;
    };

    // format tooltip dates
    formatDatesFromUTC = (timestamp: number | string, isProjectionChart?: boolean) => {
        const date = new Date(timestamp);
        if (typeof timestamp === 'number' && isProjectionChart) {
            return moment.utc(date).format('MMM YYYY');
        }
        if (typeof timestamp === 'number') {
            return moment.utc(date).format('ddd, MMM DD, HH:mm');
        }
        return timestamp;
    };

    // am using any here, because am not sure the type of 'this'
    getToolTip = (displayDecimalPlacesMinimum: number, displayDecimalPlacesMaximum: number, thisContext: ThisContext | any, isProjectionChart?: boolean) => {
        const { x, y, point, series }: ThisContext = thisContext;
        const monthOrMonthYear = this.formatDatesFromUTC(x, isProjectionChart);
        let decimalDataPoint;

        // index of 1 is always the percentage series on the projection chart
        if (isProjectionChart && thisContext?.series?.index === 1) {
            decimalDataPoint = formattingService.getDecimalDataWithAppropriateTrailingZeros(1, 1, y);
        } else if (displayDecimalPlacesMaximum !== displayDecimalPlacesMinimum) {
            decimalDataPoint = formattingService.getDecimalDataWithAppropriateTrailingZeros(displayDecimalPlacesMinimum, displayDecimalPlacesMaximum, y);
        } else {
            decimalDataPoint = formattingService.toFormattedNumber(y.toFixed(displayDecimalPlacesMaximum), displayDecimalPlacesMaximum);
        }
        const tooltip = `<b>${monthOrMonthYear}</b><br /><span style="color: ${point.color}">\u25CF</span> ${series.name}: <b>${decimalDataPoint}</b>`;
        return tooltip;
    };

    seperateWords = (unseperatedString: string) => unseperatedString.replace(/([A-Z])/g, ' $1').trim();

    // used when formatting the tooltip to add or take 0s after the '.'
    getDecimalDataWithAppropriateTrailingZeros = (tooltipDecimalMinimum: number, tooltipDecimalMaximum: number, value: number) => {
        let returnValue: string = value.toString();

        // get the number of places after the decimal
        const getDecimalCount = (number: number | string) => {
            const numberFromString = String(number);
            if (numberFromString.includes('.')) {
                return numberFromString.split('.')[1].length;
            }
            return 0;
        };
        const decimalCount = getDecimalCount(value);

        if (decimalCount < tooltipDecimalMinimum) {
            if (decimalCount === 0) {
                returnValue += '.';
            }
            // add 0s
            for (let i = decimalCount; i < tooltipDecimalMinimum; i += 1) {
                returnValue += '0';
            }
        } else if (decimalCount > tooltipDecimalMaximum) {
            returnValue = Number(returnValue).toFixed(tooltipDecimalMaximum);
        } else if (decimalCount > tooltipDecimalMinimum) {
            // determine if there are 0s to get rid of
            for (let i = decimalCount; i < tooltipDecimalMinimum; i += 1) {
                if (returnValue[i] === '0') {
                    returnValue.slice(0, -1);
                    // eslint-disable-next-line no-continue
                    continue;
                }
                break;
            }
        }

        return parseFloat(returnValue).toLocaleString(languageService.getLanguage(), {
            minimumFractionDigits: tooltipDecimalMinimum,
            maximumFractionDigits: tooltipDecimalMaximum,
        });
    };
}

const formattingService = new FormattingService();

export default formattingService;
