/* eslint-disable @typescript-eslint/indent */
import { useEffect, useMemo } from 'react';
import { demeterApi } from '../../../Apis/Apis';
import {
    Currency,
    ListMarketPricesRollingRangeResponse,
    ListMarketPricesWithConversionsResponse,
    MarketPricesTimeSpan,
    UnitOfMeasure,
} from '../../../Generated/Raven-Demeter/api';
import { TermClassification, ValueMatrixStripType, ValueMatrixUpdateTrigger } from '../../Pages/Calculators/ValueMatrixCalculator/ValueMatrixDefinitions';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useCacheThenApi from './useCacheThenApiHook';
import useMultipleApis from './useMultipleApisHook';

export type ListMarketPricesResponse = ListMarketPricesWithConversionsResponse | ListMarketPricesRollingRangeResponse;

const defaultContractStartNumber = 1;

const useMarketPricesRollingApi = (
    reutersInstrumentCodePrefix: string,
    timeSpan: MarketPricesTimeSpan,
    valueMatrixStripType?: ValueMatrixStripType,
    numberOfContracts?: number,
    termClassifications?: TermClassification[],
    updateTriggeredBy?: ValueMatrixUpdateTrigger,
    currency?: Currency,
    unitOfMeasure?: UnitOfMeasure,
): [boolean, () => void, ListMarketPricesResponse[] | ListMarketPricesResponse | undefined] => {
    const cacheKey = useMemo(() => {
        const cacheKeyPrefix = CacheKeys.ListMarketPricesRolling;
        const cacheKeySuffix = JSON.stringify({
            reutersInstrumentCodePrefix,
            valueMatrixStripType,
            yearsToLookBack: timeSpan,
            updateTriggeredBy,
            currency,
            unitOfMeasure,
        });

        return `${cacheKeyPrefix}_${cacheKeySuffix}`;
    }, [reutersInstrumentCodePrefix, valueMatrixStripType, timeSpan, updateTriggeredBy, currency, unitOfMeasure]);

    const [loadingMarketPricesRolling, refreshListMarketPricesRollingResponse, listMarketPricesRollingResponse] = useCacheThenApi<ListMarketPricesResponse>(
        cacheKey,
        () => {
            if (
                !valueMatrixStripType ||
                !reutersInstrumentCodePrefix ||
                valueMatrixStripType === 'forwardContract' ||
                valueMatrixStripType === 'spot' ||
                updateTriggeredBy === 'productChange'
            ) {
                return null;
            }

            if (valueMatrixStripType === 'twelveMonthStrip') {
                return demeterApi.listMarketPricesRollingRange(
                    reutersInstrumentCodePrefix!,
                    `${defaultContractStartNumber}`,
                    `${numberOfContracts}`,
                    timeSpan,
                    currency,
                    unitOfMeasure,
                );
            }

            return demeterApi.listMarketPricesRollingWithConversions(
                reutersInstrumentCodePrefix!,
                valueMatrixStripType === 'firstContractMonth' ? 1 : 2,
                timeSpan,
                currency,
                unitOfMeasure,
            );
        },
    );

    const [loadingMarketPricesForwardContract, refreshListMarketPricesForwardContractResponse, listMarketPricesForwardContractResponse] = useMultipleApis(
        () => {
            if (!valueMatrixStripType || !reutersInstrumentCodePrefix || valueMatrixStripType !== 'forwardContract') {
                return null;
            }

            return termClassifications?.map((x) =>
                demeterApi.listMarketPricesRollingYearWithConversions(
                    reutersInstrumentCodePrefix!,
                    timeSpan,
                    currency,
                    unitOfMeasure,
                    x.contractMonths[0],
                    x.contractMonths,
                ),
            );
        },
    );

    // Whenever we change term classifications or strip type, we need to get the updated data for the forwardContract.
    useEffect(() => {
        if (valueMatrixStripType !== 'forwardContract') {
            return;
        }

        refreshListMarketPricesForwardContractResponse();
    }, [termClassifications, valueMatrixStripType]);

    if (valueMatrixStripType === 'forwardContract') {
        return [loadingMarketPricesForwardContract, refreshListMarketPricesForwardContractResponse, listMarketPricesForwardContractResponse];
    }

    return [loadingMarketPricesRolling, refreshListMarketPricesRollingResponse, listMarketPricesRollingResponse];
};

export default useMarketPricesRollingApi;
