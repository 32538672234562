import React, { useEffect, useMemo, useState } from 'react';
import { MarketIndicatorFactorModel } from '../../../../../../../Generated/Raven-Demeter';
import plusSvg from '../../../../../../Assets/Icons/expand.svg';
import useSearchParameters from '../../../../../../Components/Navigation/Hooks/useSearchParametersHook';
import Tabs from '../../../../../../Components/Navigation/Tabs/Tabs';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { CombinationParameters, UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from './CombinationTabs.module.scss';

interface ICombinationTabsProps {
    marketIndicatorFactor: MarketIndicatorFactorModel;
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
    parentUpsertMarketIndicatorFactorRequest?: UpsertMarketIndicatorFactorRequest;
    setParentUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
    setTestChartsActive: (value?: boolean) => void;
}

const minimumNumberOfSubFactors = 2;
const maximumNumberOfTabOptions = 4;
const numberOfMultipliers = 12;

const CombinationTabs: React.FC<ICombinationTabsProps> = (props: ICombinationTabsProps) => {
    const [translations] = useLanguage();
    const [searchParameters] = useSearchParameters();
    const [numberOfSubFactors, setNumberOfSubFactors] = useState(minimumNumberOfSubFactors);

    const multipliers1OfParentOrInitialRequest = useMemo(
        () =>
            (
                (props.parentUpsertMarketIndicatorFactorRequest?.parameters as CombinationParameters) ??
                (props.upsertMarketIndicatorFactorRequest.parameters as CombinationParameters)
            )?.multipliers1,
        [
            props.parentUpsertMarketIndicatorFactorRequest?.parameters as CombinationParameters,
            props.upsertMarketIndicatorFactorRequest.parameters as CombinationParameters,
        ],
    );

    useEffect(() => {
        props.setTestChartsActive(false);
    }, [searchParameters.tab]);

    useEffect(() => {
        if (!multipliers1OfParentOrInitialRequest) {
            return;
        }

        const currentNumberOfSubFactors = Math.max(props.marketIndicatorFactor.subFactors.length, multipliers1OfParentOrInitialRequest?.split(',').length);

        setNumberOfSubFactors(currentNumberOfSubFactors);
    }, [props.marketIndicatorFactor, multipliers1OfParentOrInitialRequest]);

    const marketIndicatorFactorTabOptions = useMemo(() => {
        const options = [
            {
                title: translations.words.parent,
                key: 'parent',
            },
        ];

        for (let index = 0; index < numberOfSubFactors; index += 1) {
            options.push({
                title: props.marketIndicatorFactor?.subFactors[index] ? props.marketIndicatorFactor?.subFactors[index].displayName : `Child ${index + 1}`,
                key: props.marketIndicatorFactor?.subFactors[index]
                    ? props.marketIndicatorFactor?.subFactors[index].marketIndicatorFactorGuid
                    : `child${index + 1}`,
            });
        }

        return options;
    }, [props.marketIndicatorFactor?.subFactors, numberOfSubFactors]);

    const addColumnOfMultipliers = () => {
        const updatedMultipliers = props.upsertMarketIndicatorFactorRequest?.parameters! as CombinationParameters;

        for (let index = 0; index < numberOfMultipliers; index += 1) {
            updatedMultipliers[`multipliers${index + 1}` as keyof CombinationParameters] += ',1';
        }

        setNumberOfSubFactors((currentNumberOfSubFactors) => currentNumberOfSubFactors + 1);
        props.setUpsertMarketIndicatorFactorRequest({ ...props.upsertMarketIndicatorFactorRequest, parameters: updatedMultipliers });
        props.setParentUpsertMarketIndicatorFactorRequest({ ...props.upsertMarketIndicatorFactorRequest, parameters: updatedMultipliers });
    };

    return (
        <div className={styles.combination_tabs_flex}>
            <Tabs tabOptions={marketIndicatorFactorTabOptions} />
            {searchParameters.tab === 'parent' && marketIndicatorFactorTabOptions.length <= maximumNumberOfTabOptions && (
                <button onClick={addColumnOfMultipliers} className={styles.combination_tabs_plus_button} type="button">
                    <img src={plusSvg} alt="#" />
                </button>
            )}
        </div>
    );
};

export default CombinationTabs;
