/* eslint-disable no-confusing-arrow */
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { demeterApi, demeterCalculatorsApi, demeterOtherApi } from '../../../../Apis/Apis';
import scssVariables from '../../../../Config.module.scss';
import {
    DemeterCommodity,
    DemeterDataFrequency,
    DemeterFilterTimeSpan,
    DemeterMarket,
    DemeterRegion,
    MarketPriceModel,
    MarketPricesModel,
    MarketPricesTimeSpan,
} from '../../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectUserCurrentMarket } from '../../../../Redux/Slices/UserSlice';
import useApiWithoutAutoExecute from '../../../Apis/Hooks/useApiWithoutAutoExecute';
import useCacheOrApi from '../../../Apis/Hooks/useCacheOrApiHook';
import useMarketPricesRollingApi, { ListMarketPricesResponse } from '../../../Apis/Hooks/useMarketPricesRollingApiHook';
import useMultipleApis from '../../../Apis/Hooks/useMultipleApisHook';
import usePricesApi from '../../../Apis/Hooks/usePricesApiHook';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import checkSvg from '../../../Assets/Icons/checkCircle.svg';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import Switch from '../../../Components/Form/Buttons/Switch';
import DatePickerInput from '../../../Components/Form/Inputs/DatePickerInput';
import LabelWithTooltip from '../../../Components/Form/Inputs/LabelWithTooltip';
import TextInput from '../../../Components/Form/Inputs/TextInput';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import { IRegionCommoditySelection } from '../../../Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import RegexValidators from '../../../Core/Validation/RegexValidators';
import WebWorker from '../../../Core/WebWorker';
import CacheKeys from '../../../Services/Cache/CacheKeys';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './ValueMatrixCalculator.module.scss';
import ValueMatrixChart from './ValueMatrixChart';
import ValueMatrixChartControls from './ValueMatrixChartControls';
import {
    ChartDisplayVisability,
    DecileChartOption,
    DecileRowDefinitions,
    defaultNumberOfYearsToLookBackGeneral,
    TermClassificationOptions,
    ValueMatrixChartRequest,
    ValueMatrixChartResults,
    ValueMatrixStripType,
    ValueMatrixTableRequest,
    ValueMatrixTableResults,
} from './ValueMatrixDefinitions';
import ValueMatrixMarketDropdown from './ValueMatrixDropdowns/ValueMatrixMarketDropdown';
import ValueMatrixOuterRangeDropdown from './ValueMatrixDropdowns/ValueMatrixOuterRangeDropdown';
import ValueMatrixProductDropdown from './ValueMatrixDropdowns/ValueMatrixProductDropdown';
import ValueMatrixStripTypeDropdown from './ValueMatrixDropdowns/ValueMatrixStripTypeDropdown';
import ValueMatrixTermClassificationDropdown from './ValueMatrixDropdowns/ValueMatrixTermClassificationDropdown';
import ValueMatrixYearsToLookBackDropdown from './ValueMatrixDropdowns/ValueMatrixYearsToLookBackDropdown';
import ValueMatrixResultsTableRow from './ValueMatrixResultsTableRow';
import ValueMatrixStickyColumn from './ValueMatrixStickyColumn';
import valueMatrixWorker from './ValueMatrixWorker';

// Defaults.
const defaultInnerRange = 1;
const defaultInnerWeight = 20;
const defaultOuterWeight = 80;
const defaultDairyStripType = 'spot';
const defaultstripType = 'firstContractMonth';
const defaultApiYearsToLookBack = MarketPricesTimeSpan.TenYears;
const defaultApiYearsToLookBackSpot = DemeterFilterTimeSpan.TenYears;
const defaultDataFrequency = DemeterDataFrequency.Monthly;

// Default chart controls.
const defaultChartDeciles = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
const defaultChartStartDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
const defaultChartEndDate = new Date();
const defaultChartYears = [new Date().getFullYear()];

const ValueMatrixCalculator = () => {
    // Application hooks.
    const [translations] = useLanguage();
    const defaultMarket = useApplicationSelector(selectUserCurrentMarket);
    const symbols = useSymbolsApi();
    const valueMatrixTableWebworker = useRef(new WebWorker(valueMatrixWorker));
    const valueMatrixChartWebworker = useRef(new WebWorker(valueMatrixWorker));

    // Table hooks.
    const [valueMatrixTableInputs, setValueMatrixTableInputs] = useState<ValueMatrixTableRequest>();
    const [valueMatrixTableResults, setValueMatrixTableResults] = useState<ValueMatrixTableResults[]>([]);
    const [lastGeneratedTime, setLastGeneratedTime] = useState(new Date());
    const [showLoadedMessage, setShowLoadedMessage] = useState<boolean>();
    const [isTableWorkerCalculating, setIsTableWorkerCalculating] = useState<boolean>();
    const valueMatrixStripTypeIsNotTwelveMonthStrip = valueMatrixTableInputs?.stripType !== 'twelveMonthStrip';
    const valueMatrixStripTypeIsNotSpot = valueMatrixTableInputs?.stripType !== 'spot';

    // Chart hooks.
    const [valueMatrixChartInputs, setValueMatrixChartInputs] = useState<ValueMatrixChartRequest>({
        chartStartDate: defaultChartStartDate,
        chartEndDate: defaultChartEndDate,
        chartDeciles: defaultChartDeciles,
        chartTerms: defaultChartYears,
    });
    const [valueMatrixChartResults, setValueMatrixChartResults] = useState<ValueMatrixChartResults>();
    const [chartDisplayVisibility, setChartDisplayVisibility] = useState<ChartDisplayVisability>({
        chartControlsActive: false,
        chartIsVisible: false,
    });
    const [isChartWorkerCalculating, setIsChartWorkerCalculating] = useState(true);
    const [chartChangesDetected, setChartChangesDetected] = useState<boolean>(true);

    // Api hooks.
    const [marketPricesLoading, refreshMarketPricesResponse, marketPricesResponse] = useMarketPricesRollingApi(
        valueMatrixTableInputs?.product.reutersInstrumentCodePrefix!,
        defaultApiYearsToLookBack,
        valueMatrixTableInputs?.stripType,
        valueMatrixTableInputs?.product.contractMonths!.length,
        valueMatrixTableInputs?.termClassification,
        valueMatrixTableInputs?.updateTriggeredBy,
    );

    // TODO - multiple caches?
    const [, refreshListMarketPricesWithContractYear, listMarketPricesWithContractYearResponse] = useMultipleApis(
        () =>
            valueMatrixChartInputs.chartTerms.map((contractYear) =>
                demeterApi.listMarketPricesByContractYearWithConversions(
                    valueMatrixTableInputs?.product.reutersInstrumentCodePrefix!,
                    `${contractYear}`,
                    defaultApiYearsToLookBack,
                ),
            ),
        { stopAutoExecute: true },
    );

    // Energy products are showing null for a pricesRegion and pricesCommodity.
    const regionCommoditySelection: IRegionCommoditySelection = {
        region: valueMatrixTableInputs?.product?.pricesRegion ?? DemeterRegion.All,
        commodity: valueMatrixTableInputs?.product?.pricesCommodity ?? DemeterCommodity.All,
        subRegion: '',
        extraParameters: valueMatrixTableInputs?.product?.pricesDataSource ?? '',
        dataFrequency: defaultDataFrequency,
    };

    const pricesResponseRaw = usePricesApi(regionCommoditySelection, undefined, undefined, defaultApiYearsToLookBackSpot);
    const spotPricesResponse = useMemo(() => {
        if (!pricesResponseRaw?.rows) {
            return undefined;
        }

        return { ...pricesResponseRaw, rows: pricesResponseRaw.rows.filter((x) => !!x.isActualValue) };
    }, [pricesResponseRaw]);

    const [, , producerPricesIndexResponse] = useCacheOrApi(CacheKeys.ListProducerPriceIndex, () =>
        demeterOtherApi.listCommodityMonthlyOther(DemeterRegion.UnitedStates, DemeterCommodity.ProducerPriceIndex),
    );

    const [, updateValueMatrixCalculator] = useApiWithoutAutoExecute(
        () =>
            demeterCalculatorsApi.updateValueMatrixCalculator(valueMatrixTableInputs?.product.region!, valueMatrixTableInputs?.product.commodity!, {
                region: valueMatrixTableInputs!.product.region!,
                commodity: valueMatrixTableInputs!.product.commodity!,
                asOfDate: formattingService.toApiDate(valueMatrixTableInputs!.asOfDate!),
                applyProducerPriceIndex: valueMatrixTableInputs!.applyProducerPriceIndex,
                weighted: showWeightedFields!,
                numberOfYearsInner: valueMatrixTableInputs?.weighted?.innerRange,
                weightPercentInner: valueMatrixTableInputs?.weighted?.innerWeight,
                numberOfYearsOuter: valueMatrixTableInputs?.weighted?.outerRange,
                weightPercentOuter: valueMatrixTableInputs?.weighted?.outerWeight ?? valueMatrixTableInputs?.timeSpan,
                stripType: valueMatrixTableInputs?.stripType!,
                termClassifications: valueMatrixTableInputs?.termClassification.map((x) => x.contractGroupName) as string[],
                showChart: chartDisplayVisibility.chartControlsActive,
                chartStartDate: formattingService.toApiDate(valueMatrixChartInputs?.chartStartDate!),
                chartEndDate: formattingService.toApiDate(valueMatrixChartInputs?.chartEndDate!),
                chartDeciles: valueMatrixChartInputs.chartDeciles,
                chartTerms: valueMatrixChartInputs.chartTerms.map((x) => `${x}`),
            }),
        {
            successMessage: translations.calculators.valueMatrix.messages.successfulCalculatorSave,
            errorMessage: translations.calculators.valueMatrix.messages.failedCalculatorSave,
        },
    );

    const [, refreshGetValueMatrixCalculator, getValueMatrixCalculatorResponse] = useApiWithoutAutoExecute(() =>
        demeterCalculatorsApi.getValueMatrixCalculator(valueMatrixTableInputs?.product.region!, valueMatrixTableInputs?.product.commodity!),
    );

    // Display hooks.
    const [showWeightedFields, setShowWeightedFields] = useState<boolean>(false);

    // Dropdown options.
    const decileRowDefinitions: DecileRowDefinitions[] = useMemo(
        () => [
            {
                text: translations.words.mean,
                backgroundColor: scssVariables.deactivatedBlockColor,
            },
            {
                text: translations.words.median,
                backgroundColor: scssVariables.deactivatedBlockColor,
            },
            {
                text: `90% - ${translations.words.maximum}`,
                backgroundColor: scssVariables.ninetyToOneHundredDecile,
                textColor: scssVariables.plainWhite,
                fieldName: 'ninetyToOneHundredDecile',
            },
            {
                text: '80% - 90%',
                backgroundColor: scssVariables.ninetyToOneHundredDecile,
                textColor: scssVariables.plainWhite,
                fieldName: 'eightyToNinetyDecile',
            },
            {
                text: '70% - 80%',
                backgroundColor: scssVariables.seventyToEightyDecile,
                fieldName: 'seventyToEightyDecile',
            },
            {
                text: '60% - 70%',
                backgroundColor: scssVariables.sixtyToSeventyDecile,
                fieldName: 'sixtyToSeventyDecile',
            },
            {
                text: '50% - 60%',
                backgroundColor: scssVariables.fiftyToSixtyDecile,
                fieldName: 'fiftyToSixtyDecile',
            },
            {
                text: '40% - 50%',
                backgroundColor: scssVariables.fourtyToFiftyDecile,
                fieldName: 'fourtyToFiftyDecile',
            },
            {
                text: '30% - 40%',
                backgroundColor: scssVariables.thirtyToFourtyDecile,
                fieldName: 'thirtyToFourtyDecile',
            },
            {
                text: '20% - 30%',
                backgroundColor: scssVariables.twentyToThirtyDecile,
                fieldName: 'twentyToThirtyDecile',
            },
            {
                text: '10% - 20%',
                backgroundColor: scssVariables.zeroToTenDecile,
                textColor: scssVariables.plainWhite,
                fieldName: 'tenToTwentyDecile',
            },
            {
                text: `${translations.words.minimum} - 10%`,
                backgroundColor: scssVariables.zeroToTenDecile,
                textColor: scssVariables.plainWhite,
                fieldName: 'zeroToTenDecile',
            },
        ],
        [translations],
    );

    const decileChartOptions: DecileChartOption[] = useMemo(
        () => [
            {
                label: `90% - ${translations.words.maximum}`,
                value: 10,
            },
            {
                label: '80% - 90%',
                value: 9,
            },
            {
                label: '70% - 80%',
                value: 8,
            },
            {
                label: '60% - 70%',
                value: 7,
            },
            {
                label: '50% - 60%',
                value: 6,
            },
            {
                label: '40% - 50%',
                value: 5,
            },
            {
                label: '30% - 40%',
                value: 4,
            },
            {
                label: '20% - 30%',
                value: 3,
            },
            {
                label: '10% - 20%',
                value: 2,
            },
            {
                label: `${translations.words.minimum} - 10%`,
                value: 1,
            },
        ],
        [translations],
    );

    const termClassificationOptions = useMemo(() => {
        const options: TermClassificationOptions[] = [
            {
                label: translations.calculators.valueMatrix.text.annual,
                value: {
                    contractMonths: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    contractGroupName: 'annual',
                },
            },
        ];

        if (valueMatrixStripTypeIsNotTwelveMonthStrip && valueMatrixStripTypeIsNotSpot) {
            options.push(
                {
                    label: translations.calculators.valueMatrix.text.quarter1,
                    value: {
                        contractMonths: [1, 2, 3],
                        contractGroupName: 'quarter1',
                    },
                },
                {
                    label: translations.calculators.valueMatrix.text.quarter2,
                    value: {
                        contractMonths: [4, 5, 6],
                        contractGroupName: 'quarter2',
                    },
                },
                {
                    label: translations.calculators.valueMatrix.text.quarter3,
                    value: {
                        contractMonths: [7, 8, 9],
                        contractGroupName: 'quarter3',
                    },
                },
                {
                    label: translations.calculators.valueMatrix.text.quarter4,
                    value: {
                        contractMonths: [10, 11, 12],
                        contractGroupName: 'quarter4',
                    },
                },
            );
        }

        if (valueMatrixTableInputs?.market === DemeterMarket.Energy && valueMatrixStripTypeIsNotTwelveMonthStrip && valueMatrixStripTypeIsNotSpot) {
            options.push(
                {
                    label: translations.calculators.valueMatrix.text.summerMonths,
                    value: {
                        contractMonths: [4, 5, 6, 7, 8, 9, 10],
                        contractGroupName: 'summerMonths',
                    },
                },
                {
                    label: translations.calculators.valueMatrix.text.winterMonths,
                    value: {
                        contractMonths: [11, 12, 1, 2, 3],
                        contractGroupName: 'winterMonths',
                    },
                },
            );
        }

        return options;
    }, [translations, valueMatrixTableInputs?.product, valueMatrixTableInputs?.stripType]);

    // On page load, add event listeners for messages from the Worker.
    useEffect(() => {
        if (!defaultMarket || !symbols) {
            return () => null;
        }

        setTableDefaults();

        (valueMatrixTableWebworker.current as Worker).onmessage = (event: MessageEvent) => {
            setValueMatrixTableResults(event.data.decileRangeData);
            setIsTableWorkerCalculating(false);
        };

        (valueMatrixChartWebworker.current as Worker).onmessage = (event: MessageEvent) => {
            setValueMatrixChartResults(event.data);
            setIsChartWorkerCalculating(false);
        };

        return () => {
            (valueMatrixTableWebworker.current as Worker).terminate();
            (valueMatrixChartWebworker.current as Worker).terminate();
        };
    }, [defaultMarket, symbols]);

    // On first load, once we have the defaults. Also, detect changes for disabling the render chart button.
    useEffect(() => {
        setChartChangesDetected(true);

        if (!valueMatrixTableInputs || valueMatrixTableResults.length > 0) {
            return;
        }

        handleCalculateAndUpdateValueMatrixTable();
        refreshGetValueMatrixCalculator();
    }, [valueMatrixTableInputs, valueMatrixChartInputs]);

    // Whenever he have the new responses from the apis, update the table data.
    useEffect(() => {
        const valueMatrixTableCalculatorNotReady = !valueMatrixTableInputs?.product || isTableWorkerCalculating;
        const apisNotLoaded = (valueMatrixStripTypeIsNotSpot && !marketPricesResponse) || (valueMatrixTableInputs?.stripType === 'spot' && !spotPricesResponse);
        const triggeredByProductChange = valueMatrixTableInputs?.updateTriggeredBy === 'productChange';
        const savedCommodityNotLoaded = getValueMatrixCalculatorResponse?.valueMatrixCalculator?.commodity !== valueMatrixTableInputs?.product.commodity;

        // If we don't have the correct saved data element loaded in, we cancel this call. Whenever a product changes,
        // don't update. There will be an api call to trigger the update once later.
        if (apisNotLoaded || triggeredByProductChange || valueMatrixTableCalculatorNotReady || savedCommodityNotLoaded) {
            return;
        }

        // If we dont have marketPricesResponse data, reload the api.
        const marketPricesExists = marketPricesResponse && !Array.isArray(marketPricesResponse) && marketPricesResponse.rows![0];
        const contractMonthRequiresLoad =
            marketPricesExists &&
            (!(marketPricesResponse.rows![0] as MarketPricesModel).prices ||
                !(marketPricesResponse.rows![0] as MarketPricesModel).prices[0].reutersInstrumentCode?.startsWith(
                    valueMatrixTableInputs?.product.reutersInstrumentCodePrefix,
                ));

        const twelveMonthStripModelRequiresLoad =
            marketPricesExists &&
            !(marketPricesResponse.rows![0] as MarketPriceModel).reutersInstrumentCode?.startsWith(valueMatrixTableInputs?.product.reutersInstrumentCodePrefix);

        if (valueMatrixStripTypeIsNotSpot && contractMonthRequiresLoad && twelveMonthStripModelRequiresLoad) {
            refreshMarketPricesResponse();
            return;
        }

        setIsTableWorkerCalculating(true);
        handleCalculateAndUpdateValueMatrixTable();
    }, [marketPricesResponse, spotPricesResponse, valueMatrixTableInputs]);

    // We need to trigger the chart update with a useEffect only if we do not have our first set of chart results.
    useEffect(() => {
        if (!listMarketPricesWithContractYearResponse || valueMatrixChartResults || !chartDisplayVisibility.chartControlsActive) {
            return;
        }

        handleValueMatrixChartUpdate();
    }, [listMarketPricesWithContractYearResponse]);

    // Refresh market prices api when inputs change.
    useEffect(() => {
        if (!valueMatrixTableInputs?.product) {
            return;
        }

        refreshListMarketPricesWithContractYear();
    }, [valueMatrixTableInputs?.product, valueMatrixChartInputs.chartTerms]);

    // When we change products and get a new response, update the defaults for that product.
    useEffect(() => {
        if (!symbols || !getValueMatrixCalculatorResponse || valueMatrixTableInputs?.updateTriggeredBy === 'stripTypeSelection') {
            return;
        }

        if (!getValueMatrixCalculatorResponse?.valueMatrixCalculator?.modifiedAt) {
            setTableDefaults();
            setChartDefaults();
            return;
        }

        handleValueMatrixReset();
    }, [getValueMatrixCalculatorResponse, symbols, termClassificationOptions]);

    useEffect(() => {
        setLastGeneratedTime(new Date());

        if (valueMatrixTableInputs?.updateTriggeredBy === 'apiReset' || valueMatrixTableInputs?.updateTriggeredBy === 'textInputDefault') {
            return;
        }

        setShowLoadedMessage(false);
    }, [valueMatrixTableResults, valueMatrixTableInputs?.updateTriggeredBy]);

    const setTableDefaults = () => {
        const defaultSymbol = symbols
            ?.filter((x) => x.symbolCategory === defaultMarket)
            .sort((a, b) => `${a.exchange}-${a.displayName}`.localeCompare(`${b.exchange}-${b.displayName}`))
            .find((x) => x.symbolCategory === defaultMarket)!;

        setShowWeightedFields(false);
        setValueMatrixTableInputs({
            ...valueMatrixTableInputs,
            asOfDate: moment().toDate(),
            market: valueMatrixTableInputs?.market ?? defaultMarket,
            product: valueMatrixTableInputs?.product ?? defaultSymbol,
            stripType: valueMatrixTableInputs?.market === DemeterMarket.Dairy ? defaultDairyStripType : defaultstripType,
            termClassification: [termClassificationOptions[0].value],
            timeSpan: defaultNumberOfYearsToLookBackGeneral,
            applyProducerPriceIndex: false,
            weighted: {},
            updateTriggeredBy: 'apiReset',
        });
    };

    const setChartDefaults = () => {
        setValueMatrixChartInputs({
            chartStartDate: defaultChartStartDate,
            chartEndDate: defaultChartEndDate,
            chartDeciles: defaultChartDeciles,
            chartTerms: defaultChartYears,
        });
    };

    const handleCalculateAndUpdateValueMatrixTable = () => {
        const spotNotLoaded = valueMatrixTableInputs?.stripType === 'spot' && !spotPricesResponse;
        const marketPricesNotLoaded = !marketPricesResponse && valueMatrixStripTypeIsNotSpot;
        const producerPriceIndexNotLoaded = valueMatrixTableInputs?.applyProducerPriceIndex && !producerPricesIndexResponse;
        const apisNotLoaded = spotNotLoaded || !getValueMatrixCalculatorResponse || marketPricesNotLoaded || producerPriceIndexNotLoaded;

        if (!valueMatrixTableInputs || apisNotLoaded) {
            return;
        }

        let marketPricesForWorker = valueMatrixStripTypeIsNotSpot ? [marketPricesResponse] : [spotPricesResponse];

        if (valueMatrixTableInputs?.stripType === 'forwardContract') {
            marketPricesForWorker = marketPricesResponse as ListMarketPricesResponse[];
        }

        (valueMatrixTableWebworker.current as Worker).postMessage({
            messageType: 'updateTableResults',
            valueMatrixTableInputs,
            valueMatrixChartInputs,
            producerPricesIndexResponse,
            termClassificationOptions,
            decileChartOptions,
            marketPricesResponse: marketPricesForWorker,
            decileRowDefinitions,
        });
    };

    const handleValueMatrixChartUpdate = () => {
        if ((!marketPricesResponse && !spotPricesResponse) || !listMarketPricesWithContractYearResponse || !valueMatrixTableInputs) {
            return;
        }

        const availableDatesArray: Date[] = [];
        const { chartStartDate, chartEndDate } = valueMatrixChartInputs;
        const currentAvailableDate = new Date(chartStartDate!);

        availableDatesArray.push(chartStartDate!);

        while (currentAvailableDate!.getTime() < chartEndDate!.getTime()) {
            const newAvailableDate = new Date(currentAvailableDate?.setMonth(currentAvailableDate.getMonth() + 1));
            availableDatesArray.push(newAvailableDate);
        }

        let marketPricesForWorker = valueMatrixStripTypeIsNotSpot ? [marketPricesResponse] : [spotPricesResponse];

        if (valueMatrixTableInputs?.stripType === 'forwardContract') {
            marketPricesForWorker = marketPricesResponse as ListMarketPricesResponse[];
        }

        (valueMatrixChartWebworker.current as Worker).postMessage({
            messageType: 'updateChartResults',
            valueMatrixTableInputs,
            valueMatrixChartInputs,
            producerPricesIndexResponse,
            termClassificationOptions,
            availableDatesArray,
            decileChartOptions,
            marketPricesResponse: marketPricesForWorker,
            listMarketPricesWithContractYearResponse,
        });
    };

    const handleValueMatrixReset = () => {
        const {
            stripType,
            termClassifications,
            applyProducerPriceIndex,
            weighted,
            numberOfYearsOuter,
            weightPercentInner,
            weightPercentOuter,
            numberOfYearsInner,
            showChart,
            chartDeciles,
            chartTerms,
        } = getValueMatrixCalculatorResponse!.valueMatrixCalculator!;

        // Update table and chart inputs. We always reset the dates.
        setValueMatrixTableInputs({
            ...valueMatrixTableInputs!,
            asOfDate: moment().toDate(),
            stripType: stripType as ValueMatrixStripType,
            termClassification: termClassificationOptions.filter((x) => termClassifications.find((y) => y === x.value.contractGroupName!)).map((x) => x.value),
            timeSpan: numberOfYearsOuter ?? valueMatrixTableInputs!.timeSpan ?? defaultNumberOfYearsToLookBackGeneral,
            applyProducerPriceIndex,
            weighted: weighted
                ? {
                      ...valueMatrixTableInputs!.weighted,
                      innerRange: numberOfYearsInner!,
                      innerWeight: weightPercentInner!,
                      outerRange: numberOfYearsOuter!,
                      outerWeight: weightPercentOuter!,
                  }
                : {},
            updateTriggeredBy: 'apiReset',
        });

        setValueMatrixChartInputs({
            ...valueMatrixChartInputs,
            chartStartDate: new Date(valueMatrixChartInputs.chartStartDate!),
            chartEndDate: new Date(valueMatrixChartInputs.chartEndDate!),
            chartDeciles: chartDeciles ?? defaultChartDeciles,
            chartTerms: chartTerms ? chartTerms?.map((x) => +x) : valueMatrixChartInputs.chartTerms,
        });

        // Set switches to display properly.
        setShowWeightedFields(weighted);
        setChartDisplayVisibility({ chartIsVisible: false, chartControlsActive: showChart });
    };

    const updateValueMatrixChart = () => {
        setIsChartWorkerCalculating(true);
        handleValueMatrixChartUpdate();
    };

    const titleString = useMemo(() => formattingService.toDisplayName(valueMatrixTableInputs?.product), [valueMatrixTableResults]);

    const isLoading = !valueMatrixTableInputs || valueMatrixTableResults?.length === 0;

    const pricesLoading = (!spotPricesResponse && valueMatrixTableInputs?.stripType === 'spot') || (marketPricesLoading && valueMatrixStripTypeIsNotSpot);

    return isLoading ? (
        <PageLoadingSpinner />
    ) : (
        <>
            <div className={styles.value_matrix_title_row}>
                <ComponentHeader title={translations.calculators.text.valueMatrix} />
                <div className={styles.value_matrix_chart_button_set}>
                    {getValueMatrixCalculatorResponse?.valueMatrixCalculator?.modifiedAt && (
                        <p>
                            {`${translations.words.saved} ${formattingService.toLongDateAndTimeFormat(
                                new Date(getValueMatrixCalculatorResponse?.valueMatrixCalculator?.modifiedAt),
                            )}`}
                        </p>
                    )}

                    <LinkButton
                        disabled={!getValueMatrixCalculatorResponse?.valueMatrixCalculator?.modifiedAt}
                        title={translations.actions.loadLastSave}
                        type={LinkButtonType.White}
                        onClick={() => {
                            handleValueMatrixReset();
                            setShowLoadedMessage(true);
                        }}
                    />
                    <LinkButton
                        title={translations.actions.save}
                        type={LinkButtonType.White}
                        onClick={() => {
                            updateValueMatrixCalculator();
                        }}
                    />
                </div>
            </div>
            <div className={styles.value_matrix_wrapper}>
                <div className={styles.value_matrix_inputs}>
                    {showLoadedMessage && (
                        <div className={styles.value_matrix_saved_version_loaded}>
                            <img className={styles.registration_self_list_checkmark} src={checkSvg} alt="#" />
                            {translations.calculators.valueMatrix.text.savedVersionLoaded}
                        </div>
                    )}

                    <div className={styles.value_matrix_section_header}>{translations.words.table}</div>

                    <div className={styles.value_matrix_inputs_row}>
                        <ValueMatrixMarketDropdown valueMatrixInputs={valueMatrixTableInputs} setValueMatrixInputs={setValueMatrixTableInputs} />
                    </div>
                    <div className={styles.value_matrix_inputs_row}>
                        <ValueMatrixProductDropdown
                            refreshGetValueMatrixCalculator={refreshGetValueMatrixCalculator}
                            valueMatrixInputs={valueMatrixTableInputs}
                            setValueMatrixInputs={setValueMatrixTableInputs}
                        />
                    </div>
                    <div className={styles.value_matrix_inputs_row}>
                        <div className={styles.value_matrix_inputs_datepicker}>
                            <DatePickerInput
                                testId="ValueMatrixDatePicker"
                                title={translations.text.asOfDate}
                                required
                                value={valueMatrixTableInputs?.asOfDate}
                                handleDateChange={(value) => {
                                    setValueMatrixTableInputs({
                                        ...valueMatrixTableInputs,
                                        asOfDate: value,
                                        updateTriggeredBy: value?.getTime()! >= new Date().setHours(0, 0, 0, 0) ? 'textInputDefault' : 'textInputUpdated',
                                    });
                                }}
                                oldestAllowedDate={moment().subtract(defaultNumberOfYearsToLookBackGeneral, 'years').toDate()}
                                mostRecentAllowedDate={new Date()}
                            />
                        </div>
                        <div>
                            <div className={styles.value_matrix_apply_producer_price_index}>
                                <Switch
                                    checked={valueMatrixTableInputs.applyProducerPriceIndex}
                                    handleChange={() => {
                                        if (valueMatrixTableInputs.applyProducerPriceIndex) {
                                            setValueMatrixTableInputs({
                                                ...valueMatrixTableInputs,
                                                applyProducerPriceIndex: false,
                                                updateTriggeredBy: 'generalFieldSelection',
                                            });
                                        } else {
                                            setValueMatrixTableInputs({
                                                ...valueMatrixTableInputs,
                                                applyProducerPriceIndex: true,
                                                updateTriggeredBy: 'generalFieldSelection',
                                            });
                                        }
                                    }}
                                />
                                <LabelWithTooltip
                                    title={translations.calculators.valueMatrix.fields.applyProducerPriceIndex}
                                    tooltip={translations.calculators.valueMatrix.text.producerPriceIndexTooltipInformation}
                                />
                            </div>
                            <div className={styles.value_matrix_apply_producer_price_index}>
                                <Switch
                                    checked={showWeightedFields}
                                    handleChange={() => {
                                        const defaultNumberOfYears = defaultNumberOfYearsToLookBackGeneral;

                                        if (showWeightedFields) {
                                            setValueMatrixTableInputs({
                                                ...valueMatrixTableInputs,
                                                timeSpan: defaultNumberOfYears,
                                                weighted: {},
                                                updateTriggeredBy: 'generalFieldSelection',
                                            });
                                            setShowWeightedFields(false);
                                        } else {
                                            setValueMatrixTableInputs({
                                                ...valueMatrixTableInputs,
                                                timeSpan: null,
                                                weighted: {
                                                    ...valueMatrixTableInputs.weighted,
                                                    innerRange: defaultInnerRange,
                                                    innerWeight: defaultInnerWeight,
                                                    outerRange: defaultNumberOfYears,
                                                    outerWeight: defaultOuterWeight,
                                                },
                                                updateTriggeredBy: 'generalFieldSelection',
                                            });
                                            setShowWeightedFields(true);
                                        }
                                    }}
                                />
                                {translations.calculators.valueMatrix.fields.weighted}
                            </div>
                        </div>
                    </div>

                    {!showWeightedFields ? (
                        <div className={styles.value_matrix_inputs_row}>
                            <ValueMatrixYearsToLookBackDropdown valueMatrixInputs={valueMatrixTableInputs} setValueMatrixInputs={setValueMatrixTableInputs} />
                        </div>
                    ) : (
                        <>
                            <div className={styles.value_matrix_inputs_row}>
                                <div className={styles.value_matrix_half_first_input}>
                                    <TextInput
                                        title={
                                            <LabelWithTooltip
                                                title={translations.calculators.valueMatrix.fields.innerRange}
                                                tooltip={translations.calculators.valueMatrix.text.innerRangeTooltipInformation}
                                            />
                                        }
                                        type="number"
                                        required
                                        value={`${valueMatrixTableInputs?.weighted?.innerRange}`}
                                        handleTextChange={(value) => {
                                            const loadedInnerYears =
                                                getValueMatrixCalculatorResponse?.valueMatrixCalculator?.numberOfYearsInner ?? defaultInnerRange;

                                            setValueMatrixTableInputs({
                                                ...valueMatrixTableInputs,
                                                weighted: {
                                                    ...valueMatrixTableInputs.weighted,
                                                    innerRange: +value,
                                                },
                                                updateTriggeredBy: +value === loadedInnerYears ? 'textInputDefault' : 'textInputUpdated',
                                            });
                                        }}
                                        validation={RegexValidators.PositiveNumber}
                                        errorMessage={translations.marketIndicatorsManagement.messages.positiveNumber}
                                    />
                                </div>

                                <TextInput
                                    title={translations.calculators.valueMatrix.fields.innerWeight}
                                    type="number"
                                    required
                                    value={`${valueMatrixTableInputs.weighted?.innerWeight}`}
                                    handleTextChange={(value) => {
                                        if (+value > 100 || +value < 0) {
                                            return;
                                        }

                                        const loadedInnerWeight =
                                            getValueMatrixCalculatorResponse?.valueMatrixCalculator?.weightPercentInner ?? defaultInnerWeight;

                                        setValueMatrixTableInputs({
                                            ...valueMatrixTableInputs,
                                            weighted: {
                                                ...valueMatrixTableInputs.weighted,
                                                innerWeight: +value,
                                                outerWeight: 100 - +value,
                                            },
                                            updateTriggeredBy: +value === loadedInnerWeight ? 'textInputDefault' : 'textInputUpdated',
                                        });
                                    }}
                                    validation={RegexValidators.PositiveNumber}
                                    errorMessage={translations.marketIndicatorsManagement.messages.positiveNumber}
                                />
                            </div>
                            <div className={styles.value_matrix_inputs_row}>
                                <div className={styles.value_matrix_half_first_input}>
                                    <ValueMatrixOuterRangeDropdown
                                        valueMatrixInputs={valueMatrixTableInputs}
                                        setValueMatrixInputs={setValueMatrixTableInputs}
                                    />
                                </div>
                                <TextInput
                                    title={translations.calculators.valueMatrix.fields.outerWeight}
                                    type="number"
                                    required
                                    value={`${valueMatrixTableInputs.weighted?.outerWeight}`}
                                    handleTextChange={(value) => {
                                        if (+value > 100 || +value < 0) {
                                            return;
                                        }

                                        const loadedOuterWeight =
                                            getValueMatrixCalculatorResponse?.valueMatrixCalculator?.weightPercentOuter ?? defaultOuterWeight;

                                        setValueMatrixTableInputs({
                                            ...valueMatrixTableInputs,
                                            weighted: {
                                                ...valueMatrixTableInputs.weighted,
                                                outerWeight: +value,
                                                innerWeight: 100 - +value,
                                            },
                                            updateTriggeredBy: +value === loadedOuterWeight ? 'textInputDefault' : 'textInputUpdated',
                                        });
                                    }}
                                    validation={RegexValidators.PositiveNumber}
                                    errorMessage={translations.marketIndicatorsManagement.messages.positiveNumber}
                                />
                            </div>
                        </>
                    )}

                    <div className={styles.value_matrix_inputs_row}>
                        <ValueMatrixStripTypeDropdown valueMatrixInputs={valueMatrixTableInputs} setValueMatrixInputs={setValueMatrixTableInputs} />
                    </div>
                    <p className={styles.value_matrix_checkbox_dropdown_label}>{translations.calculators.valueMatrix.fields.termClassification}</p>
                    <div className={styles.value_matrix_inputs_row}>
                        <ValueMatrixTermClassificationDropdown
                            valueMatrixInputs={valueMatrixTableInputs}
                            setValueMatrixInputs={setValueMatrixTableInputs}
                            termClassificationOptions={termClassificationOptions}
                        />
                    </div>

                    <div className={styles.value_matrix_section_header}>{translations.words.chart}</div>

                    <div className={styles.value_matrix_note}>{translations.calculators.valueMatrix.text.chartSettingsMessage}</div>

                    <ValueMatrixChartControls
                        valueMatrixTableInputs={valueMatrixTableInputs}
                        valueMatrixChartRequest={valueMatrixChartInputs}
                        setValueMatrixChartInputs={setValueMatrixChartInputs}
                        decileChartOptions={decileChartOptions}
                        chartDisplayVisibility={chartDisplayVisibility}
                        setChartDisplayVisibility={setChartDisplayVisibility}
                    />
                    {chartDisplayVisibility.chartControlsActive && (
                        <div className={styles.value_matrix_go_button}>
                            {pricesLoading && <LoadingSpinner />}

                            <LinkButton
                                title={translations.actions.renderChart}
                                type={LinkButtonType.Blue}
                                onClick={() => {
                                    if (chartDisplayVisibility.chartControlsActive) {
                                        setChartDisplayVisibility({ ...chartDisplayVisibility, chartIsVisible: true });
                                    }

                                    updateValueMatrixChart();
                                    setChartChangesDetected(false);
                                }}
                                disabled={pricesLoading || !chartChangesDetected}
                            />
                        </div>
                    )}
                </div>

                <div className={styles.value_matrix_results}>
                    <ComponentHeader title={titleString} />
                    <div className={styles.value_matrix_primary_header}>
                        <div className={styles.value_matrix_results_table_header_term_classifications_row}>
                            {valueMatrixTableInputs.product.currency &&
                                valueMatrixTableResults?.map((x) => (
                                    <div key={x.title} className={styles.value_matrix_results_header_cell}>
                                        {`${x?.title} (${translations.currency[valueMatrixTableInputs.product.currency]})`}
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className={styles.value_matrix_results_table}>
                        <ValueMatrixStickyColumn decileRowDefinitions={decileRowDefinitions} />
                        <div className={styles.value_matrix_results_scrollable}>
                            <div className={styles.value_matrix_results_table_header_row}>
                                {valueMatrixTableResults?.map((x) => (
                                    <div key={x.title} className={styles.value_matrix_results_header_cell}>
                                        {formattingService.toNumberStringWithTrailingZeros(+x.meanPrice)}
                                    </div>
                                ))}
                            </div>
                            <div className={styles.value_matrix_results_table_header_row}>
                                {valueMatrixTableResults?.map((x) => (
                                    <div key={x.title} className={styles.value_matrix_results_header_cell}>
                                        {formattingService.toNumberStringWithTrailingZeros(+x.medianPrice)}
                                    </div>
                                ))}
                            </div>

                            {decileRowDefinitions
                                .filter((x) => x.fieldName)
                                .map((x) => (
                                    <ValueMatrixResultsTableRow key={x.fieldName} valueMatrixResults={valueMatrixTableResults} fieldName={x.fieldName!} />
                                ))}
                        </div>
                    </div>
                    <p className={styles.value_matrix_generated_text}>
                        {`${translations.words.generated} ${formattingService.toLongDateAndTimeFormat(lastGeneratedTime)}`}
                    </p>
                </div>
            </div>
            {chartDisplayVisibility?.chartIsVisible && (
                <div className={styles.value_matrix_chart_section}>
                    <ValueMatrixChart
                        title={`${formattingService.toDisplayName(valueMatrixChartResults?.product)} - ${
                            termClassificationOptions.find((x) => x.value.contractGroupName === valueMatrixTableInputs.termClassification[0].contractGroupName)
                                ?.label ?? ''
                        }`}
                        valueMatrixTableRequest={valueMatrixTableInputs}
                        valueMatrixChartRequest={valueMatrixChartInputs}
                        valueMatrixChartResults={valueMatrixChartResults!}
                        isCalculating={isChartWorkerCalculating}
                    />
                </div>
            )}
        </>
    );
};

export default ValueMatrixCalculator;
