import { Currency, DemeterDataFrequency, UnitOfMeasure } from '../../../Generated/Raven-Demeter';
import formattingService from '../../Services/Formatting/FormattingService';
import languageService from '../../Services/Language/LanguageService';
import { translationKeys } from '../../Services/Language/TranslationDefinitions';
import {
    areaRangeChartType,
    ChartContext,
    ChartOptionsDefinitions,
    defaultForecastLineWidth,
    defaultZoneAxis,
    HighchartsPlot,
    IChartAreaRangeData,
    IChartAreaRangeDataSeries,
    IChartBarDataSeries,
    IChartData,
    IChartDataSeries,
    IChartSeasonalDataSeries,
    IDownloadData,
    lineChartType,
    shortDashStyle,
} from './ChartDefinitions';
import { StudyToSeriesMap as studyToSeriesMap } from './Studies/StudiesDefinitions';

// Translations.
const forecastText = 'words.forecast';
const highText = 'words.high';
const lowText = 'words.low';

type TooltipOptions = {
    nameOverride?: string;
    format?: string;
    dataFrequency?: DemeterDataFrequency;
    displayDecimalPlacesMinimum?: number;
    displayDecimalPlacesMaximum?: number;
};

/* eslint-disable class-methods-use-this */
class ChartService {
    // Defines base for a normal data series.
    getDataSeriesBase(series: IChartDataSeries | IChartAreaRangeDataSeries, type: string, color: string) {
        const baseSeries = {
            name: series.label,
            type,
            data: [] as HighchartsPlot[] | number[],
            color,
            visible: true,
            showInLegend: true,
            marker: {},
        };

        if (type === lineChartType) {
            baseSeries.marker = { enabled: false };
        }

        if (type === areaRangeChartType) {
            baseSeries.marker = { enabled: false };
        }

        return baseSeries;
    }

    // Defines forecast base for a normal data series.
    getForecastDataSeriesBase(series: IChartDataSeries, color: string, handleForecastClicked: (chartContext: ChartContext) => void) {
        return {
            name: series.forecastLabel ?? `${series.label} ${languageService.translate(forecastText)}`,
            yAxis: 0,
            marker: {
                enabled: false,
            },
            events: {
                legendItemClick() {
                    handleForecastClicked(this as unknown as ChartContext);
                },
            },
            zoneAxis: defaultZoneAxis,
            color,
            type: lineChartType,
            dashStyle: shortDashStyle,
            lineWidth: defaultForecastLineWidth,
            showInLegend: true,
        };
    }

    // Returns a string 'currency / unitOfMeasure' for a chart axis.
    getCurrencyAndUnitOfMeasureText(unitOfMeasure?: UnitOfMeasure, currency?: Currency) {
        if (currency && unitOfMeasure) {
            return `${currency} / ${languageService.translate(`unitOfMeasure.Short${unitOfMeasure}`)}`;
        }

        if (unitOfMeasure) {
            return `${languageService.translate(`unitOfMeasure.Short${unitOfMeasure}`)}`;
        }

        if (currency) {
            return currency;
        }

        return '';
    }

    // General tooltip formatted text for most chart types.
    getTooltipText(context: ChartContext, tooltipOptions?: TooltipOptions) {
        const { dataFrequency, format, nameOverride, displayDecimalPlacesMinimum, displayDecimalPlacesMaximum } = tooltipOptions ?? {};
        const { x, y, point, series } = context as unknown as ChartContext;
        let { name } = series;

        const focusDate = point.asOfDate ?? new Date(x);
        const isStudy = Object.values(studyToSeriesMap).some((baseStudy) => baseStudy.some((study) => study.type === series?.initialType));
        const year = formattingService.toYearFromUtc(focusDate);
        const monthYear =
            dataFrequency === DemeterDataFrequency.Daily || dataFrequency === DemeterDataFrequency.Weekly
                ? formattingService.toDayOfWeekWithDayMonthYear(focusDate)
                : formattingService.toMonthYear(focusDate);
        let formattedDate = dataFrequency === DemeterDataFrequency.Yearly ? year : monthYear;
        let formattedValue = formattingService.toNumberStringWithTrailingZeros(y, displayDecimalPlacesMinimum, displayDecimalPlacesMaximum);

        if (format === 'percent') {
            formattedValue = `${formattedValue}%`;
        }

        if (nameOverride && !point.zone?.value && !isStudy) {
            name = nameOverride;
        }

        if (context.point.lineDataType === 'range' || context.point.lineDataType === 'average') {
            formattedDate = formattingService.toMonth(focusDate.getMonth());
        }

        if (context.point.lineDataType === 'range') {
            formattedValue = `${formattingService.toNumberStringWithTrailingZeros(
                point.low ?? 0,
                displayDecimalPlacesMinimum,
                displayDecimalPlacesMaximum,
            )} - ${formattingService.toNumberStringWithTrailingZeros(point.high ?? 0, displayDecimalPlacesMinimum, displayDecimalPlacesMaximum)}`;
        }

        return `<b>${formattedDate}</b><br /><span style="color: ${point.color}">\u25CF</span> ${name} : <b>${formattedValue}</b>`;
    }

    // Get a tooltip text with high point and low point.
    getAreaRangeTooltipText(context: ChartContext) {
        const { x, point, series } = context;

        const monthYear = formattingService.toMonthYear(new Date(x));
        const formattedHighValue = formattingService.toNumberStringWithTrailingZeros(point.high ?? 0.0);
        const formattedLowValue = formattingService.toNumberStringWithTrailingZeros(point.low ?? 0.0);

        return `<b>${monthYear}</b><br /><span style="color: ${point.color}">\u25CF</span> ${series.name}<br/>
        ${languageService.translate(highText)}: <b>${formattedHighValue}</b><br/>
        ${languageService.translate(lowText)}: <b>${formattedLowValue}</b>`;
    }

    // Returns a singular download item in the proper format for a csv download.
    getDownloadItem(
        series: IChartBarDataSeries | IChartDataSeries,
        dataFrequency?: DemeterDataFrequency,
        onlyActualValues?: boolean,
        useForwardCurve?: boolean,
        displayDecimalPlacesMinimum?: number,
        displayDecimalPlacesMaximum?: number,
        rawDateFormatter?: (date: Date) => string,
    ) {
        const barChartSeries = series as IChartBarDataSeries;
        const dataSeries = useForwardCurve ? series.forwardCurveData : series.data;
        const downloadDataItem = dataSeries?.map((dataItem) => {
            let date = formattingService.toMonthYear(dataItem.asOfDate);

            if (dataFrequency === DemeterDataFrequency.Yearly) {
                date = `${dataItem.asOfDate.getFullYear()}`;
            }

            if (dataFrequency === DemeterDataFrequency.Weekly || dataFrequency === DemeterDataFrequency.Daily) {
                date = formattingService.toShortDayMonthYear(dataItem.asOfDate);
            }

            if (rawDateFormatter) {
                date = rawDateFormatter(dataItem.asOfDate);
            }

            return {
                value: formattingService.toNumberStringWithTrailingZeros(
                    dataItem.value,
                    barChartSeries.displayDecimalPlacesMinimum ??
                        displayDecimalPlacesMinimum ??
                        formattingService.getDisplayDecimalPlacesMinimum(dataItem.value),
                    barChartSeries.displayDecimalPlacesMaximum ??
                        displayDecimalPlacesMaximum ??
                        formattingService.getDisplayDecimalPlacesMinimum(dataItem.value),
                ),
                date,
                rawDate: formattingService.toApiDate(dataItem.asOfDate, true),
                isActualValue: onlyActualValues ? true : dataItem.isActualValue,
            };
        });

        return downloadDataItem;
    }

    // Returns a data in comma separated value format for download (special format for areaRange).
    getDownloadDataForAreaRange(
        allSeries: (IChartDataSeries | IChartAreaRangeDataSeries)[],
        dataSeries: ChartOptionsDefinitions[],
        numberOfDecimalPlaces?: number,
        onlyActualValues?: boolean,
    ) {
        const downloadData: IDownloadData[] = [];

        allSeries.forEach((series, index) => {
            const endIndex = series.data.length;

            (dataSeries[index].data as number[] | number[][]) = series.data.slice(0, endIndex).map((item) => {
                if ((item as IChartData).value) {
                    return [item.asOfDate.getTime(), (item as IChartData).value];
                }

                return [item.asOfDate.getTime(), (item as IChartAreaRangeData).minimumValue, (item as IChartAreaRangeData).maximumValue];
            });

            const downloadDataItem = this.getDownloadItem(
                series as IChartDataSeries,
                undefined,
                onlyActualValues,
                false,
                numberOfDecimalPlaces,
                numberOfDecimalPlaces,
            );

            downloadData.push({ label: series.label, data: downloadDataItem } as IDownloadData);
        });

        return downloadData;
    }

    // Returns a data in comma separated value format for download.
    getDownloadData(allSeries: IChartDataSeries[], dataFrequency?: DemeterDataFrequency, numberOfDecimalPlaces?: number) {
        const downloadData: IDownloadData[] = [];

        allSeries.forEach((series) => {
            const downloadDataItem = this.getDownloadItem(
                series as IChartDataSeries,
                dataFrequency,
                false,
                false,
                numberOfDecimalPlaces,
                numberOfDecimalPlaces,
            );

            downloadData.push({ label: series.label, data: downloadDataItem } as IDownloadData);

            const forwardCurveDataItem = this.getDownloadItem(series as IChartDataSeries, undefined, false, true, numberOfDecimalPlaces, numberOfDecimalPlaces);

            if (forwardCurveDataItem) {
                downloadData.push({ label: series.forecastLabel, data: forwardCurveDataItem });
            }
        });

        return downloadData;
    }

    getDownloadDataForSeasonalChart(allSeries: IChartSeasonalDataSeries[], dataFrequency?: DemeterDataFrequency, numberOfDecimalPlaces?: number) {
        const downloadData: IDownloadData[] = [];

        allSeries.forEach((series) => {
            const dataSeries = [
                {
                    ...series,
                },
            ];

            if (series.lineDataType === 'forecast') {
                dataSeries[0].data = series.data.filter((x) => !x.isActualValue);
            }

            if (series.lineDataType === 'range') {
                dataSeries[0].label = `${series.label} - ${languageService.translate(translationKeys.words.minimum)}`;
                dataSeries[0].data = series.data.map((dataItem) => ({
                    ...dataItem,
                    value: (dataItem as IChartAreaRangeData).minimumValue,
                }));

                dataSeries.push({
                    ...dataSeries[0],
                    label: `${series.label} - ${languageService.translate(translationKeys.words.maximum)}`,
                    data: series.data.map((dataItem) => ({
                        ...dataItem,
                        value: (dataItem as IChartAreaRangeData).maximumValue,
                    })),
                });
            }

            dataSeries.forEach((x) => {
                const downloadDataItem = this.getDownloadItem(
                    x as IChartDataSeries,
                    dataFrequency,
                    false,
                    false,
                    numberOfDecimalPlaces,
                    numberOfDecimalPlaces,
                    series.lineDataType === 'range' || series.lineDataType === 'average' ? (date) => formattingService.toMonth(date.getMonth()) : undefined,
                );

                downloadData.push({ label: x.label, data: downloadDataItem } as IDownloadData);
            });
        });

        return downloadData;
    }
}

const chartService = new ChartService();

export default chartService;
