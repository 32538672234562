import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { breadCrumbs } from '../../Core/Utility/HelperObjects';
import {
    DemeterFeatureType,
    DemeterMarket,
    DemeterPermissionType,
    DemeterRegistrationSource,
    DemeterTableDefinitionType,
    DemeterUserType,
} from '../../Generated/Raven-Demeter';
import NavigationRoutes from '../../Layouts/NavigationRoutes';
import { useApplicationSelector } from '../../Redux/ReduxStore';
import { selectRegistrationSource, selectUserCurrentMarket, selectUserType } from '../../Redux/Slices/UserSlice';
import Dropdown from '../../Refactor/Components/Form/Inputs/Dropdown';
import { SelectInputOption } from '../../Refactor/Components/Form/Inputs/SelectInput';
import useSearchParameters from '../../Refactor/Components/Navigation/Hooks/useSearchParametersHook';
import useScreenSize from '../../Refactor/Components/Responsive/Hooks/useScreenSizeHook';
import useFeatureFlag from '../../Refactor/Services/FeatureFlags/useFeatureFlagHook';
import useLanguage from '../../Refactor/Services/Language/useLanguageHook';
import { EventActionsEnum, EventDataTargetsEnum } from '../../Refactor/Services/Logging/DataLayerDefinitions';
import loggingService from '../../Refactor/Services/Logging/LoggingService';
import usePermission from '../../Refactor/Services/Permissions/usePermissionHook';
import styles from './Sidebar.module.scss';

type SearchParametersType = 'exchangeCommodity' | 'regionCommodity';

interface INavigationLink {
    divider?: boolean;
    isLineBreak?: boolean;
    isPageOn?: boolean;
    link?: string;
    name: string;
    recordSearchParameters?: boolean;
    searchParametersType?: SearchParametersType;
    tableDefinitionType?: DemeterTableDefinitionType;
    url?: string;
    includedMarkets?: DemeterMarket[]; // If this is undefined, link is good for all markets, otherwise, only for these markets.
    excludedMarkets?: DemeterMarket[]; // Use this to exclude these markets from this menu item.
}

interface INavigationLinkStatus {
    isCurrentLink?: boolean;
    searchString?: string;
}

interface INavigationLinkStatusMap {
    [key: string]: INavigationLinkStatus;
}

const Sidebar: React.FC = () => {
    const location = useLocation().pathname.toUpperCase();
    const market = useApplicationSelector(selectUserCurrentMarket);
    const currentUserType = useApplicationSelector(selectUserType);
    const currentRegistrationSource = useApplicationSelector(selectRegistrationSource);
    const isCancelTrialPageOn =
        currentUserType === DemeterUserType.Regular && currentRegistrationSource === DemeterRegistrationSource.SelfRegistrationWithCreditCard;
    const isAdministrationPageOn = useFeatureFlag(DemeterFeatureType.Administration);
    const isMarketIndicatorPageOn = useFeatureFlag(DemeterFeatureType.MarketIndicators) && usePermission(DemeterPermissionType.MarketIndicators);
    const isFeatureFlagsPageOn = currentUserType === DemeterUserType.Administrator;
    const isRiskPageOn = useFeatureFlag(DemeterFeatureType.PositionsCalculator);
    const isUserDataPageOn =
        useFeatureFlag(DemeterFeatureType.UserData) && (currentUserType === DemeterUserType.Administrator || currentUserType === DemeterUserType.Premium);

    const [searchParameters] = useSearchParameters();
    const [translations] = useLanguage();
    const screenSize = useScreenSize();
    const navigate = useNavigate();

    const allLinks = useMemo<INavigationLink[]>(
        () => [
            { name: translations.dashboard.title, link: NavigationRoutes.Dashboard },
            {
                name: market === DemeterMarket.Dairy ? translations.futures.title : translations.futures.titleWithoutOtc,
                link: NavigationRoutes.Futures,
                recordSearchParameters: true,
            },
            {
                name: translations.physicalPrices.title,
                link: NavigationRoutes.PhysicalPrices,
                recordSearchParameters: true,
                includedMarkets: [DemeterMarket.Dairy],
            },
            {
                name: translations.page.marketIndicators,
                link: NavigationRoutes.MarketIndicators,
                recordSearchParameters: true,
                isPageOn: isMarketIndicatorPageOn,
            },
            { name: translations.page.production, link: NavigationRoutes.Production, recordSearchParameters: true },
            { name: translations.page.exports, link: NavigationRoutes.Exports, recordSearchParameters: true },
            { name: translations.page.imports, link: NavigationRoutes.Imports, recordSearchParameters: true },
            { name: translations.page.domestics, link: NavigationRoutes.Domestics, recordSearchParameters: true },
            { name: translations.page.stocks, link: NavigationRoutes.Stocks, recordSearchParameters: true },
            {
                name: translations.balanceTable.title,
                link: NavigationRoutes.BalanceTable,
                recordSearchParameters: true,
                includedMarkets: [DemeterMarket.Dairy],
            },

            { name: translations.page.calculators, link: NavigationRoutes.Calculators },
            { name: translations.page.risk, link: NavigationRoutes.Risk, isPageOn: isRiskPageOn, includedMarkets: [DemeterMarket.Dairy] },
            { name: translations.page.userData, link: NavigationRoutes.UserData, isPageOn: isUserDataPageOn },
            { name: translations.administration.title, link: NavigationRoutes.Administration, isPageOn: isAdministrationPageOn },
            {
                name: translations.featureFlags.title,
                link: NavigationRoutes.FeatureFlags,
                isPageOn: isFeatureFlagsPageOn,
            },
            { name: 'Line Break', isLineBreak: true },
            { name: translations.outlookAndReports.title, link: NavigationRoutes.OutlookAndReports, divider: true, includedMarkets: [DemeterMarket.Dairy] },
            { name: translations.page.frequentlyAskedQuestions, link: NavigationRoutes.Faqs },
            { name: translations.page.disclaimer, link: NavigationRoutes.Disclaimer },
            { name: translations.page.privacyPolicy, link: NavigationRoutes.PrivacyPolicy },
            { name: translations.page.cancelTrial, link: NavigationRoutes.CancelTrial, isPageOn: isCancelTrialPageOn },
        ],
        [market, isAdministrationPageOn, isUserDataPageOn, isMarketIndicatorPageOn, isRiskPageOn, isCancelTrialPageOn, translations],
    );

    const [linkStatusMap, setLinkStatusMap] = useState<INavigationLinkStatusMap>({});

    // Used to keep cache of the search string for each page.
    useEffect(() => {
        const parsedLocation = location.indexOf('?') > 0 ? location.substring(0, location.indexOf('?')) : location;
        const currentLink = allLinks.filter((link) => link.link).find((link) => parsedLocation.endsWith(link.link!.toUpperCase()));
        const newLinkStatusMap: INavigationLinkStatusMap = { ...linkStatusMap, [currentLink?.link ?? '']: {} };

        Object.keys(newLinkStatusMap).forEach((key) => {
            const linkStatus = newLinkStatusMap[key];
            linkStatus.isCurrentLink = key === currentLink?.link;

            if (linkStatus.isCurrentLink && currentLink?.recordSearchParameters && Object.values(searchParameters).filter((x) => x).length !== 0) {
                linkStatus.searchString = `?${Object.keys(searchParameters).reduce((searchString, searchStringKey) => {
                    if (searchString === '') {
                        return `${searchStringKey}=${searchParameters[searchStringKey]}`;
                    }

                    return `${searchString}&${searchStringKey}=${searchParameters[searchStringKey]}`;
                }, '')}`;
            }
        });

        setLinkStatusMap(newLinkStatusMap);
    }, [location, searchParameters]);

    const handleNavigationClick = (navigationLink: INavigationLink): void => {
        breadCrumbs.page = navigationLink.name;
        loggingService.trackEventWithAnalytics(EventActionsEnum.Navigation, `Navigate to ${navigationLink.name} page`, '', EventDataTargetsEnum.Sidebar);
    };

    const findSidebarSelection = (): string | undefined => {
        const selection = allLinks.find((item: INavigationLink) => linkStatusMap[item.link ?? '']?.isCurrentLink);
        return selection?.link;
    };

    const getSidebarOptions = (): (SelectInputOption<string | undefined> & { divider?: boolean })[] => {
        const sidebarOptions = allLinks.filter((item: INavigationLink) => item?.isPageOn !== false && !item?.isLineBreak);
        return sidebarOptions?.map((item: INavigationLink) => ({ label: item.name, value: item?.link, divider: item.divider }));
    };

    if (screenSize?.type === 'sm' || screenSize?.type === 'xs') {
        return (
            <div className={styles.responsive_sidebar}>
                <Dropdown
                    value={findSidebarSelection()}
                    options={getSidebarOptions()}
                    handleOptionChange={(value: string | undefined) => navigate(value ?? '')}
                />
            </div>
        );
    }

    return (
        <div className={styles.sidebar_blue}>
            <div className={styles.sidebar_content}>
                <div className={styles.sidebar_navigation}>
                    {allLinks
                        .filter((x) => !x.includedMarkets || x.includedMarkets.includes(market))
                        .map(
                            (navigationLink: INavigationLink) =>
                                navigationLink.isPageOn !== false && (
                                    <React.Fragment key={`${navigationLink.name.toString()}_link`}>
                                        {navigationLink.isLineBreak && <div className={styles.sidebar_navigation_divider} />}
                                        {!navigationLink.isLineBreak && (
                                            <div key={`${navigationLink.name.toString()}_link`} className={styles.master_flex}>
                                                <Link
                                                    onClick={() => handleNavigationClick(navigationLink)}
                                                    className={
                                                        linkStatusMap[navigationLink.link ?? '']?.isCurrentLink
                                                            ? styles.sidebar_navItem_selected
                                                            : styles.sidebar_navItem
                                                    }
                                                    to={`${navigationLink.link!}${linkStatusMap[navigationLink.link ?? '']?.searchString ?? ''}`}
                                                >
                                                    {navigationLink.name}
                                                </Link>
                                            </div>
                                        )}
                                    </React.Fragment>
                                ),
                        )}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
