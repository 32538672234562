import { useCallback, useLayoutEffect, useRef, useState } from 'react';

export interface DimensionObject {
    width: number;
    height: number;
    top: number;
    left: number;
    x: number;
    y: number;
    right: number;
    bottom: number;
}

const useDynamicDimensions = (): [(node: HTMLElement | null) => void, DimensionObject] => {
    const [dimensions, setDimensions] = useState<DimensionObject>({
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        x: 0,
        y: 0,
        right: 0,
        bottom: 0,
    });

    const observerReference = useRef<ResizeObserver | null>(null);

    const externalReference = useCallback((node: HTMLElement | null) => {
        if (observerReference.current) {
            observerReference.current.disconnect();
        }

        if (node) {
            observerReference.current = new ResizeObserver((entries) => {
                const entry = entries[0];
                setDimensions({
                    width: entry.contentRect.width,
                    height: entry.contentRect.height,
                    top: entry.contentRect.top,
                    left: entry.contentRect.left,
                    x: entry.contentRect.x,
                    y: entry.contentRect.y,
                    right: entry.contentRect.right,
                    bottom: entry.contentRect.bottom,
                });
            });
            observerReference.current.observe(node);
        }
    }, []);

    useLayoutEffect(
        () => () => {
            if (observerReference.current) {
                observerReference.current.disconnect();
            }
        },
        [],
    );

    return [externalReference, dimensions];
};

export default useDynamicDimensions;
