import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import AgGridBuilder from '../../../../Components/AgGridBuilder/AgGridBuilder';
import { Currency, DemeterSymbolContractModel, DemeterSymbolModel, ExchangeType, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { MarketPriceModel, selectMarketPricesBySymbol } from '../../../../Redux/Slices/MarketPricesSlice';
import { selectStoreId } from '../../../../Redux/Slices/SystemSlice';
import useCurrencyConversionApi from '../../../Apis/Hooks/useCurrencyConversionApiHook';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import useUnitOfMeasureConversionApi from '../../../Apis/Hooks/useUnitOfMeasureConversionApiHook';
import Switch from '../../../Components/Form/Buttons/Switch';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import { IExchangeCommoditySelection } from '../../../Components/Navigation/Hooks/useExchangeCommodityNavigationHook';
import ShowMoreAccordian from '../../../Components/Tables/ShowMoreAccordian/ShowMoreAccordian';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import lightstreamerMarketPricesService from '../../../Services/MarketPrices/LightstreamerMarketPricesService';
import FuturesPricesChart from './FuturesPricesChart';
import FuturesPricesComparisonChart from './FuturesPricesComparisonChart';
import FuturesPricesForwardCurveChart from './FuturesPricesForwardCurveChart';
import styles from './FuturesPricesTable.module.scss';
import {
    ExchangeSettings,
    futuresPricesColumnDefinitions,
    futuresPricesColumnOptions,
    RendererParameters,
    SymbolContractCompositeModel,
} from './FuturesPricesTableDefinitions';

export interface IFuturesPricesTableProps {
    exchangeCommoditySelection: IExchangeCommoditySelection;
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
}

export type SymbolContract = DemeterSymbolContractModel & MarketPriceModel & { id: string; multipler: number };

const rowsToShow = 12;
const defaultContractNumber = 1;
const selectionLimit = 5;

const FuturesPricesTable: React.FC<IFuturesPricesTableProps> = (props: IFuturesPricesTableProps) => {
    const [translations] = useLanguage();

    // Data hooks.
    const storeId = useApplicationSelector(selectStoreId);
    const marketPricesBySymbol = useApplicationSelector(selectMarketPricesBySymbol);

    // Symbols hooks.
    const symbols = useSymbolsApi();
    const [symbolModel, setSymbolModel] = useState<DemeterSymbolModel>();
    const symbolContractCompositeModelsReference = useRef<SymbolContractCompositeModel[]>([]);
    const [symbolContractCompositeModels, setSymbolContractCompositeModels] = useState<SymbolContractCompositeModel[]>(
        symbolContractCompositeModelsReference.current,
    );
    const [symbolContractSelections, setSymbolContractSelections] = useState<SymbolContractCompositeModel[]>([]);

    // Filter hooks.
    const [getCurrencyConversionRate, currencyConversionMap] = useCurrencyConversionApi(symbolModel?.reutersInstrumentCodePrefix);
    const [getUnitOfMeasureConversionRate, unitOfMeasureConversionMap] = useUnitOfMeasureConversionApi(symbolModel?.reutersInstrumentCodePrefix);
    const [showMore, setShowMore] = useState<boolean>(false);

    // Table hooks.
    const gridReference = useRef<AgGridReact>();

    // We need to create this part of the table definition here because of the local selectRow function.
    const actionsCellRenderer = (parameters: RendererParameters) => (
        <Switch
            disabled={symbolContractSelections?.length === selectionLimit && !parameters.data.selected}
            checked={parameters.data.selected}
            handleChange={() => {
                selectRow(parameters.data.rowIndex, true);
            }}
        />
    );

    const futuresPricesColumnDefinitionsWithRenderer = useMemo(() => {
        const actionField = {
            ...futuresPricesColumnDefinitions[futuresPricesColumnDefinitions.length - 1],
            cellRenderer: actionsCellRenderer,
        };
        const newFuturesPricesColumnDefinition = [...futuresPricesColumnDefinitions].slice(0, futuresPricesColumnDefinitions.length - 1);
        const hasTradeRegistration = ExchangeSettings[props.exchangeCommoditySelection.exchange as ExchangeType].hasTradeRegistrationColumns;

        newFuturesPricesColumnDefinition.forEach((x) => {
            if (x.field.startsWith('tradeRegistration')) {
                x.hide = !hasTradeRegistration;
            }
        });

        return [...newFuturesPricesColumnDefinition, actionField];
    }, [futuresPricesColumnDefinitions]);

    // This is used to cache the column definitions generated by ag-grid itself so we can hide columns programmatically and have it drawn correctly.
    // This is also why ag-grid is a pain because it make doing something simple so complex.
    const [currentColumnDefinitions, setCurrentColumnDefinitions] = useState<ColDef[]>(() => futuresPricesColumnDefinitionsWithRenderer as ColDef[]);

    useEffect(() => {
        if (!symbols) {
            return;
        }

        const selectedSymbol = symbols.find(
            (x) => x.exchange === props.exchangeCommoditySelection.exchange && x.commodity === props.exchangeCommoditySelection.commodity,
        );

        if (selectedSymbol) {
            setSymbolModel(selectedSymbol);
        }
    }, [symbols, props.exchangeCommoditySelection]);

    // Add in trade registration columns when requested. This isn't the best way to do this, but it works
    // for now until we find a replacement for ag-grid.
    useEffect(() => {
        const hasTradeRegistration = ExchangeSettings[props.exchangeCommoditySelection.exchange as ExchangeType].hasTradeRegistrationColumns;
        const actionsColumn = {
            ...currentColumnDefinitions[currentColumnDefinitions.length - 1],
            cellRenderer: actionsCellRenderer,
        };

        let sharedColumnDefinitions = currentColumnDefinitions.filter((x) => !x.field?.startsWith('tradeRegistration'));
        sharedColumnDefinitions = sharedColumnDefinitions.slice(0, sharedColumnDefinitions.length - 1);

        if (hasTradeRegistration) {
            const tradeRegistrationColumns = futuresPricesColumnDefinitions.filter((x) => x.field?.startsWith('tradeRegistration'));
            tradeRegistrationColumns.forEach((x) => {
                x.hide = false;
            });
            setCurrentColumnDefinitions([...sharedColumnDefinitions, ...tradeRegistrationColumns, actionsColumn]);
        } else {
            setCurrentColumnDefinitions([...sharedColumnDefinitions, actionsColumn]);
        }
    }, [props.exchangeCommoditySelection, symbolContractSelections]);

    // Tell the lightstreamer service to get new data if the commodity changes.
    useEffect(() => {
        if (!symbolModel?.symbolContracts) {
            return;
        }

        const codes = symbolModel.symbolContracts.map((x) => x.reutersInstrumentCode);
        lightstreamerMarketPricesService.start(storeId, codes);
    }, [symbolModel]);

    // Stop the lightstreamer service when we leave this component.
    useEffect(
        () => () => {
            lightstreamerMarketPricesService.stop(storeId);
        },
        [],
    );

    const convertMarketPrice = (contract: SymbolContractCompositeModel): SymbolContractCompositeModel => {
        const convert = (value: number | undefined) => {
            if (value === undefined) {
                return undefined;
            }
            return getUnitOfMeasureConversionRate(props.unitOfMeasure) * getCurrencyConversionRate(props.currency, contract.year, contract.month) * value;
        };

        return {
            ...contract,
            ...{
                contractYearMonth: `${contract.year}${contract.month}`,
                latestPrice: contract.latestPrice ?? undefined,
                latestPriceConverted: convert(contract.latestPrice),
                latestOrSettledPriceConverted: convert(contract.latestPrice) ?? convert(contract.settlementPrice),
                bidPriceConverted: convert(contract.bidPrice),
                askPriceConverted: convert(contract.askPrice),
                settlementPriceConverted: convert(contract.settlementPrice),
                netChangeConverted: convert(contract.netChange),
                openPriceConverted: convert(contract.openPrice),
                expirationDateFormatted: formattingService.toShortDayMonthYear(contract.expirationDate),
                lastSessionHighPriceConverted: convert(contract.lastSessionHighPrice),
                lastSessionLowPriceConverted: convert(contract.lastSessionLowPrice),
                settlementNetChangeConverted: convert(contract.settlementNetChange),
                highPriceConverted: convert(contract.highPrice),
                lowPriceConverted: convert(contract.lowPrice),
                tradeRegistrationPriceConverted: convert(contract.tradeRegistrationPrice),
            },
        };
    };

    // Update the initial symbol contract when we selected a different symbol.
    useEffect(() => {
        if (!symbolModel?.symbolContracts) {
            return;
        }

        if (symbolModel.symbolContracts) {
            const contracts = symbolModel.symbolContracts.map((row, index) => {
                const contract: SymbolContractCompositeModel = {
                    id: `${index}`,
                    rowIndex: index,
                    selected: false,
                    multipler: symbolModel.priceMultiplierFutures,
                    reutersInstrumentCodePrefix: symbolModel.reutersInstrumentCodePrefix,
                    ...row,
                };

                const marketPrice = marketPricesBySymbol[row.reutersInstrumentCode];

                if (!marketPrice) {
                    return contract;
                }

                return convertMarketPrice({
                    ...contract,
                    ...marketPrice,
                });
            });

            setSymbolContractCompositeModels(contracts);
            setSymbolContractSelections(contracts.filter((x) => x.selected));
            symbolContractCompositeModelsReference.current = contracts;
            selectRow((symbolModel.rollingContractNumber ?? defaultContractNumber) - 1);
        }
    }, [symbolModel]);

    // Update the prices when they come in.
    useEffect(() => {
        if (symbolContractCompositeModels.length === 0 || !currencyConversionMap || !unitOfMeasureConversionMap) {
            return;
        }

        const contracts = symbolContractCompositeModels.map((row) => {
            const marketPrice = marketPricesBySymbol[row.reutersInstrumentCode];

            if (!marketPrice) {
                return row;
            }

            return convertMarketPrice({
                ...row,
                ...marketPrice,
            });
        });

        setSymbolContractCompositeModels(contracts);
        symbolContractCompositeModelsReference.current = contracts;
    }, [marketPricesBySymbol, currencyConversionMap, props.currency, unitOfMeasureConversionMap, props.unitOfMeasure]);

    const selectRow = (rowIndex: number, allowMultipleSelections?: boolean, setSelected?: boolean) => {
        const actionFieldName = futuresPricesColumnDefinitions[futuresPricesColumnDefinitions.length - 1].field ?? '';
        const selectedRows = gridReference?.current?.api?.getSelectedRows();
        if (!allowMultipleSelections) {
            (symbolContractCompositeModelsReference?.current ?? []).forEach((symbolContractModel) => {
                if (symbolContractModel.rowIndex !== rowIndex) {
                    symbolContractModel.selected = false;
                    const rowNode = gridReference?.current?.api.getRowNode(symbolContractModel.id);
                    rowNode?.setSelected(false);
                    rowNode?.setDataValue(actionFieldName, false);
                }
            });
        }

        const row = symbolContractCompositeModelsReference?.current[rowIndex];

        if (row) {
            row.selected = !row.selected || (!allowMultipleSelections && (selectedRows?.length ?? 0) > 1) || !!setSelected;
            const selectedRow = gridReference?.current?.api?.getRowNode(row.id);
            selectedRow?.setSelected(row.selected);
            selectedRow?.setDataValue(actionFieldName, !!row.selected || (!allowMultipleSelections && (selectedRows?.length ?? 0) > 1));
        }

        symbolContractCompositeModelsReference.current = [...symbolContractCompositeModelsReference.current];
        setSymbolContractCompositeModels(symbolContractCompositeModelsReference.current);
        setSymbolContractSelections(symbolContractCompositeModelsReference.current.filter((x) => x.selected));
    };

    return (
        <div className={styles.futures_prices_table_container}>
            {symbolContractCompositeModels.length === 0 || symbolContractCompositeModelsReference.current.length === 0 ? (
                <PageLoadingSpinner />
            ) : (
                <>
                    <AgGridBuilder
                        gridRef={gridReference}
                        rowData={showMore ? symbolContractCompositeModels : symbolContractCompositeModels.slice(0, rowsToShow)}
                        hasSaveColumnsState
                        columnDefinitions={currentColumnDefinitions}
                        setColumnDefinitions={setCurrentColumnDefinitions}
                        defaultColumnDefinition={futuresPricesColumnOptions}
                        domLayout="autoHeight"
                        cellClickedHandler={(event: { colDef: { field: string }; data: SymbolContractCompositeModel }) => {
                            const actionField = futuresPricesColumnDefinitionsWithRenderer[futuresPricesColumnDefinitionsWithRenderer.length - 1].field ?? '';
                            if (event.colDef.field !== actionField) {
                                selectRow(event.data.rowIndex);
                            }
                        }}
                        onGridReady={() => {
                            selectRow((symbolModel?.rollingContractNumber ?? defaultContractNumber) - 1, false, true);
                        }}
                        testId="FuturesPricesTable"
                    />
                    <ShowMoreAccordian
                        showAccordian={symbolContractCompositeModels.length > rowsToShow}
                        showMore={showMore}
                        handleShowMoreToggle={(value) => setShowMore(value)}
                        additionalText={
                            ExchangeSettings[props.exchangeCommoditySelection.exchange as ExchangeType].hasRealtimeData
                                ? translations.futures.text.realtimeFooter
                                : translations.futures.text.delayedFooter
                        }
                    />
                    <div className={styles.futures_prices_chart_container}>
                        <FuturesPricesChart
                            exchangeCommoditySelection={props.exchangeCommoditySelection}
                            symbolContracts={symbolContractSelections}
                            currency={props.currency}
                            unitOfMeasure={props.unitOfMeasure}
                            testId="FuturesPricesChart"
                        />
                    </div>
                    <div className={styles.futures_prices_chart_container_small}>
                        <FuturesPricesComparisonChart
                            exchangeCommoditySelection={props.exchangeCommoditySelection}
                            currency={props.currency}
                            unitOfMeasure={props.unitOfMeasure}
                            testId="FuturesPricesComparisonChart"
                        />
                    </div>
                    <div className={styles.futures_prices_chart_container_small}>
                        <FuturesPricesForwardCurveChart
                            exchangeCommoditySelection={props.exchangeCommoditySelection}
                            currency={props.currency}
                            unitOfMeasure={props.unitOfMeasure}
                            testId="FuturesPricesForwardCurveChart"
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default FuturesPricesTable;
