import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import { ExchangeType } from '../../../../Generated/Raven-Demeter';
import formattingService from '../../../Services/Formatting/FormattingService';
import languageService from '../../../Services/Language/LanguageService';
import { translationKeys } from '../../../Services/Language/TranslationDefinitions';
import styleService from '../../../Services/Styles/StyleService';
import type { SymbolContract } from './FuturesPricesTable';

export interface ExchangeSetting {
    hasRealtimeData?: boolean;
    hasTradeRegistrationColumns?: boolean;
}

export const ExchangeSettings: { [key: string]: ExchangeSetting } = {
    [ExchangeType.Cme as string]: {
        hasRealtimeData: false,
        hasTradeRegistrationColumns: false,
    },
    [ExchangeType.Eex as string]: {
        hasRealtimeData: true,
        hasTradeRegistrationColumns: true,
    },
    [ExchangeType.Euronext as string]: {
        hasRealtimeData: false,
        hasTradeRegistrationColumns: false,
    },
    [ExchangeType.Ice as string]: {
        hasRealtimeData: false,
        hasTradeRegistrationColumns: false,
    },
    [ExchangeType.Sgx as string]: {
        hasRealtimeData: false,
        hasTradeRegistrationColumns: false,
    },
};

export type SymbolContractCompositeModel = SymbolContract & {
    id: string;
    rowIndex: number;
    selected: boolean;
    reutersInstrumentCodePrefix: string;
    contractYearMonth?: string;
    latestPriceConverted?: number;
    latestOrSettledPriceConverted?: number;
    bidPriceConverted?: number;
    askPriceConverted?: number;
    settlementPriceConverted?: number;
    netChangeConverted?: number;
    openPriceConverted?: number;
    expirationDateFormatted?: string;
    lastSessionHighPriceConverted?: number;
    lastSessionLowPriceConverted?: number;
    settlementNetChangeConverted?: number;
    highPriceConverted?: number;
    lowPriceConverted?: number;
    tradeRegistrationPriceConverted?: number;
};

export type RendererParameters = {
    data: SymbolContractCompositeModel;
    value: number | string;
};

const getExactPrecision = (number: number, precision: number) => number.toPrecision(precision).replace(new RegExp(`((\\d\\.*){${precision}}).*`), '$1');

const contractMonthCellRenderer = (parameters: RendererParameters) =>
    formattingService.toMonthYear(new Date(parameters.data.year, parameters.data.month - 1, 1));

const latestPriceCellRenderer = (parameters: RendererParameters) => {
    if (!parameters.data.latestPriceConverted && !parameters.data.settlementPriceConverted) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }

    let value = parameters.data.latestPriceConverted ?? parameters.data.settlementPriceConverted;
    value = value! * parameters.data.multipler;
    const suffix = parameters.data.latestPrice ? '' : 's';

    return `${+getExactPrecision(value, 6)}${suffix}`;
};

const priceCellRenderer = (parameters: RendererParameters) => {
    if (!parameters.value) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }

    return +getExactPrecision(+parameters.value * parameters.data.multipler, 6);
};

const percentCellRenderer = (parameters: RendererParameters) => {
    if (!parameters.value) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }

    return formattingService.toPercent(+parameters.value);
};

const numberCellRenderer = (parameters: RendererParameters) => (!parameters.value ? applicationConstants.TablePlaceholderZeroOrEmpty : parameters.value);

export const futuresPricesColumnOptions = {
    resizable: true,
    wrapHeaderText: true,
    flex: 1,
    animateRows: true,
    wrapText: true,
    enableCellChangeFlash: true,
};

export const futuresPricesColumnDefinitions = [
    {
        field: 'reutersInstrumentCode',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.symbol),
        minWidth: 100,
        width: 100,
        lockPinned: true,
        pinned: 'left',
        rowDrag: false,
        suppressMovable: true,
    },
    {
        field: 'contractYearMonth',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.contractMonth),
        minWidth: 120,
        width: 120,
        cellRenderer: contractMonthCellRenderer,
    },
    {
        field: 'latestOrSettledPriceConverted',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.last),
        minWidth: 75,
        width: 75,
        cellRenderer: latestPriceCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'bidSize',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.bidSize),
        minWidth: 75,
        width: 75,
        cellRenderer: numberCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'bidPriceConverted',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.bidPrice),
        minWidth: 75,
        width: 75,
        cellRenderer: priceCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'askPriceConverted',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.askPrice),
        minWidth: 75,
        width: 75,
        cellRenderer: priceCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'askSize',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.askSize),
        minWidth: 75,
        width: 75,
        cellRenderer: numberCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'volume',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.volumeTradedToday),
        minWidth: 75,
        width: 75,
        cellRenderer: numberCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'settlementPriceConverted',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.settle),
        minWidth: 75,
        width: 75,
        cellRenderer: priceCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'openInterest',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.openInterest),
        minWidth: 100,
        width: 100,
        cellRenderer: numberCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'currency',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.currency),
        minWidth: 100,
        width: 100,
        hide: true,
        type: 'rightAligned',
    },
    {
        field: 'openPriceConverted',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.open),
        minWidth: 100,
        width: 100,
        hide: true,
        cellRenderer: priceCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'openInterestNetChange',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.openInterestNetChange),
        minWidth: 100,
        width: 100,
        hide: true,
        cellRenderer: numberCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'expirationDateFormatted',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.expirationDate),
        minWidth: 100,
        width: 100,
        hide: true,
        type: 'rightAligned',
    },
    {
        field: 'lastSessionVolume',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.lastSessionVolume),
        minWidth: 100,
        width: 100,
        cellRenderer: numberCellRenderer,
        hide: true,
        type: 'rightAligned',
    },
    {
        field: 'lastSessionHighPriceConverted',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.priorSessionHigh),
        minWidth: 100,
        width: 100,
        hide: true,
        cellRenderer: priceCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'lastSessionLowPriceConverted',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.priorSessionLow),
        minWidth: 100,
        width: 100,
        hide: true,
        cellRenderer: priceCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'settlementNetChangeConverted',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.settleNetChange),
        cellClass: styleService.positiveOrNegativeCellClassSetter,
        minWidth: 100,
        width: 100,
        hide: true,
        cellRenderer: priceCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'highPriceConverted',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.high),
        minWidth: 100,
        width: 100,
        hide: true,
        cellRenderer: priceCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'lowPriceConverted',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.low),
        minWidth: 100,
        width: 100,
        hide: true,
        cellRenderer: priceCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'percentChange',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.percentChange),
        cellClass: styleService.positiveOrNegativeCellClassSetter,
        minWidth: 75,
        width: 75,
        cellRenderer: percentCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'netChangeConverted',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.netChange),
        cellClass: styleService.positiveOrNegativeCellClassSetter,
        minWidth: 75,
        width: 75,
        cellRenderer: priceCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'tradeRegistrationPriceConverted',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.tradeRegistrationPrice),
        minWidth: 130,
        width: 130,
        hide: false,
        cellRenderer: priceCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'tradeRegistrationVolume',
        headerValueGetter: () => languageService.translate(translationKeys.futures.fields.tradeRegistrationVolume),
        minWidth: 130,
        width: 130,
        hide: false,
        cellRenderer: numberCellRenderer,
        type: 'rightAligned',
    },
    {
        field: 'actions',
        headerValueGetter: () => languageService.translate(translationKeys.fields.chartControl),
        lockPinned: true,
        pinned: 'right',
        rowDrag: false,
        suppressMovable: true,
        cellRenderer: null,
        cellRendererParams: null,
        cellStyle: {
            color: 'blue',
        },
        maxWidth: 125,
        width: 125,
        type: 'rightAligned',
    },
];
