import { DashStyleValue, Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import scssVariables from '../../../Config.module.scss';
import { Currency, UnitOfMeasure } from '../../../Generated/Raven-Demeter';

const defaultNavigatorMargin = 10;

// Highcharts keys and defaults. These are keys to use on the highcharts object to avoid misspelling and improper casing.
export const defaultZoneAxis = 'x';
export const defaultForecastLineWidth = 2;
export const columnChartType = 'column';
export const lineChartType = 'line';
export const areaRangeChartType = 'arearange';
export const areaChartType = 'area';
export const solidStyle = 'Solid';
export const shortDashStyle = 'ShortDash';
export const longDashStyle = 'LongDash';
export const dotDashStyle = 'Dot';

export interface IChartData {
    value: number;
    asOfDate: Date;
    isActualValue: boolean;
}

export interface IChartDataSeries {
    label: string;
    data: IChartData[];
    forwardCurveData?: IChartData[];
    yAxis?: number;
    axisLabel?: string;
    forecastLabel?: string;
}

export type BarChartFormatOptions = 'percent' | 'currency' | 'value';

export interface IChartBarDataSeries extends IChartDataSeries {
    format?: BarChartFormatOptions;
    displayDecimalPlacesMinimum?: number;
    displayDecimalPlacesMaximum?: number;
    isHidden?: boolean;
}

export interface IChartAreaRangeData extends IChartData {
    minimumValue: number;
    maximumValue: number;
}

export interface IChartAreaRangeDataSeries {
    label: string;
    data: IChartAreaRangeData[];
    displayDecimalPlacesMinimum?: number;
    displayDecimalPlacesMaximum?: number;
    decileRank?: number;
}

export type LineDataType = 'average' | 'forecast' | 'range' | 'value';

export interface IChartSeasonalDataSeries extends IChartDataSeries {
    lineDataType: LineDataType;
    yearsOrNumberOfYears: number;
    data: IChartData[] | IChartAreaRangeData[];
}

export interface IChartPriceDataSeries extends IChartDataSeries {
    forwardCurveLabel?: string;
    forwardCurveData?: IChartData[];
    // This is for the case where each individual data series has its own unit of measure.
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
    forwardCurveLineStyle?: DashStyleValue;
}

export interface IDownloadData {
    label?: string;
    data: {
        value: string;
        date: number | string;
        rawDate: number | string | Date;
        isActualValue: boolean;
    }[];
}

export interface OptionsWithDownloads extends Options {
    downloadData?: IDownloadData;
}

export interface IChartProps {
    chartReference?: (newChartReference: HighchartsReact.RefObject) => void;
    displayDecimalPlacesMinimum?: number;
    displayDecimalPlacesMaximum?: number;
}

export enum ChartDisplayType {
    Area = 'Area',
    Column = 'Column',
    Line = 'Line',
    Spline = 'Spline',
}

export type ChartContextSeries = { name: string; visible: boolean; hide: () => void; show: () => void };

export type ChartContext = {
    chart: { series: ChartContextSeries[] };
    name: string;
    x: number;
    y: number;
    index: number;
    point: {
        asOfDate?: Date;
        isActualValue?: boolean;
        color: string;
        high?: number;
        low?: number;
        lineDataType?: LineDataType;
        options: { x: number; y: number; isActualValue: boolean };
        zone: { dashStyle: string; color: string; value: number };
    };
    series: { name: string; index: number; initialType: string; tooltipOptions: { customTooltipPerSeries: () => void } };
};

export type HighchartsPlot = {
    x: number;
    y?: number | null | undefined;
    low?: number | null | undefined;
    high?: number | null | undefined;
    lineDataType?: LineDataType;
};

export const defaultChartOptions: Options = {
    title: {
        text: '',
        style: {
            fontSize: '30px',
            color: 'white',
        },
    },
    chart: {
        style: {
            fontFamily: 'Mulish, Helvetica, Arial, sans-serif', // TODO: Get variables for this.
        },
    },
    legend: {
        symbolRadius: 0,
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            day: '%e %b',
            month: '%b %y',
            year: '%Y',
        },
    },
    yAxis: [],
    series: [],
    navigator: {
        margin: defaultNavigatorMargin,
    },
    exporting: {
        buttons: {
            contextButton: {
                enabled: false,
            },
        },
    },
    credits: { enabled: false },
    lang: {
        numericSymbols: undefined,
    },
};

export interface ChartOptionsDefinitions {
    dashStyle?: string;
    data: HighchartsPlot[] | number[] | number[][];
    showInLegend?: boolean;
    type: string;
    visible?: boolean;
    yAxis?: number;
    zones?: { value: number }[];
}

export const chartColors = {
    alternateAreaColors: [scssVariables.stonexLightGrayBlue, scssVariables.bullishColor],
    lineChartColors: [
        scssVariables.chartColor1,
        scssVariables.chartColor2,
        scssVariables.chartColor3,
        scssVariables.chartColor4,
        scssVariables.chartColor5,
        scssVariables.chartColor6,
        scssVariables.chartColor7,
        scssVariables.chartColor8,
        scssVariables.chartColor9,
        scssVariables.chartColor10,
        scssVariables.chartColor11,
        scssVariables.chartColor12,
        scssVariables.chartColor13,
        scssVariables.chartColor14,
        scssVariables.chartColor15,
    ],
    valueMatrixLineChartColors: [
        scssVariables.valueMatrixChartColor1,
        scssVariables.valueMatrixChartColor2,
        scssVariables.valueMatrixChartColor3,
        scssVariables.valueMatrixChartColor4,
    ],
    donutChartColors: [
        scssVariables.plainWhite,
        scssVariables.highchartColor1,
        scssVariables.highchartColor2,
        scssVariables.highchartColor3,
        scssVariables.highchartColor4,
        scssVariables.highchartColor5,
        scssVariables.highchartColor6,
        scssVariables.highchartColor7,
        scssVariables.highchartColor8,
        scssVariables.highchartColor9,
        scssVariables.highchartColor10,
        scssVariables.highchartColor11,
    ],
    decileChartColors: [
        { decileRank: 10, color: scssVariables.ninetyToOneHundredDecile },
        { decileRank: 9, color: scssVariables.eightyToNinetyDecile },
        { decileRank: 8, color: scssVariables.seventyToEightyDecile },
        { decileRank: 7, color: scssVariables.sixtyToSeventyDecile },
        { decileRank: 6, color: scssVariables.fiftyToSixtyDecile },
        { decileRank: 5, color: scssVariables.fourtyToFiftyDecile },
        { decileRank: 4, color: scssVariables.thirtyToFourtyDecile },
        { decileRank: 3, color: scssVariables.twentyToThirtyDecile },
        { decileRank: 2, color: scssVariables.tenToTwentyDecile },
        { decileRank: 1, color: scssVariables.zeroToTenDecile },
    ],
};
