/* eslint-disable class-methods-use-this */
import { IChartData } from '../ChartDefinitions';

class MarketIndicatorChartDateService {
    toWeeklyData(data: IChartData[]): IChartData[] {
        const weeks: { [weekDate: string]: IChartData[] } = {};

        data.forEach((day) => {
            const weekStartDate = this.getWeekStartDate(day.asOfDate).toISOString().split('T')[0];
            if (!weeks[weekStartDate]) {
                weeks[weekStartDate] = [];
            }
            weeks[weekStartDate].push(day);
        });

        const weeklyData: IChartData[] = Object.keys(weeks).map((weekDate) => {
            const weekData = weeks[weekDate];
            const averageValue = weekData.reduce((sum, day) => sum + day.value, 0) / weekData.length;
            return { value: averageValue, asOfDate: new Date(weekDate), isActualValue: true };
        });
        return weeklyData;
    }

    getWeekStartDate(date: Date): Date {
        const diff = date.getUTCDate() - date.getUTCDay();
        const weekStartDate = new Date(date);
        weekStartDate.setUTCDate(diff);
        return weekStartDate;
    }

    getPreviousYearsWeeks = (date: Date, yearsBack: number): Date[] => {
        const previousYearsWeeks: Date[] = [];
        for (let i = 1; i <= yearsBack; i += 1) {
            const previousYearsWeek = this.getPreviousYearWeek(date, i);
            previousYearsWeeks.push(previousYearsWeek);
        }
        return previousYearsWeeks;
    };

    getPreviousYearWeek = (date: Date, yearsBack: number = 1): Date => {
        const previousYearDate = new Date(date);
        previousYearDate.setUTCFullYear(date.getUTCFullYear() - yearsBack);
        const previousYearsWeek = this.getWeekStartDate(previousYearDate);
        return previousYearsWeek;
    };

    getPreviousWeeks = (date: Date, weeksBack: number): Date[] => {
        const previousWeeks: Date[] = [];
        for (let i = 1; i <= weeksBack; i += 1) {
            const previousWeek = this.getPreviousWeek(date, i);
            previousWeeks.push(previousWeek);
        }
        return previousWeeks;
    };

    getPreviousWeek = (date: Date, weeksBack: number = 1): Date => {
        const previousWeekDate = new Date(date);
        previousWeekDate.setDate(date.getDate() - date.getDay() - 7 * weeksBack);
        const previousWeek = this.getWeekStartDate(previousWeekDate);
        return previousWeek;
    };
}

const marketIndicatorChartDateService = new MarketIndicatorChartDateService();
export default marketIndicatorChartDateService;
