import React, { useEffect, useMemo } from 'react';
import { demeterMarketIndicatorsApi } from '../../../../../Apis/Apis';
import scssVariables from '../../../../../Config.module.scss';
import {
    GetMarketIndicatorFactorDataResponse,
    GetMarketIndicatorFactorResponse,
    GetMarketIndicatorResponse,
    MarketIndicatorFactorModel,
} from '../../../../../Generated/Raven-Demeter';
import useMultipleApis from '../../../../Apis/Hooks/useMultipleApisHook';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import { IChartData, IChartDataSeries } from '../../ChartDefinitions';
import ChartWrapper from '../../ChartWrapper/ChartWrapper';
import { IMarketIndicatorChartProps } from '../MarketIndicatorChart';
import { IMarketIndicatorChartColorPalette } from '../MarketIndicatorChartColorPalette';
import MarketIndicatorChartRaw from '../MarketIndicatorChartRaw';

export const comboChartPalette: IMarketIndicatorChartColorPalette = {
    lineColors: [scssVariables.chartColor18, scssVariables.chartColor16, scssVariables.chartColor5],
};

export interface IMarketIndicatorCombinationChartProps extends IMarketIndicatorChartProps {
    runMarketIndicatorResponse?: GetMarketIndicatorResponse;
    getMarketIndicatorSubFactorResponses?: GetMarketIndicatorFactorResponse[];
}

const MarketIndicatorCombinationChart: React.FC<IMarketIndicatorCombinationChartProps> = (props: IMarketIndicatorCombinationChartProps) => {
    const [translations, translate] = useLanguage();

    const [, refreshRunTestMarketIndicatorFactor, runTestMarketIndicatorFactorResponse] = useMultipleApis(
        () =>
            props.getMarketIndicatorSubFactorResponses?.map((x) => {
                const {
                    leadingIndicatorType,
                    region,
                    subRegion,
                    commodity,
                    extraParameters,
                    dataFrequency,
                    templateType,
                    parameters,
                    correlation,
                    dataCombinationType,
                    secondaryExtraParameters,
                    secondaryRegion,
                    secondarySubRegion,
                    secondaryCommodity,
                    secondaryDataFrequency,
                    secondaryLeadingIndicatorType,
                } = x.marketIndicatorFactor ?? (x as MarketIndicatorFactorModel);

                return demeterMarketIndicatorsApi.runTestMarketIndicatorFactor({
                    leadingIndicatorType: leadingIndicatorType!,
                    region,
                    subRegion,
                    commodity,
                    extraParameters,
                    dataFrequency,
                    templateType: templateType!,
                    parameters: parameters ?? { yearsOfData: props.runMarketIndicatorResponse?.marketIndicator?.numberOfYears },
                    correlation,
                    numberOfYears: props.runMarketIndicatorResponse?.marketIndicator?.numberOfYears!,
                    asOfDate: props.runMarketIndicatorResponse?.marketIndicator?.asOfDate!,
                    dataCombinationType,
                    secondaryExtraParameters,
                    secondaryRegion,
                    secondarySubRegion,
                    secondaryCommodity,
                    secondaryDataFrequency,
                    secondaryLeadingIndicatorType,
                });
            }),
        { stopAutoExecute: true },
    );

    useEffect(() => {
        if (props.getMarketIndicatorSubFactorResponses?.length === 0) {
            return;
        }

        refreshRunTestMarketIndicatorFactor();
    }, [props.getMarketIndicatorSubFactorResponses]);

    const lineSeries = useMemo(() => {
        if (!runTestMarketIndicatorFactorResponse) {
            return [];
        }

        const newLinesSeries: IChartDataSeries[] = [];

        runTestMarketIndicatorFactorResponse.forEach((x, index) => {
            const lineSeriesData: IChartData[] = [];

            x.rows
                ?.filter((row) => row.value)
                .forEach((row) => {
                    lineSeriesData.push({
                        value: row.value!,
                        asOfDate: new Date(row.asOfDate),
                        isActualValue: true,
                    });
                });

            const currentSubFactorResponse = props.getMarketIndicatorSubFactorResponses![index];
            const currentSubFactorDisplayName =
                (currentSubFactorResponse as MarketIndicatorFactorModel)?.displayName ??
                (currentSubFactorResponse as GetMarketIndicatorFactorDataResponse).factorDisplayName ??
                '';

            newLinesSeries.push({
                yAxis: index,
                label: translate(currentSubFactorDisplayName),
                data: lineSeriesData,
            });
        });

        return newLinesSeries;
    }, [runTestMarketIndicatorFactorResponse]);

    const dataSourceTag = useMemo(() => {
        if (
            props.runMarketIndicatorResponse?.marketIndicator &&
            props.runMarketIndicatorResponse?.marketIndicator.dataSource &&
            props.runMarketIndicatorResponse?.marketIndicator.dataSource.length > 0
        ) {
            return `${props.runMarketIndicatorResponse?.marketIndicator.dataSource[0]}, ${translations.dataSource.StoneXCalculations}`;
        }
        return translations.dataSource.StoneXCalculations;
    }, [props.runTestMarketIndicatorFactorResponse]);

    const titleString = useMemo(() => translate(props.title ?? ''), [props.title]);

    const isLoading = lineSeries.length === 0;

    return isLoading && !props.showOnlyAsPopout ? (
        <LoadingSpinner />
    ) : (
        <ChartWrapper
            name="MarketIndicatorCombinationChart"
            title={titleString}
            headerOptions={{
                showOnlyAsPopout: props.showOnlyAsPopout,
            }}
            showPopout={props.showPopout}
            setShowPopout={props.setShowPopout}
            dataSourceTag={dataSourceTag}
            isLoading={isLoading}
        >
            <MarketIndicatorChartRaw lineSeries={lineSeries} colorPalette={comboChartPalette} hideAreaRangeSeriesInLegend />
        </ChartWrapper>
    );
};

export default MarketIndicatorCombinationChart;
