import { DemeterSymbolModel } from '../../../Generated/Raven-Demeter';
import Carousel from '../../Components/Carousel/Carousel';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import CumulativeValueAtRiskChart from './Charts/CumulativeValueAtRiskChart';
import ForwardCurveAndBasisChart from './Charts/FowardCurveAndBasisChart';
import MarkToMarketChart from './Charts/MarkToMarketChart';
import MonthlyNetValueAtRiskChart from './Charts/MonthlyNetValueAtRiskChart';
import NetPositionChart from './Charts/NetPositionChart';
import NetPositionCumulativeBudgetAtRiskChart from './Charts/NetPositionCumulativeBudgetAtRiskChart';
import NetPositionMonthlyBudgetAtRiskChart from './Charts/NetPositionMonthlyBudgetAtRiskChart';
import ValueAtRiskAssumptionsChart from './Charts/ValueAtRiskAssumptionsChart';
import { MonthlyRiskCompositeModel, RiskRequest } from './RiskDefinitions';
import styles from './RiskPage.module.scss';

interface IRiskCarouselProps {
    symbol: DemeterSymbolModel;
    risksRequest: RiskRequest;
    calculatedRisks: MonthlyRiskCompositeModel[];
    displayDecimalPlaces: number;
}

const RiskCarousel: React.FC<IRiskCarouselProps> = (props: IRiskCarouselProps) => {
    const [searchParamters] = useSearchParameters();

    return (
        <div className={styles.risk_carousel}>
            <Carousel>
                <div className={styles.risk_carousel_chart}>
                    <ValueAtRiskAssumptionsChart
                        symbol={props.symbol}
                        risksRequest={props.risksRequest}
                        calculatedRisks={props.calculatedRisks}
                        displayDecimalPlaces={props.displayDecimalPlaces}
                    />
                </div>
                <div className={styles.risk_carousel_chart}>
                    <CumulativeValueAtRiskChart symbol={props.symbol} risksRequest={props.risksRequest} calculatedRisks={props.calculatedRisks} />
                </div>
                <div className={styles.risk_carousel_chart}>
                    <ForwardCurveAndBasisChart
                        symbol={props.symbol}
                        risksRequest={props.risksRequest}
                        calculatedRisks={props.calculatedRisks}
                        displayDecimalPlaces={props.displayDecimalPlaces}
                    />
                </div>
                <div className={styles.risk_carousel_chart}>
                    <MarkToMarketChart symbol={props.symbol} risksRequest={props.risksRequest} calculatedRisks={props.calculatedRisks} />
                </div>
                <div className={styles.risk_carousel_chart}>
                    <MonthlyNetValueAtRiskChart symbol={props.symbol} risksRequest={props.risksRequest} calculatedRisks={props.calculatedRisks} />
                </div>
                <div className={styles.risk_carousel_chart}>
                    <NetPositionChart symbol={props.symbol} risksRequest={props.risksRequest} calculatedRisks={props.calculatedRisks} />
                </div>
                <div className={styles.risk_carousel_chart}>
                    <NetPositionChart symbol={props.symbol} risksRequest={props.risksRequest} calculatedRisks={props.calculatedRisks} />
                </div>
                {searchParamters.tab === 'budget' && (
                    <div className={styles.risk_carousel_chart}>
                        <NetPositionMonthlyBudgetAtRiskChart symbol={props.symbol} risksRequest={props.risksRequest} calculatedRisks={props.calculatedRisks} />
                    </div>
                )}
                {searchParamters.tab === 'budget' && (
                    <div className={styles.risk_carousel_chart}>
                        <NetPositionCumulativeBudgetAtRiskChart
                            symbol={props.symbol}
                            risksRequest={props.risksRequest}
                            calculatedRisks={props.calculatedRisks}
                        />
                    </div>
                )}
            </Carousel>
        </div>
    );
};

export default RiskCarousel;
