import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { demeterMarketIndicatorsApi } from '../../../../../Apis/Apis';
import {
    AddMarketIndicatorFactorRequest,
    DemeterDataFrequency,
    DemeterRegion,
    GetMarketIndicatorFactorResponse,
    LeadingIndicatorType,
    MarketIndicatorTemplateType,
    UpdateMarketIndicatorFactorRequest,
} from '../../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../../Layouts/NavigationRoutes';
import { useApplicationSelector } from '../../../../../Redux/ReduxStore';
import { selectUserMarkets } from '../../../../../Redux/Slices/UserSlice';
import useApi from '../../../../Apis/Hooks/useApiHook';
import useApiWithoutAutoExecute from '../../../../Apis/Hooks/useApiWithoutAutoExecute';
import useSymbolsApi from '../../../../Apis/Hooks/useSymbolsApiHook';
import useTableDefinitionsApi from '../../../../Apis/Hooks/useTableDefinitionsApiHook';
import addNewDataSource from '../../../../Assets/Icons/expand.svg';
import MarketIndicatorChart from '../../../../Components/Charts/MarketIndicator/MarketIndicatorChart';
// eslint-disable-next-line max-len
import MarketIndicatorAverageSeasonalChart from '../../../../Components/Charts/MarketIndicators/MarketIndicatorAverageSeasonal/MarketIndicatorAverageSeasonalChart';
import MarketIndicatorCombinationChart from '../../../../Components/Charts/MarketIndicators/MarketIndicatorCombination/MarketIndicatorCombinationChart';
import MarketIndicatorDecileChart from '../../../../Components/Charts/MarketIndicators/MarketIndicatorDecile/MarketIndicatorDecileChart';
import MarketIndicatorHistoricalChart from '../../../../Components/Charts/MarketIndicators/MarketIndicatorHistorical/MarketIndicatorHistoricalChart';
import MarketIndicatorSeasonalChart from '../../../../Components/Charts/MarketIndicators/MarketIndicatorSeasonal/MarketIndicatorSeasonalChart';
import MarketIndicatorTechnicalChart from '../../../../Components/Charts/MarketIndicators/MarketIndicatorTechnical/MarketIndicatorTechnicalChart';
import JoinedSelectionButtons from '../../../../Components/Form/Buttons/JoinedSelectionButtons/JoinedSelectionButtons';
import LinkButton, { LinkButtonType } from '../../../../Components/Form/Buttons/LinkButton';
import CheckboxDropdown from '../../../../Components/Form/Inputs/CheckboxDropdown';
import TextInput from '../../../../Components/Form/Inputs/TextInput';
import ComponentHeader from '../../../../Components/Headers/ComponentHeader';
import PageHeaderWithBackNavigation from '../../../../Components/Headers/PageHeaderWithBackNavigation';
import PageLoadingSpinner from '../../../../Components/LoadingSpinner/PageLoadingSpinner';
import useSearchParameters from '../../../../Components/Navigation/Hooks/useSearchParametersHook';
import RegexValidators from '../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import {
    AverageSeasonalParameters,
    CombinationParameters,
    DecileParameters,
    ForwardCurveContractInputType,
    ForwardCurveParameters,
    HistoricalParameters,
    leadingIndicatorTypeDefinitions,
    ManualEntryParameters,
    monthsOptions,
    Parameters,
    SeasonalParameters,
    SlidingWindowSeasonalParameters,
    TechnicalParameters,
    UpsertMarketIndicatorFactorRequest,
} from '../MarketIndicatorsManagementDefinitions';
import MarketIndicatorFactorDataSource from './MarketIndicatorFactorDataSource';
import styles from './MarketIndicatorFactorPage.module.scss';
import {
    defaultCorrelation,
    defaultDaysToAverage1,
    defaultDaysToAverage2,
    defaultDaysToAverage3,
    defaultHistoricalSubFactorCalculationTypes,
    defaultLeadingIndicatorType,
    defaultLongTermContractMonth,
    defaultLongTermContractNumber,
    defaultLongTermDays1,
    defaultLongTermDays2,
    defaultLongTermDays3,
    defaultMonthsFlatOutlook,
    defaultMonthsInverseCorrelation,
    defaultMonthsOfData,
    defaultMultipliers1,
    defaultMultipliers10,
    defaultMultipliers11,
    defaultMultipliers12,
    defaultMultipliers2,
    defaultMultipliers3,
    defaultMultipliers4,
    defaultMultipliers5,
    defaultMultipliers6,
    defaultMultipliers7,
    defaultMultipliers8,
    defaultMultipliers9,
    defaultNumberOfDataSourceSections,
    defaultOneYearWeightedPercent,
    defaultOutlook,
    defaultPeriodocity,
    defaultSeasonalSubFactorCalculationTypes,
    defaultShortTermContractMonth,
    defaultShortTermDays1,
    defaultShortTermDays2,
    defaultShortTermDays3,
    defaultTableDefinition,
    defaultTemplateType,
    defaultWindowEndDay,
    defaultWindowStartDay,
    defaultYearsOfData,
    maximumNumberOfDataSourceSections,
    minimumLengthOfGuid,
    numberOfThresholds,
    subFactorOrderBaseline,
} from './MarketIndicatorFactorPageDefault';
import CombinationMultiplierColumn from './MarketIndicatorsFactorFields/Combination/CombinationMultiplierColumn';
import CombinationTabs from './MarketIndicatorsFactorFields/Combination/CombinationTabs';
import MarketFactorCorrelationDropdown from './MarketIndicatorsFactorFields/Common/MarketFactorCorrelationDropdown';
import MarketFactorMathOperationDropdown from './MarketIndicatorsFactorFields/Common/MarketFactorMathOperationDropdown';
import MarketFactorMultiplierTextInput from './MarketIndicatorsFactorFields/Common/MarketFactorMultiplierTextInput';
import MarketFactorParameterTextInput from './MarketIndicatorsFactorFields/Common/MarketFactorParameterTextInput';
import MarketFactorTemplateTypeDropdown from './MarketIndicatorsFactorFields/Common/MarketFactorTemplateTypeDropdown';
import MarketFactorCalculationTypeDropdown from './MarketIndicatorsFactorFields/Common/MarketFactorThresholdDropdown';
import MarketFactorYearsOfDataDropdown from './MarketIndicatorsFactorFields/Common/MarketFactorYearsOfDataDropdown';
import MarketFactorLongTermContractMonthDropdown from './MarketIndicatorsFactorFields/ForwardCurve/MarketFactorLongTermContractMonthDropdown';
import MarketFactorLongTermContractNumberDropdown from './MarketIndicatorsFactorFields/ForwardCurve/MarketFactorLongTermContractNumberDropdown';
import MarketFactorMonthsOfDataDropdown from './MarketIndicatorsFactorFields/ForwardCurve/MarketFactorMonthsOfDataDropdown';
import MarketFactorShortTermContractNumberDropdown from './MarketIndicatorsFactorFields/ForwardCurve/MarketFactorShortTermContractNumberDropdown';
import MarketFactorManualEntryArea from './MarketIndicatorsFactorFields/Manual/MarketFactorManualEntryArea';
import MarketFactorWindowEndDayDropdown from './MarketIndicatorsFactorFields/SlidingWindowSeasonal/MarketFactorWindowEndDayDropdown';
import MarketFactorWindowStartDayDropdown from './MarketIndicatorsFactorFields/SlidingWindowSeasonal/MarketFactorWindowStartDayDropdown';

const MarketIndicatorFactorPage = () => {
    // Application hooks.
    const [translations] = useLanguage();
    const symbols = useSymbolsApi();
    const markets = useApplicationSelector(selectUserMarkets);
    const navigate = useNavigate();
    const [searchParameters, setSearchParameters] = useSearchParameters();

    const { marketIndicatorGuid, marketIndicatorFactorGroupName } = useParams();
    const marketIndicatorFactorGuidFromRoute = useParams().marketIndicatorFactorGuid;

    const marketIndicatorFactorParentGuid = useMemo(() => {
        if (searchParameters.tab?.length >= minimumLengthOfGuid || searchParameters.tab?.includes('child')) {
            return marketIndicatorFactorGuidFromRoute;
        }

        return undefined;
    }, [searchParameters.tab, marketIndicatorFactorGuidFromRoute]);

    const marketIndicatorFactorGuid = useMemo(() => {
        if (!searchParameters.tab || (searchParameters.tab.length < minimumLengthOfGuid && !searchParameters.tab.includes('child'))) {
            return marketIndicatorFactorGuidFromRoute;
        }

        return searchParameters.tab.includes('child') ? undefined : searchParameters.tab;
    }, [searchParameters.tab, marketIndicatorFactorGuidFromRoute]);

    // Form requests.
    const [upsertMarketIndicatorFactorRequest, setUpsertMarketIndicatorFactorRequest] = useState<UpsertMarketIndicatorFactorRequest>();
    const [parentUpsertMarketIndicatorFactorRequest, setParentUpsertMarketIndicatorFactorRequest] = useState<UpsertMarketIndicatorFactorRequest>();

    // Display hooks.
    const [forwardCurveContractInput, setForwardCurveContractInput] = useState<ForwardCurveContractInputType>('contractNumber');
    const [navigateAfterApiSuccess, setNavigateAfterApiSuccess] = useState(false);
    const [numberOfDataSourceSections, setNumberOfDataSourceSections] = useState(defaultNumberOfDataSourceSections);
    const [testChartsButtonActive, setTestChartsButtonActive] = useState<boolean>();

    // Api hooks.
    const tableDefinitions = useTableDefinitionsApi(
        upsertMarketIndicatorFactorRequest?.fundamentalCategory ?? defaultTableDefinition,
        true,
        upsertMarketIndicatorFactorRequest?.market,
    );
    const [, , getMarketIndicatorResponse] = useApi(() => demeterMarketIndicatorsApi.getMarketIndicator(marketIndicatorGuid!));

    const [, refreshGetMarketIndicatorFactorResponse, getMarketIndicatorFactorResponse] = useApiWithoutAutoExecute(() =>
        demeterMarketIndicatorsApi.getMarketIndicatorFactor(
            marketIndicatorGuid!,
            upsertMarketIndicatorFactorRequest?.parentMarketIndicatorFactorGuid ??
                marketIndicatorFactorGuid ??
                addMarketIndicatorResponse?.marketIndicatorFactorGuid!,
        ),
    );

    const [, handleUpdateMarketIndicatorFactor, updateMarketIndicatorsResponse] = useApiWithoutAutoExecute(
        (marketIndicatorFactorRequest?: UpdateMarketIndicatorFactorRequest) =>
            demeterMarketIndicatorsApi.updateMarketIndicatorFactor(
                marketIndicatorGuid ?? '',
                marketIndicatorFactorRequest?.marketIndicatorFactorGuid ?? '',
                marketIndicatorFactorRequest,
            ),
        {
            successMessage: translations.marketIndicatorsManagement.messages.marketIndicatorFactorUpdateSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.marketIndicatorFactorUpdateFailed,
        },
    );

    const [, handleAddMarketIndicatorFactor, addMarketIndicatorResponse] = useApiWithoutAutoExecute(
        (marketIndicatorFactorRequest?: AddMarketIndicatorFactorRequest) =>
            demeterMarketIndicatorsApi.addMarketIndicatorFactor(marketIndicatorGuid ?? '', marketIndicatorFactorRequest),
        {
            successMessage: translations.marketIndicatorsManagement.messages.marketIndicatorFactorAddSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.marketIndicatorFactorAddFailed,
        },
    );

    const [, refreshGetTestMarketIndicatorFactor, getTestMarketIndicatorFactorResponse] = useApiWithoutAutoExecute(() =>
        demeterMarketIndicatorsApi.getTestMarketIndicatorFactorData(upsertMarketIndicatorFactorRequest),
    );

    const [, refreshRunTestMarketIndicatorFactor, runTestMarketIndicatorFactorResponse] = useApiWithoutAutoExecute(() =>
        demeterMarketIndicatorsApi.runTestMarketIndicatorFactor({
            leadingIndicatorType: upsertMarketIndicatorFactorRequest?.leadingIndicatorType!,
            region: upsertMarketIndicatorFactorRequest?.region,
            subRegion: upsertMarketIndicatorFactorRequest?.subRegion,
            commodity: upsertMarketIndicatorFactorRequest?.commodity,
            extraParameters: upsertMarketIndicatorFactorRequest?.extraParameters || undefined,
            dataFrequency: upsertMarketIndicatorFactorRequest?.dataFrequency,
            templateType: upsertMarketIndicatorFactorRequest?.templateType!,
            parameters: upsertMarketIndicatorFactorRequest?.parameters,
            correlation: upsertMarketIndicatorFactorRequest?.correlation!,
            numberOfYears: getMarketIndicatorResponse?.marketIndicator?.numberOfYears!,
            asOfDate: getMarketIndicatorResponse?.marketIndicator?.asOfDate!,
            subFactorGuids: getMarketIndicatorFactorResponse?.marketIndicatorFactor?.subFactors.map((x) => x.marketIndicatorFactorGuid) ?? [],
            dataCombinationType: upsertMarketIndicatorFactorRequest?.dataCombinationType,
            secondaryExtraParameters: upsertMarketIndicatorFactorRequest?.secondaryExtraParameters,
            secondaryRegion: upsertMarketIndicatorFactorRequest?.secondaryRegion,
            secondarySubRegion: upsertMarketIndicatorFactorRequest?.secondarySubRegion,
            secondaryCommodity: upsertMarketIndicatorFactorRequest?.secondaryCommodity,
            secondaryDataFrequency: upsertMarketIndicatorFactorRequest?.secondaryDataFrequency,
            secondaryLeadingIndicatorType: upsertMarketIndicatorFactorRequest?.secondaryLeadingIndicatorType,
        }),
    );

    const isCombinationTemplate = getMarketIndicatorFactorResponse?.marketIndicatorFactor?.templateType === MarketIndicatorTemplateType.Combination;
    const isCombinationParentNew =
        marketIndicatorFactorParentGuid === undefined &&
        marketIndicatorFactorGuid === undefined &&
        upsertMarketIndicatorFactorRequest?.templateType === MarketIndicatorTemplateType.Combination;
    const isCombinationParentEdit =
        marketIndicatorFactorParentGuid === undefined &&
        marketIndicatorFactorGuid !== undefined &&
        (isCombinationTemplate || searchParameters.tab === 'parent');
    const isSubFactorNew = marketIndicatorFactorParentGuid && searchParameters.tab.includes('child');
    const isSubFactorEdit = marketIndicatorFactorParentGuid && !isSubFactorNew;

    const leadingIndicatorTypeOptions = useMemo(
        () =>
            leadingIndicatorTypeDefinitions.map((x) => ({
                ...x,
                label: translations.tableDefinitionType[x.tableDefinitionType],
            })),
        [tableDefinitions, translations],
    );

    const longTermContractOptions = useMemo(
        () => [
            {
                label: translations.marketIndicatorsManagement.fields.contractNumber,
                value: 'contractNumber',
            },
            {
                label: translations.marketIndicatorsManagement.fields.contractMonths,
                value: 'contractMonths',
            },
        ],
        [translations],
    );

    const shouldIncludeYearsOfDataDropdown = useMemo(() => {
        if (!upsertMarketIndicatorFactorRequest?.templateType) {
            return false;
        }

        return (
            [
                MarketIndicatorTemplateType.AverageSeasonal,
                MarketIndicatorTemplateType.Seasonal,
                MarketIndicatorTemplateType.Historical,
                MarketIndicatorTemplateType.SlidingWindowSeasonal,
                MarketIndicatorTemplateType.Decile,
            ] as MarketIndicatorTemplateType[]
        ).includes(upsertMarketIndicatorFactorRequest?.templateType);
    }, [upsertMarketIndicatorFactorRequest?.templateType]);

    useEffect(() => {
        if (!upsertMarketIndicatorFactorRequest || runTestMarketIndicatorFactorResponse) {
            return;
        }

        refreshRunTestMarketIndicatorFactor();
    }, [upsertMarketIndicatorFactorRequest?.region, runTestMarketIndicatorFactorResponse]);

    useEffect(() => {
        if (!marketIndicatorFactorGuid && !addMarketIndicatorResponse?.marketIndicatorFactorGuid) {
            return;
        }

        refreshGetMarketIndicatorFactorResponse();
    }, [marketIndicatorFactorGuid, addMarketIndicatorResponse, updateMarketIndicatorsResponse]);

    useEffect(() => {
        if (!navigateAfterApiSuccess && !addMarketIndicatorResponse && !upsertMarketIndicatorFactorRequest?.parentMarketIndicatorFactorGuid) {
            return;
        }

        const navigationString = `${NavigationRoutes.AdministrationMarketIndicators}/${marketIndicatorGuid}`;
        const navigationTab =
            isCombinationParentNew || isCombinationParentEdit ? 'parent' : addMarketIndicatorResponse?.marketIndicatorFactorGuid ?? marketIndicatorFactorGuid;

        if (isSubFactorNew && !navigateAfterApiSuccess) {
            setSearchParameters({ tab: navigationTab! });
            return;
        }

        if (!navigateAfterApiSuccess) {
            navigate(
                `${navigationString}/factorGroups/${upsertMarketIndicatorFactorRequest!.displayGroupName}/factors/${
                    upsertMarketIndicatorFactorRequest?.parentMarketIndicatorFactorGuid ?? addMarketIndicatorResponse?.marketIndicatorFactorGuid!
                }/edit?tab=${navigationTab}`,
            );
            return;
        }

        // Change this back to navigate(-1) after getting out of mono.
        navigate(`${navigationString}/edit`);
    }, [updateMarketIndicatorsResponse, addMarketIndicatorResponse, navigateAfterApiSuccess]);

    useEffect(() => {
        if (
            !symbols ||
            (!getMarketIndicatorFactorResponse && marketIndicatorFactorGuid) ||
            !getMarketIndicatorResponse ||
            !tableDefinitions ||
            upsertMarketIndicatorFactorRequest
        ) {
            return;
        }

        const marketIndicatorFactor = isSubFactorNew
            ? undefined
            : getMarketIndicatorFactorResponse?.marketIndicatorFactor?.subFactors.find((x) => x.marketIndicatorFactorGuid === searchParameters.tab) ??
              getMarketIndicatorFactorResponse?.marketIndicatorFactor;

        const initialUpsertMarketIndicatorFactorRequest = getResetForUpsertRequest();

        if (marketIndicatorFactor?.secondaryCommodity) {
            setNumberOfDataSourceSections(2);
        }

        if (!marketIndicatorFactor) {
            updateFactorsByTemplateType(defaultTemplateType, initialUpsertMarketIndicatorFactorRequest as UpsertMarketIndicatorFactorRequest);
        } else {
            setUpsertMarketIndicatorFactorRequest(initialUpsertMarketIndicatorFactorRequest as UpsertMarketIndicatorFactorRequest);
        }
    }, [marketIndicatorFactorGuid, getMarketIndicatorResponse, getMarketIndicatorFactorResponse, searchParameters.tab, tableDefinitions, symbols]);

    useEffect(() => {
        if (!getMarketIndicatorFactorResponse) {
            refreshGetMarketIndicatorFactorResponse();
            return;
        }

        if (!searchParameters.tab || !upsertMarketIndicatorFactorRequest?.parameters) {
            return;
        }

        const marketIndicatorFactor =
            getMarketIndicatorFactorResponse?.marketIndicatorFactor?.subFactors.find((x) => x.marketIndicatorFactorGuid === searchParameters.tab) ??
            getMarketIndicatorFactorResponse?.marketIndicatorFactor;

        const resetUpsertRequest = getResetForUpsertRequest();

        const initialUpsertMarketIndicatorFactorRequest = { ...resetUpsertRequest, ...marketIndicatorFactor };
        initialUpsertMarketIndicatorFactorRequest.parameters = marketIndicatorFactor?.parameters ?? upsertMarketIndicatorFactorRequest.parameters;

        if (isSubFactorNew || isSubFactorEdit) {
            initialUpsertMarketIndicatorFactorRequest.marketIndicatorFactorGuid = marketIndicatorFactorGuid ?? '';
            initialUpsertMarketIndicatorFactorRequest.multiplier = undefined;
            initialUpsertMarketIndicatorFactorRequest.parentMarketIndicatorFactorGuid = marketIndicatorFactorParentGuid;
        }

        if (isSubFactorNew) {
            initialUpsertMarketIndicatorFactorRequest.displayName = searchParameters.tab;
            initialUpsertMarketIndicatorFactorRequest.templateType = defaultTemplateType;
            initialUpsertMarketIndicatorFactorRequest.order = resetUpsertRequest.order;

            updateFactorsByTemplateType(
                initialUpsertMarketIndicatorFactorRequest.templateType,
                initialUpsertMarketIndicatorFactorRequest as UpsertMarketIndicatorFactorRequest,
            );
        } else {
            setUpsertMarketIndicatorFactorRequest(initialUpsertMarketIndicatorFactorRequest as UpsertMarketIndicatorFactorRequest);
        }
    }, [searchParameters.tab, getMarketIndicatorFactorResponse, marketIndicatorFactorParentGuid]);

    const getResetForUpsertRequest = () => {
        const marketIndicatorFactor = isSubFactorNew
            ? undefined
            : getMarketIndicatorFactorResponse?.marketIndicatorFactor?.subFactors.find((x) => x.marketIndicatorFactorGuid === searchParameters.tab) ??
              getMarketIndicatorFactorResponse?.marketIndicatorFactor;
        const leadingIndicatorType = marketIndicatorFactor?.leadingIndicatorType ?? defaultLeadingIndicatorType;
        const secondaryLeadingIndicatorType = marketIndicatorFactor?.secondaryLeadingIndicatorType ?? defaultLeadingIndicatorType;

        // For subfactors, we will add 100 to the starting point, then will count from there. Their order lives outside
        // of the natural order.
        let currentOrder = +(marketIndicatorFactor?.order ?? searchParameters.order ?? upsertMarketIndicatorFactorRequest!.order);
        const currentMaximum = getMarketIndicatorFactorResponse?.marketIndicatorFactor?.subFactors?.length
            ? Math.max(...getMarketIndicatorFactorResponse.marketIndicatorFactor.subFactors.map((x) => x.order))
            : currentOrder;
        const newSubfactorOrderIsSet = getMarketIndicatorFactorResponse?.marketIndicatorFactor?.order === currentMaximum;

        if (getMarketIndicatorFactorResponse?.marketIndicatorFactor?.subFactors.length === 0 && newSubfactorOrderIsSet && isSubFactorNew) {
            currentOrder += subFactorOrderBaseline + 1;
        } else if (isSubFactorNew) {
            const currentMaximumOrder = currentMaximum;
            currentOrder = currentMaximumOrder + 1;
        }

        // Either use the api responses (when editing) or set defaults.
        let initialUpsertMarketIndicatorFactorRequest = {
            commodity: marketIndicatorFactor?.commodity ?? getMarketIndicatorResponse?.marketIndicator?.commodity,
            correlation: marketIndicatorFactor?.correlation ?? defaultCorrelation,
            dataFrequency:
                !marketIndicatorFactor?.dataFrequency || marketIndicatorFactor?.leadingIndicatorType === LeadingIndicatorType.CommodityFuturesPrice
                    ? defaultPeriodocity
                    : (marketIndicatorFactor?.dataFrequency as DemeterDataFrequency),
            displayGroupName: marketIndicatorFactorGroupName ?? marketIndicatorFactor?.displayGroupName!,
            displayName: marketIndicatorFactor?.displayName ?? '',
            extraParameters: marketIndicatorFactor?.extraParameters,
            fundamentalCategory: leadingIndicatorTypeOptions.find((x) => x.value === leadingIndicatorType)?.tableDefinitionType!,
            leadingIndicatorType,
            market:
                marketIndicatorFactor?.market ??
                symbols?.find(
                    (x) =>
                        x.commodity === getMarketIndicatorResponse?.marketIndicator?.commodity &&
                        x.region === getMarketIndicatorResponse?.marketIndicator.region,
                )?.symbolCategory ??
                markets[0],
            marketIndicatorFactorGuid: marketIndicatorFactorGuid ?? '',
            marketIndicatorGuid: marketIndicatorGuid ?? '',
            order: currentOrder,
            parameters: marketIndicatorFactor?.parameters ?? {},
            parentMarketIndicatorFactorGuid: marketIndicatorFactorParentGuid,
            subRegion: marketIndicatorFactor?.subRegion,
            region: (marketIndicatorFactor?.region as DemeterRegion) ?? getMarketIndicatorResponse?.marketIndicator?.region,
            templateType: marketIndicatorFactor?.templateType ?? defaultTemplateType,
            multiplier: marketIndicatorFactor?.multiplier,
        } as UpsertMarketIndicatorFactorRequest;

        if (marketIndicatorFactor?.secondaryMarket) {
            initialUpsertMarketIndicatorFactorRequest = {
                ...initialUpsertMarketIndicatorFactorRequest,
                dataCombinationType: marketIndicatorFactor?.dataCombinationType,
                secondaryMarket:
                    marketIndicatorFactor?.secondaryMarket ??
                    symbols?.find(
                        (x) =>
                            x.commodity === getMarketIndicatorResponse?.marketIndicator?.commodity &&
                            x.region === getMarketIndicatorResponse?.marketIndicator.region,
                    )?.symbolCategory ??
                    markets[0],
                secondaryExtraParameters: marketIndicatorFactor?.secondaryExtraParameters,
                secondaryRegion: marketIndicatorFactor?.secondaryRegion,
                secondarySubRegion: marketIndicatorFactor?.secondarySubRegion,
                secondaryCommodity: marketIndicatorFactor?.secondaryCommodity,
                secondaryDataFrequency:
                    !marketIndicatorFactor?.secondaryDataFrequency ||
                    marketIndicatorFactor?.secondaryLeadingIndicatorType === LeadingIndicatorType.CommodityFuturesPrice
                        ? defaultPeriodocity
                        : (marketIndicatorFactor?.secondaryDataFrequency as DemeterDataFrequency),
                secondaryFundamentalCategory: leadingIndicatorTypeOptions.find((x) => x.value === secondaryLeadingIndicatorType)?.tableDefinitionType!,
                secondaryLeadingIndicatorType,
            } as UpsertMarketIndicatorFactorRequest;
        }

        return initialUpsertMarketIndicatorFactorRequest;
    };

    const updateFactorsByTemplateType = (templateType: MarketIndicatorTemplateType, initialRequest?: UpsertMarketIndicatorFactorRequest) => {
        const parameters: Parameters = {} as Parameters;
        switch (templateType) {
            case MarketIndicatorTemplateType.AverageSeasonal:
                (parameters as AverageSeasonalParameters).yearsOfData = `${defaultYearsOfData}`;
                break;
            case MarketIndicatorTemplateType.Historical:
                (parameters as HistoricalParameters).yearsOfData = `${defaultYearsOfData}`;
                (parameters as HistoricalParameters).subFactorCalculationTypes = defaultHistoricalSubFactorCalculationTypes;
                break;
            case MarketIndicatorTemplateType.Seasonal:
                (parameters as SeasonalParameters).yearsOfData = `${defaultYearsOfData}`;
                (parameters as SeasonalParameters).subFactorCalculationTypes = defaultSeasonalSubFactorCalculationTypes;
                break;
            case MarketIndicatorTemplateType.Combination:
                (parameters as CombinationParameters).multipliers1 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers1 ?? defaultMultipliers1;
                (parameters as CombinationParameters).multipliers2 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers2 ?? defaultMultipliers2;
                (parameters as CombinationParameters).multipliers3 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers3 ?? defaultMultipliers3;
                (parameters as CombinationParameters).multipliers4 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers4 ?? defaultMultipliers4;
                (parameters as CombinationParameters).multipliers5 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers5 ?? defaultMultipliers5;
                (parameters as CombinationParameters).multipliers6 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers6 ?? defaultMultipliers6;
                (parameters as CombinationParameters).multipliers7 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers7 ?? defaultMultipliers7;
                (parameters as CombinationParameters).multipliers8 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers8 ?? defaultMultipliers8;
                (parameters as CombinationParameters).multipliers9 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers9 ?? defaultMultipliers9;
                (parameters as CombinationParameters).multipliers10 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers10 ?? defaultMultipliers10;
                (parameters as CombinationParameters).multipliers11 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers11 ?? defaultMultipliers11;
                (parameters as CombinationParameters).multipliers12 =
                    (upsertMarketIndicatorFactorRequest?.parameters as CombinationParameters).multipliers12 ?? defaultMultipliers12;

                break;
            case MarketIndicatorTemplateType.Decile:
                (parameters as DecileParameters).yearsOfData = `${defaultYearsOfData}`;
                (parameters as DecileParameters).oneYearWeightedPercent = `${defaultOneYearWeightedPercent}`;
                break;
            case MarketIndicatorTemplateType.SlidingWindowSeasonal:
                (parameters as SlidingWindowSeasonalParameters).monthsFlatOutlook = defaultMonthsFlatOutlook;
                (parameters as SlidingWindowSeasonalParameters).monthsInverseCorrelation = defaultMonthsInverseCorrelation;
                (parameters as SlidingWindowSeasonalParameters).windowStartDay = `${defaultWindowStartDay}`;
                (parameters as SlidingWindowSeasonalParameters).windowEndDay = `${defaultWindowEndDay}`;
                (parameters as SlidingWindowSeasonalParameters).yearsOfData = `${defaultYearsOfData}`;
                break;
            case MarketIndicatorTemplateType.Technical:
                (parameters as TechnicalParameters).daysToAverage1 = `${defaultDaysToAverage1}`;
                (parameters as TechnicalParameters).daysToAverage2 = `${defaultDaysToAverage2}`;
                (parameters as TechnicalParameters).daysToAverage3 = `${defaultDaysToAverage3}`;
                (parameters as TechnicalParameters).longTermDays1 = `${defaultLongTermDays1}`;
                (parameters as TechnicalParameters).longTermDays2 = `${defaultLongTermDays2}`;
                (parameters as TechnicalParameters).longTermDays3 = `${defaultLongTermDays3}`;
                (parameters as TechnicalParameters).shortTermDays1 = `${defaultShortTermDays1}`;
                (parameters as TechnicalParameters).shortTermDays2 = `${defaultShortTermDays2}`;
                (parameters as TechnicalParameters).shortTermDays3 = `${defaultShortTermDays3}`;
                break;
            case MarketIndicatorTemplateType.ForwardCurve:
                (parameters as ForwardCurveParameters).monthsOfData = `${defaultMonthsOfData}`;
                (parameters as ForwardCurveParameters).shortTermContractNumber = `${defaultShortTermContractMonth}`;
                (parameters as ForwardCurveParameters).longTermContractNumber = `${defaultLongTermContractNumber}`;
                break;
            case MarketIndicatorTemplateType.ManualEntry:
                (parameters as ManualEntryParameters).outlook = defaultOutlook;
                break;
            default:
                (parameters as AverageSeasonalParameters).yearsOfData = `${defaultYearsOfData}`;
                break;
        }

        setUpsertMarketIndicatorFactorRequest({
            ...(initialRequest ?? upsertMarketIndicatorFactorRequest)!,
            templateType,
            parameters,
        });
    };

    const navigateBackToMarketIndicators = () => {
        navigate(`${NavigationRoutes.AdministrationMarketIndicators}/${marketIndicatorGuid}/edit`);
    };

    const multiplierColumns = useMemo(() => {
        if (!upsertMarketIndicatorFactorRequest || (!isCombinationParentNew && !isCombinationParentEdit)) {
            return null;
        }

        const lengthOfMultipliers = (upsertMarketIndicatorFactorRequest.parameters as CombinationParameters)?.multipliers1?.split(',').length;
        const columns = [];

        for (let index = 0; index < lengthOfMultipliers; index += 1) {
            columns.push(
                <div className={styles.indicator_add_and_edit_position}>
                    <div className={styles.master_flex}>
                        <p className={styles.indicator_add_and_edit_subfactor_name}>
                            {
                                getMarketIndicatorFactorResponse?.marketIndicatorFactor?.subFactors.find(
                                    (x) => x.marketIndicatorFactorGuid === searchParameters.tab,
                                )?.displayName
                            }
                        </p>
                    </div>

                    <CombinationMultiplierColumn
                        upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                        setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                        columnNumber={index}
                    />
                </div>,
            );
        }

        return columns;
    }, [upsertMarketIndicatorFactorRequest, searchParameters.tab]);

    const dataSourceSections = useMemo(() => {
        if (!upsertMarketIndicatorFactorRequest) {
            return [];
        }

        const templateTypeIsAllowed =
            upsertMarketIndicatorFactorRequest?.templateType !== MarketIndicatorTemplateType.ForwardCurve &&
            upsertMarketIndicatorFactorRequest?.templateType !== MarketIndicatorTemplateType.Speculative;

        if (!templateTypeIsAllowed || (!upsertMarketIndicatorFactorRequest.secondaryMarket && isSubFactorEdit)) {
            setNumberOfDataSourceSections(1);
        } else if (upsertMarketIndicatorFactorRequest.secondaryMarket) {
            setNumberOfDataSourceSections(2);
        }

        const currentDataSourceSections = [];
        for (let index = 0; index < numberOfDataSourceSections; index += 1) {
            const useSecondary = index === 1;
            const market = useSecondary
                ? upsertMarketIndicatorFactorRequest.secondaryMarket! ?? upsertMarketIndicatorFactorRequest.market
                : upsertMarketIndicatorFactorRequest.market;
            const leadingIndicatorType = useSecondary
                ? upsertMarketIndicatorFactorRequest.secondaryLeadingIndicatorType ?? upsertMarketIndicatorFactorRequest.leadingIndicatorType
                : upsertMarketIndicatorFactorRequest.leadingIndicatorType;
            const region = useSecondary
                ? upsertMarketIndicatorFactorRequest.secondaryRegion ?? upsertMarketIndicatorFactorRequest.region
                : upsertMarketIndicatorFactorRequest.region;
            const subRegion = useSecondary
                ? upsertMarketIndicatorFactorRequest.secondarySubRegion ?? upsertMarketIndicatorFactorRequest.subRegion
                : upsertMarketIndicatorFactorRequest.subRegion;
            const commodity = useSecondary
                ? upsertMarketIndicatorFactorRequest.secondaryCommodity ?? upsertMarketIndicatorFactorRequest.commodity
                : upsertMarketIndicatorFactorRequest.commodity;
            const extraParameters = useSecondary
                ? upsertMarketIndicatorFactorRequest.secondaryExtraParameters ?? upsertMarketIndicatorFactorRequest.extraParameters
                : upsertMarketIndicatorFactorRequest.extraParameters;
            const dataFrequency = useSecondary
                ? upsertMarketIndicatorFactorRequest.secondaryDataFrequency ?? upsertMarketIndicatorFactorRequest.dataFrequency
                : upsertMarketIndicatorFactorRequest.dataFrequency;

            currentDataSourceSections.push(
                <>
                    {index !== 0 && index + 1 === numberOfDataSourceSections && (
                        <>
                            <div className={styles.indicator_add_and_edit_line_break} />
                            <div className={styles.indicator_add_and_edit_close_icon_container}>
                                <CloseIcon
                                    onClick={() => {
                                        setNumberOfDataSourceSections(1);
                                        setUpsertMarketIndicatorFactorRequest({
                                            ...upsertMarketIndicatorFactorRequest,
                                            dataCombinationType: undefined,
                                            secondaryMarket: undefined,
                                            secondaryRegion: undefined,
                                            secondarySubRegion: undefined,
                                            secondaryCommodity: undefined,
                                            secondaryLeadingIndicatorType: undefined,
                                            secondaryFundamentalCategory: undefined,
                                            secondaryDataFrequency: undefined,
                                            secondaryExtraParameters: undefined,
                                        });
                                    }}
                                />
                            </div>
                            <MarketFactorMathOperationDropdown
                                upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                            />
                        </>
                    )}
                    <MarketIndicatorFactorDataSource
                        title={
                            useSecondary
                                ? `${translations.marketIndicatorsManagement.headers.dataSource} 2`
                                : translations.marketIndicatorsManagement.headers.dataSource
                        }
                        market={market}
                        region={region}
                        subRegion={subRegion}
                        commodity={commodity}
                        extraParameters={extraParameters}
                        leadingIndicatorType={leadingIndicatorType}
                        templateType={upsertMarketIndicatorFactorRequest.templateType}
                        dataFrequency={dataFrequency}
                        handleChange={(
                            updatedMarket,
                            updatedFundamentalCategory,
                            updatedLeadingIndicatorType,
                            updatedRegion,
                            updatedSubRegion,
                            updatedCommodity,
                            updatedExtraParameters,
                            updatedDataFrequency,
                        ) => {
                            if (useSecondary) {
                                setUpsertMarketIndicatorFactorRequest({
                                    ...upsertMarketIndicatorFactorRequest,
                                    secondaryMarket: updatedMarket,
                                    secondaryFundamentalCategory: updatedFundamentalCategory,
                                    secondaryLeadingIndicatorType: updatedLeadingIndicatorType,
                                    secondaryRegion: updatedRegion!,
                                    secondarySubRegion: updatedSubRegion,
                                    secondaryCommodity: updatedCommodity,
                                    secondaryExtraParameters: updatedExtraParameters,
                                    secondaryDataFrequency: updatedDataFrequency!,
                                });
                            } else {
                                setUpsertMarketIndicatorFactorRequest({
                                    ...upsertMarketIndicatorFactorRequest,
                                    market: updatedMarket,
                                    fundamentalCategory: updatedFundamentalCategory,
                                    leadingIndicatorType: updatedLeadingIndicatorType,
                                    region: updatedRegion!,
                                    subRegion: updatedSubRegion,
                                    commodity: updatedCommodity,
                                    extraParameters: updatedExtraParameters,
                                    dataFrequency: updatedDataFrequency!,
                                });
                            }
                        }}
                    />
                    {currentDataSourceSections.length < 1 && numberOfDataSourceSections < maximumNumberOfDataSourceSections && templateTypeIsAllowed && (
                        <button
                            type="button"
                            onClick={() => setNumberOfDataSourceSections(currentDataSourceSections.length + 1)}
                            className={styles.indicator_add_and_edit_add_data_source}
                        >
                            <img src={addNewDataSource} alt="#" /> {translations.marketIndicatorsManagement.text.addDataSourceSection}
                        </button>
                    )}
                </>,
            );
        }
        return currentDataSourceSections;
    }, [upsertMarketIndicatorFactorRequest, numberOfDataSourceSections]);

    const marketFactorThresholds = useMemo(() => {
        if (
            (upsertMarketIndicatorFactorRequest?.templateType !== MarketIndicatorTemplateType.Historical &&
                upsertMarketIndicatorFactorRequest?.templateType !== MarketIndicatorTemplateType.Seasonal) ||
            !(upsertMarketIndicatorFactorRequest.parameters as HistoricalParameters).subFactorCalculationTypes
        ) {
            return null;
        }

        const thresholds = [];
        for (let index = 0; index < numberOfThresholds; index += 1) {
            thresholds.push(
                <MarketFactorCalculationTypeDropdown
                    calculationTypeIndex={index}
                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                />,
            );
        }

        return thresholds;
    }, [upsertMarketIndicatorFactorRequest?.templateType, upsertMarketIndicatorFactorRequest?.parameters]);

    return !upsertMarketIndicatorFactorRequest ? (
        <PageLoadingSpinner />
    ) : (
        <div className={styles.indicator_add_and_edit_page_container}>
            <PageHeaderWithBackNavigation
                title={marketIndicatorFactorGuid ? translations.words.edit : translations.marketIndicatorsManagement.headers.addFactor}
                handleBackNavigation={navigateBackToMarketIndicators}
            />
            <div className={styles.indicator_add_and_edit}>
                <div className={styles.indicator_add_and_edit_details}>
                    {isCombinationTemplate && (
                        <CombinationTabs
                            marketIndicatorFactor={getMarketIndicatorFactorResponse?.marketIndicatorFactor!}
                            upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                            setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                            parentUpsertMarketIndicatorFactorRequest={parentUpsertMarketIndicatorFactorRequest}
                            setParentUpsertMarketIndicatorFactorRequest={setParentUpsertMarketIndicatorFactorRequest}
                            setTestChartsActive={setTestChartsButtonActive}
                        />
                    )}

                    <ComponentHeader title={translations.marketIndicatorsManagement.headers.details} />
                    <div className={styles.indicator_add_and_edit_row_factor_name}>
                        <TextInput
                            title={translations.marketIndicatorsManagement.headers.factorName}
                            required
                            value={upsertMarketIndicatorFactorRequest.displayName}
                            handleTextChange={(value) => {
                                setUpsertMarketIndicatorFactorRequest({
                                    ...upsertMarketIndicatorFactorRequest,
                                    displayName: value,
                                });
                            }}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.marketIndicatorsManagement.messages.displayNameInvalid}
                        />
                    </div>
                </div>
                <div className={styles.indicator_add_and_edit_template}>
                    <ComponentHeader title={translations.marketIndicatorsManagement.headers.template} />
                    <div className={styles.indicator_add_and_edit_row}>
                        <MarketFactorTemplateTypeDropdown
                            value={upsertMarketIndicatorFactorRequest.templateType}
                            handleOptionChange={(value) => {
                                updateFactorsByTemplateType(value);
                            }}
                        />
                        {upsertMarketIndicatorFactorRequest.templateType !== MarketIndicatorTemplateType.Combination && (
                            <MarketFactorCorrelationDropdown
                                upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                            />
                        )}
                    </div>

                    {isCombinationParentEdit && <div className={styles.indicator_add_and_edit_multipliers}>{multiplierColumns}</div>}
                    {upsertMarketIndicatorFactorRequest.templateType !== MarketIndicatorTemplateType.Combination && (
                        <div className={styles.indicator_add_and_edit_row}>
                            {!searchParameters.tab && (
                                <MarketFactorMultiplierTextInput
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                />
                            )}
                        </div>
                    )}

                    {shouldIncludeYearsOfDataDropdown && (
                        <div className={styles.indicator_add_and_edit_row}>
                            <MarketFactorYearsOfDataDropdown
                                upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                            />
                        </div>
                    )}

                    {(upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Historical ||
                        upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Seasonal) && (
                        <div className={styles.indicator_add_and_edit_row}>{marketFactorThresholds}</div>
                    )}

                    {upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.SlidingWindowSeasonal && (
                        <>
                            <div className={styles.indicator_add_and_edit_row}>
                                <MarketFactorWindowStartDayDropdown
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                />
                                <MarketFactorWindowEndDayDropdown
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                />
                            </div>
                            <div className={styles.indicator_add_and_edit_row}>
                                <div className={styles.indicator_add_and_edit_dropdown}>
                                    <CheckboxDropdown
                                        options={monthsOptions}
                                        handleOptionSelect={(value) =>
                                            setUpsertMarketIndicatorFactorRequest({
                                                ...upsertMarketIndicatorFactorRequest,
                                                parameters: {
                                                    ...upsertMarketIndicatorFactorRequest.parameters,
                                                    monthsFlatOutlook: value.sort().join(),
                                                },
                                            })
                                        }
                                        values={(
                                            (upsertMarketIndicatorFactorRequest.parameters as SlidingWindowSeasonalParameters).monthsFlatOutlook ??
                                            defaultMonthsFlatOutlook
                                        ).split(',')}
                                        label={translations.marketIndicatorsManagement.fields.monthsFlatOutlook}
                                        placeholder={
                                            (upsertMarketIndicatorFactorRequest.parameters as SlidingWindowSeasonalParameters).monthsFlatOutlook ??
                                            defaultMonthsFlatOutlook
                                        }
                                    />
                                </div>
                                <div className={styles.indicator_add_and_edit_dropdown}>
                                    <CheckboxDropdown
                                        options={monthsOptions}
                                        handleOptionSelect={(value) =>
                                            setUpsertMarketIndicatorFactorRequest({
                                                ...upsertMarketIndicatorFactorRequest,
                                                parameters: {
                                                    ...upsertMarketIndicatorFactorRequest.parameters,
                                                    monthsInverseCorrelation: value.sort().join(),
                                                },
                                            })
                                        }
                                        values={(
                                            (upsertMarketIndicatorFactorRequest.parameters as SlidingWindowSeasonalParameters).monthsInverseCorrelation ??
                                            defaultMonthsInverseCorrelation
                                        ).split(',')}
                                        label={translations.marketIndicatorsManagement.fields.monthsInverseCorrelation}
                                        placeholder={
                                            (upsertMarketIndicatorFactorRequest.parameters as SlidingWindowSeasonalParameters).monthsInverseCorrelation ??
                                            defaultMonthsInverseCorrelation
                                        }
                                    />
                                </div>
                            </div>
                            <div className={styles.indicator_add_and_edit_row}>
                                <TextInput
                                    title={translations.marketIndicatorsManagement.fields.imageLink}
                                    value={(upsertMarketIndicatorFactorRequest.parameters as SlidingWindowSeasonalParameters).imageUrl ?? ''}
                                    handleTextChange={(value: string) =>
                                        setUpsertMarketIndicatorFactorRequest({
                                            ...upsertMarketIndicatorFactorRequest,
                                            parameters: {
                                                ...upsertMarketIndicatorFactorRequest.parameters,
                                                imageUrl: value,
                                            },
                                        })
                                    }
                                />
                            </div>
                        </>
                    )}
                    {upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.ForwardCurve && (
                        <>
                            <div className={styles.indicator_add_and_edit_row}>
                                <MarketFactorShortTermContractNumberDropdown
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                />
                            </div>
                            <div className={styles.indicator_add_and_edit_row}>
                                <p>{translations.marketIndicatorsManagement.fields.longTerm}</p>
                            </div>
                            <div className={styles.indicator_add_and_edit_long_term}>
                                <JoinedSelectionButtons
                                    options={longTermContractOptions}
                                    handleSelectionChange={(value) => {
                                        const newUpsertMarketIndicatorFactorRequest = { ...upsertMarketIndicatorFactorRequest };
                                        const newForwardCurveParameters = newUpsertMarketIndicatorFactorRequest.parameters as ForwardCurveParameters;

                                        if (value === 'contractNumber') {
                                            newForwardCurveParameters.longTermContractNumber = `${defaultLongTermContractNumber}`;
                                            delete newForwardCurveParameters.longTermContractMonth;
                                        } else {
                                            newForwardCurveParameters.longTermContractMonth = `${defaultLongTermContractMonth}`;
                                            delete newForwardCurveParameters.longTermContractNumber;
                                        }

                                        setUpsertMarketIndicatorFactorRequest(newUpsertMarketIndicatorFactorRequest);
                                        setForwardCurveContractInput(value as ForwardCurveContractInputType);
                                    }}
                                    selection={forwardCurveContractInput!}
                                />
                            </div>
                            <div className={styles.indicator_add_and_edit_row}>
                                {forwardCurveContractInput === 'contractNumber' ? (
                                    <MarketFactorLongTermContractNumberDropdown
                                        upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                        setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                    />
                                ) : (
                                    <MarketFactorLongTermContractMonthDropdown
                                        upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                        setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                    />
                                )}
                            </div>
                            <div className={styles.indicator_add_and_edit_row}>
                                <MarketFactorMonthsOfDataDropdown
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                />
                            </div>
                        </>
                    )}
                    {upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.ManualEntry && (
                        <>
                            <div className={styles.indicator_add_and_edit_row}>
                                <TextInput
                                    title={translations.marketIndicatorsManagement.fields.imageLink}
                                    value={(upsertMarketIndicatorFactorRequest.parameters as ManualEntryParameters).imageUrl ?? ''}
                                    handleTextChange={(value: string) =>
                                        setUpsertMarketIndicatorFactorRequest({
                                            ...upsertMarketIndicatorFactorRequest,
                                            parameters: {
                                                ...upsertMarketIndicatorFactorRequest.parameters,
                                                imageUrl: value,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className={styles.indicator_add_and_edit_row}>
                                <MarketFactorManualEntryArea
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                />
                            </div>
                        </>
                    )}
                    {upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Technical && (
                        <>
                            <div className={styles.indicator_add_and_edit_row_spacer} />
                            <div className={styles.indicator_add_and_edit_row_technical_input}>
                                <MarketFactorParameterTextInput
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                    title={translations.marketIndicatorsManagement.fields.daysToAverage1}
                                    field="daysToAverage1"
                                />
                                <MarketFactorParameterTextInput
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                    title={translations.marketIndicatorsManagement.fields.daysToAverage2}
                                    field="daysToAverage2"
                                />
                                <MarketFactorParameterTextInput
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                    title={translations.marketIndicatorsManagement.fields.daysToAverage3}
                                    field="daysToAverage3"
                                />
                            </div>
                            <div className={styles.indicator_add_and_edit_row_technical_input}>
                                <MarketFactorParameterTextInput
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                    title={translations.marketIndicatorsManagement.fields.shortTermDays1}
                                    field="shortTermDays1"
                                />
                                <MarketFactorParameterTextInput
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                    title={translations.marketIndicatorsManagement.fields.shortTermDays2}
                                    field="shortTermDays2"
                                />
                                <MarketFactorParameterTextInput
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                    title={translations.marketIndicatorsManagement.fields.shortTermDays3}
                                    field="shortTermDays3"
                                />
                            </div>
                            <div className={styles.indicator_add_and_edit_row_technical_input}>
                                <MarketFactorParameterTextInput
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                    title={translations.marketIndicatorsManagement.fields.longTermDays1}
                                    field="longTermDays1"
                                />
                                <MarketFactorParameterTextInput
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                    title={translations.marketIndicatorsManagement.fields.longTermDays2}
                                    field="longTermDays2"
                                />
                                <MarketFactorParameterTextInput
                                    upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                    setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                    title={translations.marketIndicatorsManagement.fields.longTermDays3}
                                    field="longTermDays3"
                                />
                            </div>
                        </>
                    )}
                    <div className={styles.indicator_add_and_edit_row}>
                        {upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Decile && (
                            <MarketFactorParameterTextInput
                                upsertMarketIndicatorFactorRequest={upsertMarketIndicatorFactorRequest}
                                setUpsertMarketIndicatorFactorRequest={setUpsertMarketIndicatorFactorRequest}
                                title={translations.marketIndicatorsManagement.fields.oneYearWeightedPercent}
                                field="oneYearWeightedPercent"
                                validation={RegexValidators.PositiveNumberZeroThroughOneHundred}
                                errorMessage={translations.marketIndicatorsManagement.messages.positiveNumberZeroThroughOneHundred}
                            />
                        )}
                    </div>
                </div>
                {upsertMarketIndicatorFactorRequest.templateType !== MarketIndicatorTemplateType.ManualEntry &&
                    !isCombinationParentNew &&
                    !isCombinationParentEdit &&
                    dataSourceSections}

                <div className={styles.indicator_add_and_edit_markets_chart_and_controls}>
                    <div className={styles.indicator_add_and_edit_action_buttons_row}>
                        <div className={styles.indicator_add_and_edit_action_buttons_test}>
                            <LinkButton
                                onClick={() => {
                                    refreshRunTestMarketIndicatorFactor();
                                    setTestChartsButtonActive(true);

                                    if (!isCombinationParentEdit && !isCombinationParentNew) {
                                        refreshGetTestMarketIndicatorFactor();
                                    }
                                }}
                                title={translations.actions.test}
                                type={LinkButtonType.White}
                            />
                        </div>

                        <div className={styles.indicator_add_and_edit_save_and_cancel}>
                            <LinkButton onClick={navigateBackToMarketIndicators} title={translations.actions.cancel} type={LinkButtonType.White} />
                            <LinkButton
                                onClick={() => {
                                    if (isSubFactorNew && parentUpsertMarketIndicatorFactorRequest) {
                                        handleUpdateMarketIndicatorFactor(parentUpsertMarketIndicatorFactorRequest);
                                    }

                                    if (marketIndicatorFactorGuid && !isSubFactorNew) {
                                        handleUpdateMarketIndicatorFactor(upsertMarketIndicatorFactorRequest);
                                    } else {
                                        handleAddMarketIndicatorFactor(upsertMarketIndicatorFactorRequest);
                                    }
                                }}
                                disabled={
                                    !RegexValidators.AlphaNumericMinimumLength2.test(upsertMarketIndicatorFactorRequest.displayName) ||
                                    upsertMarketIndicatorFactorRequest?.displayName.includes('child')
                                }
                                title={translations.actions.save}
                                type={LinkButtonType.White}
                            />
                            <LinkButton
                                onClick={() => {
                                    if (marketIndicatorFactorGuid && !isSubFactorNew) {
                                        setNavigateAfterApiSuccess(true);
                                        handleUpdateMarketIndicatorFactor(upsertMarketIndicatorFactorRequest);
                                    } else {
                                        setNavigateAfterApiSuccess(true);
                                        handleAddMarketIndicatorFactor(upsertMarketIndicatorFactorRequest);
                                    }
                                }}
                                disabled={
                                    !RegexValidators.AlphaNumericMinimumLength2.test(upsertMarketIndicatorFactorRequest.displayName) ||
                                    upsertMarketIndicatorFactorRequest?.displayName.includes('child')
                                }
                                title={translations.actions.saveAndReturn}
                                type={LinkButtonType.Blue}
                            />
                        </div>
                    </div>
                    <div className={styles.indicator_add_and_edit_markets_chart}>
                        <MarketIndicatorChart
                            runMarketIndicatorResponse={getMarketIndicatorResponse}
                            runTestMarketIndicatorFactorResponse={runTestMarketIndicatorFactorResponse!}
                        />
                    </div>
                    {testChartsButtonActive &&
                        getTestMarketIndicatorFactorResponse &&
                        upsertMarketIndicatorFactorRequest.templateType !== MarketIndicatorTemplateType.Combination &&
                        upsertMarketIndicatorFactorRequest.templateType !== MarketIndicatorTemplateType.ManualEntry && (
                            <div className={styles.indicator_add_and_edit_markets_chart}>
                                <MarketIndicatorChart
                                    runMarketIndicatorResponse={getMarketIndicatorResponse}
                                    runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                                    ignorePlotbands
                                />
                            </div>
                        )}
                    {testChartsButtonActive &&
                        getMarketIndicatorFactorResponse?.marketIndicatorFactor?.subFactors &&
                        upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Combination &&
                        searchParameters.tab === 'parent' && (
                            <div className={styles.indicator_add_and_edit_markets_chart}>
                                <MarketIndicatorCombinationChart
                                    title={getMarketIndicatorFactorResponse?.marketIndicatorFactor?.displayName}
                                    runMarketIndicatorResponse={getMarketIndicatorResponse}
                                    runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                                    getMarketIndicatorSubFactorResponses={
                                        getMarketIndicatorFactorResponse?.marketIndicatorFactor?.subFactors as GetMarketIndicatorFactorResponse[]
                                    }
                                />
                            </div>
                        )}
                    {testChartsButtonActive &&
                        getTestMarketIndicatorFactorResponse &&
                        upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Historical && (
                            <div className={styles.indicator_add_and_edit_markets_chart}>
                                <MarketIndicatorHistoricalChart
                                    runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                                    parameters={upsertMarketIndicatorFactorRequest.parameters as HistoricalParameters}
                                />
                            </div>
                        )}
                    {testChartsButtonActive &&
                        getTestMarketIndicatorFactorResponse &&
                        upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Seasonal && (
                            <div className={styles.indicator_add_and_edit_markets_chart}>
                                <MarketIndicatorSeasonalChart
                                    runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                                    parameters={upsertMarketIndicatorFactorRequest.parameters as SeasonalParameters}
                                />
                            </div>
                        )}
                    {testChartsButtonActive &&
                        getTestMarketIndicatorFactorResponse &&
                        upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.AverageSeasonal && (
                            <div className={styles.indicator_add_and_edit_markets_chart}>
                                <MarketIndicatorAverageSeasonalChart
                                    runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                                    parameters={upsertMarketIndicatorFactorRequest.parameters as AverageSeasonalParameters}
                                />
                            </div>
                        )}
                    {testChartsButtonActive &&
                        getTestMarketIndicatorFactorResponse &&
                        upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Technical && (
                            <div className={styles.indicator_add_and_edit_markets_chart}>
                                <MarketIndicatorTechnicalChart
                                    runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                                    parameters={upsertMarketIndicatorFactorRequest.parameters as TechnicalParameters}
                                />
                            </div>
                        )}
                    {testChartsButtonActive &&
                        getTestMarketIndicatorFactorResponse &&
                        upsertMarketIndicatorFactorRequest.templateType === MarketIndicatorTemplateType.Decile && (
                            <div className={styles.indicator_add_and_edit_markets_chart}>
                                <MarketIndicatorDecileChart
                                    runTestMarketIndicatorFactorResponse={getTestMarketIndicatorFactorResponse}
                                    parameters={upsertMarketIndicatorFactorRequest.parameters as DecileParameters}
                                />
                            </div>
                        )}

                    {testChartsButtonActive &&
                        getTestMarketIndicatorFactorResponse &&
                        !!(upsertMarketIndicatorFactorRequest.parameters as ManualEntryParameters).imageUrl && (
                            <img
                                className={styles.indicator_add_and_edit_image}
                                src={(upsertMarketIndicatorFactorRequest.parameters as ManualEntryParameters).imageUrl!}
                                alt={upsertMarketIndicatorFactorRequest.displayName}
                            />
                        )}
                </div>
            </div>
        </div>
    );
};

export default MarketIndicatorFactorPage;
